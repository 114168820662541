<style src="./step-registration-radio.component.scss" lang="scss"></style>

<template>
  <div class="step-registration-radio">
    <step-description-component :stepDescription="stepDescription" :current="current"/>
    <progressbar-component :step="step" :current="current" :stepDescription="stepDescription"/>
    <section v-show="current === 1">
      <general-information-form-component
          :userLogin="userLogin"
          :stepOneValidation="stepOneValidation"
          :userData="userData"
          :legalPersonCarrier="legalPersonCarrier"
          :organizationLegalFormValue="organizationLegalFormValue"
          :citizenshipValue="citizenshipValue"
          :citizenship="citizenship"
          :organizationLegalForm="organizationLegalForm"/>
    </section>
    <section v-show="current === 2">
      <personal-information-form-component
          @setPersonalInformation="(i) => $emit('setPersonalInformation', i)"
          :req="true"
          :organizationName="organizationName"
          @checkTin="checkTin"
          :userLogin="userLogin"
          :documentSeriesType="documentSeriesType"
          :errorPersonalInformation="errorPersonalInformation"
          :organizationLegalFormValue="organizationLegalFormValue"
          :legalPersonCarrier="legalPersonCarrier"
          :citizenshipValue="citizenshipValue"
          :personalInformation="personalInformation"
      />
    </section>
    <section v-show="current === 3">
      <post-files-component
          @selectTechnology="selectTechnology"
          :errorRedAppealTechnologyId="errorRedAppealTechnologyId"
          :errorFiles="errorFiles"
          :getPostCreateRedAppeal="getPostCreateRedAppeal"
          :technology="appealTechnologyKeyValues"
          :fileTypesKeyValues="fileTypesKeyValues"/>
    </section>
  </div>
</template>

<script src="./step-registration-radio.component.ts"></script>