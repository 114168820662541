import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import StepCertificateComponent
    from '@/pages/issuing-certificate-conformity-service/components/step-certificate/step-certificate.component.vue';
import {Validation} from '@/core/mixin/validation';
import {CreateFormData} from '@/core/mixin/mixin';
import {Router} from '@/core/mixin/router';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {container} from 'tsyringe';
import {load} from 'recaptcha-v3';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import {
    IssuingCertificateConformityServiceSandbox
} from '@/pages/issuing-certificate-conformity-service/sandbox/issuing-certificate-conformity-service.sandbox';
import {IInputPayload} from '@/types/input-payload';
import {GetCertFilesModel} from '@/pages/issuing-certificate-conformity-service/models/get-cert-files.model';
import {CertApplicantFormModel} from '@/pages/issuing-certificate-conformity-service/models/cert-appticant-form.model';
import {IFileType} from '@/pages/issuing-certificate-conformity-service/types/file-type';
import {isArray, iterator} from 'rxjs/internal-compatibility';
import {IUserData} from '@/pages/appeal-radio-service/types/user-data';
import {IServiceCommon} from '@/types/service-common';
import {CertApplicantInfoModel} from '@/pages/issuing-certificate-conformity-service/models/cert-applicant-info.model';
import {IApplicantForm} from '@/pages/issuing-certificate-conformity-service/types/applicant-form';
import {loginStateMutations} from '@/pages/login/store/store/mutations';

@Options({
    name: 'issuing-certificate-conformity-service-component',
    components: {
        StepCertificateComponent,
        CustomerInfoComponent,
        ServiceNameComponent,
    },
    watch: {
        citizenshipValue: {
            handler: 'changeCitizenshipValue',
            immediate: false,
            deep: false,
        },
        documentSeriesType: {
            handler: 'changeDocumentSeriesType',
            immediate: false,
            deep: false,
        }
    }
})

export default class IssuingCertificateConformityServiceComponent extends mixins(Validation, CreateFormData, Router) {
    public siteKey = '6LdZg8IfAAAAAEzXF_7RQY0AzNjQ4zhPPR-5Tf5P';
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);
    public sandbox: IssuingCertificateConformityServiceSandbox = container.resolve(IssuingCertificateConformityServiceSandbox);
    public store = store;
    public step: number = 3;
    public current: number = 1;
    public locale$: any = locale;
    public locale: any = null;
    public requestCitizenship = false;
    public organizationNameValue = '';
    public oldTin = '';

    public errorPersonalInformation = {
        documentNumber: false,
        documentTypeNumber: false,
        fin: false,
        phone: false,
        email: false,
        tin: false,
        organization: false,
        position: false,
        registration: false,
        poweroftheattorneydoc: false,
        govregistercopy: false,
        legalEntityFullName: false,
        positionLegalEntity: false,
        copyExtractFile: false,
        legalEntityAddress: false,
        filesForCertificate: false,
        foreignPhone: false
    };

    get applicantForm(): IApplicantForm {
        return this.store.getters._getApplicantForm;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get documentSeriesType() {
        return this.store.getters._getDocumentSeriesType;
    }

    public $refs!: {
        modal: {
            openModal: () => void;
            closeModal: () => void
        },
    };

    public stepOneValidation = {
        citizenship: false,
        organizationLegalForm: false
    };

    public checkTin() {
        load((this.siteKey), {}).then((re) => {
            re.execute('sendFeedback').then((token) => {
                this.sandboxCommon.getRadioOrganizationName({
                    SecretKey: token,
                    tin: this.personalInformation.tin,
                    applicantTypeId: this.organizationLegalFormValue,
                });
            });
        });
    }

    get personalInformation() {
        return this.store.getters._getApplicantForm;
    }

    get certificateStatus() {
        return this.store.getters._getCertificateStatus;
    }

    get organizationName() {
        return this.store.getters._getRadioOrganizationName;
    }

    get filesForForCertificate(): IFileType[] {
        return this.store.getters._getFilesForForCertificate;
    }

    get filesForForStep3() {
        return this.store.getters._getFilesForForStep3;
    }


    public changeDocumentSeriesType(val: any) {
        this.onChangeInput({
            type: 'documentTypeNumber',
            value: val[0]
        });
    }

    public setPersonalInformation(info: IInputPayload) {
        this.store.commit('SET_APPLICANT_CERTIFICATE', info);
    }


    public onChangeCertificateStatus(info: IInputPayload) {
        this.store.commit('SET_CERTIFICATE__STATUS', info.value);
    }

    public onChangeCertificateType(info: IInputPayload) {
        this.store.commit('SET_CERTIFICATE__TYPE', info.value);
        this.sandbox.getFilesForForStep3({
            sxemId: info.value,
            applicantTypeId: this.organizationLegalFormValue,
            isApplicantDifferent: this.legalPersonCarrier,
            appealTypeId: this.certificateStatus,
            citizenshipId: this.citizenshipValue
        });
    }

    public onChangeInput(info: IInputPayload) {
        this.store.commit('SET_APPLICANT_CERTIFICATE', info);
    }

    get citizenship() {
        return this.store.getters._getCitizenship;
    }

    get certificateType() {
        return this.store.getters._getCertificateType;
    }

    get organizationLegalForm() {
        return this.store.getters._getOrganPersonType;
    }

    get user() {
        if (this.store.getters._getUserFullData?.pin?.length) {
            if (this.store.getters._getUserFullData?.pin?.length === 7) {
                this.store.commit('SET_CITIZENSHIP_VALUE', 1);
            } else {
                this.store.commit('SET_CITIZENSHIP_VALUE', 2);
            }
        }
        return this.store.getters._getUserFullData;
    }

    get legalPersonCarrier() {
        return this.store.getters._getLegalPersonCarrier;
    }

    get citizenshipValue() {
        return this.store.getters._getCitizenshipValue;
    }

    get organizationLegalFormValue() {
        return this.store.getters._getOrganizationLegalFormValue;
    }

    get stepDescription() {
        return [
            {
                number: 1,
                description: 'Ümumi məlumat',
            },
            {
                number: 2,
                description: 'Şəxsi məlumatlar',
            },
            {
                number: 3,
                description: 'Müraciət üzrə parametrlər',
            },
        ];
    }

    public mounted(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.elements) {
                this.locale = res.issuingCertificateConformity;
            }
        });
        this.sandbox.getCitizenshipCert();
        this.sandboxCommon.getCommonOrganPersonType(2);
        this.getServiceData();
        // this.sandboxCommon.getDocumentSeriesType();

        // this.getServiceData();
    }

    // public closeModal() {
    //     // this.store.commit('SET_DATA_RESET');
    //     // this.store.commit('SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE');
    //     // this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
    //     // this.$refs.modal.closeModal();
    //     // this.current = 1;
    //     // this.$router.push({name: 'home'});
    // }

    public errorReset() {
        this.errorPersonalInformation = {
            documentNumber: false,
            documentTypeNumber: false,
            fin: false,
            phone: false,
            email: false,
            tin: false,
            organization: false,
            position: false,
            registration: false,
            poweroftheattorneydoc: false,
            govregistercopy: false,
            legalEntityFullName: false,
            positionLegalEntity: false,
            copyExtractFile: false,
            legalEntityAddress: false,
            filesForCertificate: false,
            foreignPhone: false
        };
    }


    public changeCitizenshipValue(val: any, oldVal: any) {
        if (val !== oldVal) {
            this.requestCitizenship = true;
            if ((oldVal === 4 && (val === 3 || val === 1)) || (val === 4 && (oldVal === 3 || oldVal === 1)))
                this.store.commit('SET_APPLICANT_CERTIFICATE_ALL', {
                    documentNumber: null,
                    documentTypeNumber: null,
                    fin: null,
                    phone: '+994(',
                    email: null,
                    tin: null,
                    organizationName: null,
                    position: null,
                    registration: null,
                    authorityFile: null,
                    legalEntityFullName: null,
                    legalEntityAddress: null,
                    positionLegalEntity: null,
                    copyExtract: null,
                    copyExtractFile: null,
                    poweroftheattorneydoc: null,
                    govregistercopy: null,
                    name: null,
                    secondName: null
                });
        }
    }

    public changeUser(val: any) {
        if (val?.pin?.length) {
            if (val?.pin?.length === 7) {
                this.store.commit('SET_CITIZENSHIP_VALUE', 1);
            } else {
                this.store.commit('SET_CITIZENSHIP_VALUE', 2);
            }
        }
    }

    public decrementCurrent(): void {
        switch (this.current) {
            case 1:
                break;
            case 2:
                this.current = --this.current;
                this.errorReset();
                break;
            case 3:
                this.current = --this.current;
                break;
        }
    }

    public checkGeneralInfoValidation() {
        this.citizenshipValue ?
            this.stepOneValidation.citizenship = false : this.stepOneValidation.citizenship = true;
        this.organizationLegalFormValue ?
            this.stepOneValidation.organizationLegalForm = false : this.stepOneValidation.organizationLegalForm = true;
    }

    public checkValidationFirstStep() {
        this.checkGeneralInfoValidation();
        if (!this.checkValidationError(this.stepOneValidation)) {
            if (!this.user?.pin?.length && this.requestCitizenship) {
                this.sandboxCommon.getCommonDocumentSeriesType(this.citizenshipValue);
            }
            this.requestCitizenship = false;
            this.sandbox.getCertFiles(new GetCertFilesModel(
                {
                    sxemId: 2,
                    applicantTypeId: this.organizationLegalFormValue,
                    isApplicantDifferent: this.legalPersonCarrier,
                    appealTypeId: this.certificateStatus,
                    citizenshipId: this.citizenshipValue
                }
            ));
            this.current = 2;
        }
    }

    public incrementCurrent() {
        switch (this.current) {
            case 1:
                this.checkValidationFirstStep();
                break;
            case 2:
                this.checkValidationSecondStep();
                break;
            case 3:
                break;
        }
    }

    public checkPersonalInformationInfoValidation() {
        if (this.citizenshipValue !== 4) {
            if (!(this.user?.pin?.length > 0)) {
                this.checkValidationData(this.errorPersonalInformation
                    , this.validationDocumentNumber(this.personalInformation.documentTypeNumber?.value, this.personalInformation.documentNumber)
                    , 'documentNumber');
                this.checkValidationData(this.errorPersonalInformation,
                    this.personalInformation.documentTypeNumber?.id, 'documentTypeNumber');
                this.checkValidationData(this.errorPersonalInformation
                    , this.validationFin(this.personalInformation.documentTypeNumber?.value, this.personalInformation.fin)
                    , 'fin');
            }
            this.checkValidationData(this.errorPersonalInformation
                , this.validationPhone(this.personalInformation.phone)
                , 'phone'
            );
            this.checkValidationData(this.errorPersonalInformation
                , this.validationEmail(this.personalInformation.email)
                , 'email'
            );

            this.checkValidationData(this.errorPersonalInformation
                , this.validationVoen(this.personalInformation.tin)
                , 'tin'
            );
            if (this.organizationLegalFormValue === 3 && this.certificateStatus === 1) {
                this.checkValidationData(this.errorPersonalInformation
                    , this.personalInformation.legalEntityAddress
                    , 'legalEntityAddress'
                );
            }
            if (this.organizationLegalFormValue === 4 && this.legalPersonCarrier) {
                this.checkValidationData(this.errorPersonalInformation
                    , this.personalInformation.position
                    , 'position'
                );
            }
            if (this.organizationLegalFormValue === 4 && this.legalPersonCarrier && this.certificateStatus === 1) {
                this.checkValidationData(this.errorPersonalInformation
                    , this.personalInformation.legalEntityFullName
                    , 'legalEntityFullName'
                );
                this.checkValidationData(this.errorPersonalInformation
                    , this.personalInformation.positionLegalEntity
                    , 'positionLegalEntity'
                );
            }
            if (this.legalPersonCarrier) {
                this.checkValidationData(this.errorPersonalInformation
                    , this.personalInformation.poweroftheattorneydoc
                    , 'poweroftheattorneydoc'
                );
            }
            if (this.organizationLegalFormValue === 4 && !this.legalPersonCarrier && this.certificateStatus === 1) {
                this.checkValidationData(this.errorPersonalInformation
                    , this.personalInformation.govregistercopy
                    , 'govregistercopy'
                );
            }
            if (this.organizationLegalFormValue === 4 && this.legalPersonCarrier && this.certificateStatus === 1) {
                this.checkValidationData(this.errorPersonalInformation
                    , this.personalInformation.copyExtractFile
                    , 'copyExtractFile'
                );
            }
        } else {
            this.checkValidationData(this.errorPersonalInformation
                , this.validationEmail(this.personalInformation.email)
                , 'email'
            );

            this.checkValidationData(this.errorPersonalInformation
                , this.validationPhone(this.personalInformation.foreignPhone)
                , 'foreignPhone'
            );
            let array = this.checkFile(this.filesForForCertificate);
            this.store.commit('SET_CERT_FILES', array);
            if (!this.validationFiles(this.filesForForCertificate)) {
                this.errorPersonalInformation.filesForCertificate = false;
            } else {
                this.errorPersonalInformation.filesForCertificate = true;
            }
        }
    }

    public checkFile(array: IFileType[]) {
        let newArray = Array.from(array);
        newArray.forEach((item: any) => {
            if (item.isRequired && item.file) {
                item.error = false;
            } else if (item.isRequired) {
                item.error = true;
            }
        });
        return array;
    }

    public validationFiles(array: IFileType[]) {
        return array.some((item: any) => item.error);
    }

    public checkValidationSecondStep() {
        this.checkPersonalInformationInfoValidation();
        if (!this.checkValidationError(this.errorPersonalInformation)) {
            if (this.citizenshipValue !== 4 && !this.user?.name?.length) {
                this.sandboxCommon.getCommonApplicantData( {data:new CertApplicantInfoModel({
                    documentNumber: this.applicantForm.documentNumber,
                    pin: this.applicantForm.fin,
                    documentTypeGroupId: this.citizenshipValue
                }), componentName:'issuing-certificate-conformity-service'}).then((res: any) => {
                    this.getFileForSTep3();
                    this.current = 3;
                });
            } else {
                this.getFileForSTep3();
                this.current = 3;
            }
        }
    }


    public getFileForSTep3() {
        let id: number | null = null;
        if (this.citizenshipValue === 4 || this.certificateStatus === 2) {
            id = 6;
        } else {
            id = this.certificateType;
        }
        this.sandbox.getFilesForForStep3({
            sxemId: id,
            applicantTypeId: this.organizationLegalFormValue,
            isApplicantDifferent: this.legalPersonCarrier,
            appealTypeId: this.certificateStatus,
            citizenshipId: this.citizenshipValue
        });
    }

    public onSelectFileInformation(info: IFileType) {
        let array = Array.from(this.filesForForCertificate);
        array.forEach((item: any) => {
            if (item.id === info.id) {
                item.file = info.file;
            }
        });
        this.store.commit('SET_CERT_FILES', array);

    }

    public unmounted() {
        this.store.commit('SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE');
        this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
        this.store.commit('SET_RESET_LEGAL_PERSONAL');
    }

    public openModal() {
        this.$refs.modal.openModal();
    }

    public closeModal() {
        this.$refs.modal.closeModal();
        this.store.commit('SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE');
        this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
        this.store.commit('SET_RESET_LEGAL_PERSONAL');
        this.current = 1;
        this.$router.push({name: 'home'});
        this.store.commit('SET_APPLICANT_CERTIFICATE_ALL', {
            documentNumber: null,
            documentTypeNumber: null,
            fin: null,
            phone: '+994(',
            email: null,
            tin: null,
            organizationName: null,
            position: null,
            registration: null,
            authorityFile: null,
            legalEntityFullName: null,
            legalEntityAddress: null,
            positionLegalEntity: null,
            copyExtract: null,
            copyExtractFile: null,
            poweroftheattorneydoc: null,
            govregistercopy: null,
        });
    }

    public finallyStep() {
        let check = this.thirdStepValidation();
        if (!check) {
            this.sendData();
        }
    }

    get userData(): IUserData {
        return this.store.getters._getApplicantData;
    }

    public checkSxemId() {
        let sxemId: null | number = null;
        if (this.certificateStatus === 2 || this.citizenshipValue === 4) {
            sxemId = 6;
        } else {
            sxemId = this.certificateType;
        }
        return sxemId;
    }

    public createFormDataForCert() {
        let sxemId = this.checkSxemId();
        let files: IFileType[] = Array.from(this.filesForForStep3);
        if (this.personalInformation.poweroftheattorneydoc) files.push(this.personalInformation.poweroftheattorneydoc);
        if (this.personalInformation.govregistercopy) files.push(this.personalInformation.govregistercopy);
        if (this.personalInformation.copyExtractFile) files.push(this.personalInformation.copyExtractFile);
        if (this.organizationLegalFormValue === 4) {
            this.filesForForCertificate.forEach((item: IFileType) => {
                files.push(item);
            });
        }
        let data = new CertApplicantFormModel(sxemId, this.certificateStatus, this.legalPersonCarrier, files, this.personalInformation, this.citizenshipValue, this.organizationLegalFormValue);
        let number = 0;
        let formData: FormData = this.createFormData({
            SxemId: data.sxemId,
            AppealTypeId: data.appealTypeId,
            isApplicantDifferent: data.isApplicantDifferent,
            applicantDto: data.applicantDto
        });
        data.appealFileDtos?.forEach((item) => {
            if (item.file) {
                formData.append(`AppealFileDtos[${number}].appealFileTypeId`, String(item.appealFileTypeId));
                if (isArray(item.file)) {
                    item.file.forEach(((element: File) => {
                        formData.append(`AppealFileDtos[${number}].File`, element);
                    }));
                } else {
                    if (item.file) {
                        formData.append(`AppealFileDtos[${number}].File`, item.file);
                    }
                }
                number = number + 1;
            }
        });
        return formData;
    }

    public sendData() {
        let formData: FormData = this.createFormDataForCert();
        this.sandbox.postCertAppeal(formData).then((res: any) => {
            if (res.status === 200) {
                this.openModal();
            }
        });
    }


    public thirdStepValidation() {
        let files = this.filesForForStep3.map((item: IFileType) => {
            if (!item.file && item.isRequired) {
                item.error = true;
            } else {
                item.error = false;
            }
            return item;
        });
        this.store.commit('SET_CERT_FILES_STEP3', files);
        return this.filesForForStep3.some((el: IFileType) => el.error);
    }

    public onSelectFile(info: IFileType) {
        let files = this.filesForForStep3.map((item: IFileType) => {
            if (item.id === info.id) {
                item.file = info.file;
            }
            return item;
        });
        this.store.commit('SET_CERT_FILES_STEP3', files);
    }
}