import {ESignCertificates, ESignCertificatesV2} from '../types/sign';

export class ESignUserCertificate {
    public certificate = '';
    public serialNumber = '';
    public name = '';
    public surname = '';
    public patronymic = '';
    public voen = '';
    public structure = '';
    public serial = '';
    public position = '';

    constructor(res?: ESignCertificates) {
        if (res) {
            this.certificate = res.certificates[0].serialNumber;
            const subject = res.certificates[0].subject;
            const arr = subject.split(',');
            this.serialNumber = arr[0].split('=')[1];
            this.name = arr[1].split('=')[1];
            this.surname = arr[2].split('=')[1];
            this.patronymic = arr[3].split('=')[1];
            this.voen = arr[4].split('=')[1];
            this.structure = arr[5].split('=')[1];
            this.serial = arr[6].split('=')[1];
            this.position = arr[7].split('=')[1];
        }
    }
}

export class ESignUserCertificateV2 extends ESignUserCertificate {
    public tsaClient: string | null = null;

    // tslint:disable-next-line:no-any
    constructor(res: ESignCertificatesV2) {
        super();
        if (res && res.output) {
            this.certificate = res.output.certificates[0].serialNumber;
            const subject = res.output.certificates[0].subject;
            const arr = subject.split(',');
            this.serialNumber = arr[0].split('=')[1];
            this.name = arr[1].split('=')[1];
            this.surname = arr[2].split('=')[1];
            this.patronymic = arr[3].split('=')[1];
            this.voen = arr[4].split('=')[1];
            this.structure = arr[5].split('=')[1];
            this.serial = arr[6].split('=')[1];
            this.position = arr[7].split('=')[1];
        }
    }
}

export class ESignFormFileModel {
    signFormat = 'Edoc';
    tsaClientName: string;
    signCertificateSerialNumber: string;
    files: {
        name: string;
        rawData: string;
    }[] = [];

    // tslint:disable-next-line:no-any
    constructor(requestData: any, cert: any) {
        this.tsaClientName = cert.tsaClient;
        this.signCertificateSerialNumber = cert.certificate;
        this.files = requestData;
    }
}
