import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import StepPermitComponent
    from '@/pages/issuance-special-permit-service/components/step-permit/step-permit.component.vue';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import {Validation} from '@/core/mixin/validation';
import {CreateFormData} from '@/core/mixin/mixin';
import {Router} from '@/core/mixin/router';
import {store} from '@/store';
import {IInputPayload} from '@/types/input-payload';
import {load} from 'recaptcha-v3';
import {locale} from '@/assets/db/db.conf.ts';
import {ReCaptcha} from '@/core/mixin/recaptcha';
import {IFileType} from '@/pages/issuing-certificate-conformity-service/types/file-type';
import {SignSandbox} from '@/core/sign/sign.sandbox';
import {IUserData} from '@/pages/appeal-radio-service/types/user-data';
import html2canvas from 'html2canvas';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import {
    createSpecialPermitEntityTemplate,
    createSpecialPermitIndividualTemplate
} from '@/core/file-templates/template/special-permit';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {container} from 'tsyringe';
import {
    IssuingCertificateConformityServiceSandbox
} from '@/pages/issuing-certificate-conformity-service/sandbox/issuing-certificate-conformity-service.sandbox';
import {
    IssuanceSpecialPermitServiceSandbox
} from '@/pages/issuance-special-permit-service/sandbox/issuance-special-permit-service.sandbox';
import {GetAppealFileModel} from '@/pages/issuance-special-permit-service/models/appeal-file.model';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {ICommonInformation, IOptions} from '@/pages/issuance-special-permit-service/types/options';
import {ISPApplicantModel} from '@/pages/issuance-special-permit-service/models/sp-applicant.model';
import {
    IAppealFiles,
    IItemNameFactoryNumbers,
    ISPApplicant
} from '@/pages/issuance-special-permit-service/types/sp-applicant';
import {Login} from '@/core/configs/api';
import {IApplicantData} from '@/types/applicant-data';
import {CertApplicantInfoModel} from '@/pages/issuing-certificate-conformity-service/models/cert-applicant-info.model';
import {isArray} from 'rxjs/internal-compatibility';
import {IServiceCommon} from '@/types/service-common';
import swal from 'sweetalert2';

@Options({
    name: 'issuance-special-permit-service-component',
    components: {
        StepPermitComponent,
        CustomerInfoComponent,
        ServiceNameComponent,
        NotDataComponent
    },
    watch: {
        citizenshipValue: {
            handler: 'changeCitizenshipValue',
            immediate: false,
            deep: false,
        },
        documentSeriesType: {
            handler: 'changeDocumentSeriesType',
            immediate: false,
            deep: false,
        }
    }
})
export default class IssuanceSpecialPermitServiceComponent extends mixins(Validation, CreateFormData, Router, ReCaptcha) {
    public sandbox: IssuanceSpecialPermitServiceSandbox = container.resolve(IssuanceSpecialPermitServiceSandbox);
    public siteKey = '6LdZg8IfAAAAAEzXF_7RQY0AzNjQ4zhPPR-5Tf5P';
    public requestCitizenship = false;
    public store = store;
    public step: number = 3;
    public current: number = 1;
    public locale$: any = locale;
    public locale: any = null;
    public $refs!: {
        modal: {
            openModal: () => void;
            closeModal: () => void
        },
    };
    public pdfOptions = {
        margin: [75, 113, 75, 75, 56],
        image: {
            type: 'png',
            quality: 1,
        },
        html2canvas: {scale: 3},
        jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'p',
        },
    };
    public errorOptions = {
        restrictedCivilCirculation: false,
        requiredCrucialData: false
    };

    public changeDocumentSeriesType(val: any) {
        this.onChangeInput({
            type: 'documentTypeNumber',
            value: val[0]
        });
    }

    get stepDescription() {
        return [
            {
                number: 1,
                description: 'Ümumi məlumat',
            },
            {
                number: 2,
                description: 'Şəxsi məlumatlar',
            },
            {
                number: 3,
                description: 'Müraciət üzrə parametrlər',
            },
        ];
    }

    public errorPersonalInformation = {
        documentNumber: false,
        documentTypeNumber: false,
        fin: false,
        phone: false,
        email: false,
        tin: false,
        organization: false,
        position: false,
        registration: false,
        authorityFile: false,
        legalEntityFullName: false,
        positionLegalEntity: false,
        copyExtractFile: false,
        legalEntityAddress: false,
        legalPersonPosition: false,
        address: false
    };
    public stepOneValidation = {
        citizenship: false,
        organizationLegalForm: false
    };

    get filesForStep2() {
        return this.store.getters._getFiles;
    }

    get filesForStep3(): IFileType[] {
        return this.store.getters._getFilesOptions;
    }

    get organizationLegalForm() {
        return this.store.getters._getOrganPersonType;
    }

    get citizenship() {
        return this.store.getters._getCitizenship;
    }

    get citizenshipValue() {
        return this.store.getters._getCitizenshipValue;
    }

    get organizationLegalFormValue() {
        return this.store.getters._getOrganizationLegalFormValue;
    }

    get legalPersonCarrier() {
        return this.store.getters._getLegalPersonCarrier;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get userData(): IApplicantData {
        return this.store.getters._getApplicantData;
    }

    get organizationName() {
        this.setPersonalInformation({
            type: 'organizationName',
            value: this.store.getters._getRadioOrganizationName
        });
        return this.store.getters._getRadioOrganizationName;
    }

    get personalInformation() {
        return this.store.getters._getApplicantFormSpecialPermit;
    }

    get documentSeriesType() {
        return this.store.getters._getDocumentSeriesType;
    }

    get applicantForm() {
        return this.store.getters._getApplicantFormSpecialPermit;
    }

    get options(): IOptions {
        return this.store.getters._getOptions;
    }

    get restrictedCirculationItems() {
        return this.store.getters._getRestrictedCirculationItems;
    }

    get requiredCrucialData() {
        return this.store.getters._getRequiredCrucialData;
    }


    get user() {
        if (this.store.getters._getUserFullData?.pin?.length) {
            if (this.store.getters._getUserFullData?.pin?.length === 7) {
                this.store.commit('SET_CITIZENSHIP_VALUE', 1);
            } else {
                this.store.commit('SET_CITIZENSHIP_VALUE', 2);
            }
        }
        return this.store.getters._getUserFullData;
    }

    public setPersonalInformation(info: IInputPayload) {
        this.store.commit('SET_APPLICANT_SPECIAL_PERMIT', info);
    }


    public mounted() {
        this.locale$.subscribe((res: any) => {
            if (res && res.elements) {
                this.locale = res.issuingCertificateConformity;
            }
        });
        this.sandboxCommon.getCommonCitizenship();
        this.sandboxCommon.getCommonOrganPersonType(2);
        this.sandbox.getRestrictedCirculationItem().then((res: any) => {
            this.store.commit('SET_OPTIONS', {
                type: 'restrictedCivilCirculation',
                value: this.restrictedCirculationItems[0]
            });
        });
        this.getServiceData();
    }

    public unmounted() {
        this.store.commit('SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE');
        this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
        this.store.commit('SET_RESET_LEGAL_PERSONAL');
        this.store.commit('SET_PERMIT_FILES', []);
        this.store.commit('SET_OPTIONS_FILES', []);
        this.store.commit('SET_APPLICANT_DATA', {
            name: null,
            surname: null,
            fatherName: null,
            pin: null,
            birthDate: null,
            image: null,
            address: null,
            birthAddress: null,
            document: null,
            documentType: null,
            documentNumber: null,
            dateOfIssue: null,
            registeredDepartmentName: null,
        });
    }

    public onChangeInput(info: IInputPayload) {
        this.store.commit('SET_APPLICANT_SPECIAL_PERMIT', info);
    }

    public checkTin() {
        this.onCheckTin({
            tin: this.personalInformation.tin,
            applicantTypeId: this.organizationLegalFormValue
        });
    }

    public addItemInformation() {
        let array = Array.from(this.options.commonInformation),
            payload: IInputPayload = {
                value: null,
                type: null
            };
        array.push({
            name: null,
            factorySerialNumber: null
        });
        payload = {
            value: array,
            type: 'commonInformation'
        };
        this.store.commit('SET_OPTIONS', payload);
    }


    public deleteItemInformation(index: number) {
        let array = Array.from(this.options.commonInformation),
            payload: IInputPayload = {
                value: null,
                type: null
            };
        array.splice(index, 1);
        payload = {
            value: array,
            type: 'commonInformation'
        };
        this.store.commit('SET_OPTIONS', payload);
    }

    public decrementCurrent(): void {
        switch (this.current) {
            case 1:
                break;
            case 2:
                this.current = --this.current;
                this.errorReset();
                break;
            case 3:
                this.current = --this.current;
                break;
        }
    }

    public errorReset() {
        this.errorPersonalInformation = {
            documentNumber: false,
            documentTypeNumber: false,
            fin: false,
            phone: false,
            email: false,
            tin: false,
            organization: false,
            position: false,
            registration: false,
            authorityFile: false,
            legalEntityFullName: false,
            positionLegalEntity: false,
            copyExtractFile: false,
            legalEntityAddress: false,
            legalPersonPosition: false,
            address: false
        };
    }

    public incrementCurrent() {
        switch (this.current) {
            case 1:
                this.checkValidationFirstStep();
                break;
            case 2:
                this.checkValidationSecondStep();
                break;
            case 3:
                break;
        }
    }

    public changeCitizenshipValue(val: any, oldVal: any) {
        if (val !== oldVal) {
            this.requestCitizenship = true;
        }
    }

    public checkValidationFirstStep() {
        this.checkGeneralInfoValidation();
        if (!this.checkValidationError(this.stepOneValidation)) {
            if (!this.user?.pin?.length && this.requestCitizenship) {
                this.sandboxCommon.getCommonDocumentSeriesType(this.citizenshipValue);
            }
            this.sandbox.getAppealFileSP(new GetAppealFileModel({
                step: 2,
                applicantTypeId: this.organizationLegalFormValue,
                isApplicantDifferentWithJuridical: this.legalPersonCarrier
            }));
            this.requestCitizenship = false;
            this.current = 2;
        }
    }

    public onSelected(info: ISearchSelect) {
        this.sandbox.getAppealFileSP(new GetAppealFileModel({
            step: this.current,
            applicantTypeId: this.organizationLegalFormValue,
            isApplicantDifferentWithJuridical: this.legalPersonCarrier,
            restrictedCirculationItemId: info.id
        }));
    }

    public checkGeneralInfoValidation() {
        this.citizenshipValue ?
            this.stepOneValidation.citizenship = false : this.stepOneValidation.citizenship = true;
        this.organizationLegalFormValue ?
            this.stepOneValidation.organizationLegalForm = false : this.stepOneValidation.organizationLegalForm = true;
    }

    public onSelectFile(info: IFileType) {
        let files = this.filesForStep3.map((item: IFileType) => {
            if (item.id === info.id) {
                item.file = info.file;
            }
            return item;
        });
        this.store.commit('SET_OPTIONS_FILES', files);
    }

    public checkValidationSecondStep() {
        this.checkPersonalInformationInfoValidation();
        if (!this.checkValidationError(this.errorPersonalInformation)) {
            if (!this.user.name.length) {
                let payload = {
                    componentName: 'issuance-special-permit-service',
                    data: new CertApplicantInfoModel({
                        documentNumber: this.applicantForm.documentNumber,
                        pin: this.applicantForm.fin,
                        documentTypeGroupId: this.citizenshipValue
                    })
                };
                this.sandboxCommon.getCommonApplicantData(payload);
            }
            if (this.filesForStep3.length === 0) {
                this.sandbox.getAppealFileOptionSP(new GetAppealFileModel({
                    step: 3,
                    applicantTypeId: this.organizationLegalFormValue,
                    isApplicantDifferentWithJuridical: this.legalPersonCarrier,
                    restrictedCirculationItemId: this.restrictedCirculationItems[0].id
                }));
            }

            this.current = 3;
        }
    }

    public closeModal() {
        this.$refs.modal.closeModal();
        this.store.commit('SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE');
        this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
        this.store.commit('SET_RESET_LEGAL_PERSONAL');
        this.store.commit('SET_APPLICANT_SPECIAL_PERMIT_RESET');
        this.store.commit('SET_OPTIONS_RESET');
        this.current = 1;
        this.$router.push({name: 'home'});
    }

    public setOptionInformation(info: IInputPayload) {
        this.store.commit('SET_OPTIONS', info);
        if (info.type === 'restrictedCivilCirculation') {
            this.store.commit('SET_OPTIONS_FILES', []);
            this.sandbox.getAppealFileOptionSP(new GetAppealFileModel({
                step: 3,
                applicantTypeId: this.organizationLegalFormValue,
                isApplicantDifferentWithJuridical: this.legalPersonCarrier,
                restrictedCirculationItemId: info.value.id
            }));
            if (info.value.id === 4) {
                this.sandbox.getRequiredCrucialData();
            }
        }
    }

    public checkPersonalInformationInfoValidation() {
        this.checkValidationData(this.errorPersonalInformation
            , this.validationPhone(this.personalInformation.phone)
            , 'phone'
        );
        this.checkValidationData(this.errorPersonalInformation
            , this.validationEmail(this.personalInformation.email)
            , 'email'
        );

        this.checkValidationData(this.errorPersonalInformation
            , this.validationVoen(this.personalInformation.tin)
            , 'tin'
        );
        if (this.organizationLegalFormValue === 3 && !this.legalPersonCarrier) {
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.address
                , 'address'
            );
        }
        if (this.organizationLegalFormValue === 4 && !this.legalPersonCarrier) {
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.positionLegalEntity
                , 'positionLegalEntity'
            );
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.legalEntityAddress
                , 'legalEntityAddress'
            );
        }
        if (this.organizationLegalFormValue === 4 && this.legalPersonCarrier) {
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.legalEntityFullName
                , 'legalEntityFullName'
            );
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.positionLegalEntity
                , 'positionLegalEntity'
            );
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.positionLegalEntity
                , 'position'
            );
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.positionLegalEntity
                , 'addressLegalEntity'
            );
        }

        if (this.legalPersonCarrier) {
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.poweroftheattorneydoc
                , 'poweroftheattorneydoc'
            );
        }
        if (this.organizationLegalFormValue === 4 && !this.legalPersonCarrier) {
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.govregistercopy
                , 'govregistercopy'
            );
        }
        if (this.organizationLegalFormValue === 4 && this.legalPersonCarrier) {
            this.checkValidationData(this.errorPersonalInformation
                , this.personalInformation.copyExtractFile
                , 'copyExtractFile'
            );
        }
    }

    public finallyStep() {
        this.checkThirstValidationStep();
        if (!this.checkFilesValidation() && !this.errorOptions.restrictedCivilCirculation) {
            if (this.restrictedCirculationItems?.id === 4) {
                if (!this.errorOptions.requiredCrucialData) {
                    this.sendData();
                }
            } else {
                this.sendData();
            }
        }
    }

    public sendData() {
        let data = new ISPApplicantModel(this.citizenshipValue,
            {...this.personalInformation, ...this.options},
            this.organizationLegalFormValue,
            this.filesForStep3,
            this.legalPersonCarrier
        );
        let formData = this.createFormDataSP(data);
        this.sandbox.postCreateAppealSP(formData).then((res: any) => {
            if (res.status === 200) {
                if (!res.data.status) {
                    this.openModal();
                }
                if (res?.data?.status === 404) {
                    swal.fire({
                        position: 'center',
                        icon: 'info',
                        html: res?.data?.title,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }
            }
        });
    }

    public checkFilesValidation() {
        let files = this.filesForStep3.map((item: IFileType) => {
            if (!item.file && item.isRequired) {
                item.error = true;
            } else {
                item.error = false;
            }
            return item;
        });
        this.store.commit('SET_OPTIONS_FILES', files);
        return this.filesForStep3.some((el: IFileType) => el.error);
    }

    public checkThirstValidationStep() {
        this.options.restrictedCivilCirculation ? this.errorOptions.restrictedCivilCirculation = false : this.errorOptions.restrictedCivilCirculation = true;
        if (this.options.restrictedCivilCirculation?.id === 4) {
            this.options.requiredCrucialData ? this.errorOptions.requiredCrucialData = false : this.errorOptions.requiredCrucialData = true;
        }
    }

    public openModal() {
        this.$refs.modal.openModal();
    }

    public createFormDataSP(data: ISPApplicant) {
        const formData = new FormData();
        if (!this.user.name.length) {
            data?.applicant?.documentTypeGroupId !== null ? formData.append(`Applicant.DocumentTypeGroupId`, String(data.applicant?.documentTypeGroupId)) : null;
            data?.applicant?.documentTypeId !== null ? formData.append(`Applicant.DocumentTypeId`, String(data.applicant?.documentTypeId)) : null;
            data?.applicant?.documentNumber !== null ? formData.append(`Applicant.DocumentNumber`, String(data.applicant?.documentNumber)) : null;
            data?.applicant?.pin !== null ? formData.append(`Applicant.Pin`, String(data.applicant?.pin)) : null;
        }
        data?.applicant?.phone !== null ? formData.append(`Applicant.Phone`, String(data.applicant?.phone)) : null;
        data?.applicant?.email !== null ? formData.append(`Applicant.Email`, String(data.applicant?.email)) : null;
        data?.applicant?.applicantTypeId !== null ? formData.append(`Applicant.ApplicantTypeId`, String(data.applicant?.applicantTypeId)) : null;

        data?.applicant?.applicantOrganization?.name !== null ? formData.append(`Applicant.ApplicantOrganization.Name`, String(data.applicant?.applicantOrganization?.name)) : null;
        data?.applicant?.applicantOrganization?.tin !== null ? formData.append(`Applicant.ApplicantOrganization.Tin`, String(data.applicant?.applicantOrganization?.tin)) : null;
        data?.applicant?.applicantOrganization?.position !== null ? formData.append(`Applicant.ApplicantOrganization.Position`, String(data.applicant?.applicantOrganization?.position)) : null;
        data?.applicant?.applicantOrganization?.isApplicantDifferentWithJuridical !== null ? formData.append(`Applicant.ApplicantOrganization.IsApplicantDifferentWithJuridical`, String(data.applicant?.applicantOrganization?.isApplicantDifferentWithJuridical)) : null;
        data?.applicant?.applicantOrganization?.address !== null ? formData.append(`Applicant.ApplicantOrganization.Address`, String(data.applicant?.applicantOrganization?.address)) : null;

        data?.applicant?.juridicalPersonInformation?.fullname !== null ? formData.append(`Applicant.JuridicalPersonInformation.Fullname`, String(data.applicant?.juridicalPersonInformation?.fullname)) : null;
        data?.applicant?.juridicalPersonInformation?.position !== null ? formData.append(`Applicant.JuridicalPersonInformation.Position`, String(data.applicant?.juridicalPersonInformation?.position)) : null;
        data?.appeal?.restrictedCirculationItemId !== null ? formData.append(`Appeal.RestrictedCirculationItemId`, String(data.appeal?.restrictedCirculationItemId)) : null;
        // formData.append(`Appeal.RequiredCrucialDataId`, String(data.appeal?.requiredCrucialDataId));

        data?.appeal?.requiredCrucialDataId !== null ? formData.append(`Appeal.RequiredCrucialDataId`, String(data?.appeal?.requiredCrucialDataId)) : null;
        let number = 0;
        data?.appeal?.appealFiles?.forEach((item: IAppealFiles, index) => {

            if (item?.file !== null) {
                formData.append(`Appeal.AppealFiles[${number}].FileInputId`, String(item?.fileInputId));
                if (item?.file.name) {
                    formData.append(`Appeal.AppealFiles[${number}].Files`, item?.file);
                } else if (!item?.file.name) {
                    for (const [key, value] of Object.entries(item?.file)) {
                        formData.append(`Appeal.AppealFiles[${number}].Files`, value);
                    }
                }
                number = number + 1;
            }
        });
        return formData;

    }
}
