<style src="./form-appeal.component.scss" lang="scss"></style>

<template>
  <div class="form-appeal">
    <div class="row">
      <div class="col-12">
        <status-info-component
            v-if="!userForm.new && !userForm.edit && userForm.appealStatusId"
            :link="userForm.answerFile?.url"
            title="Mövcud müraciət"
            :statusName="userForm.appealStatusName"
            :statusColor="setStatus(userForm.appealStatusId)"
        />

        <card-info-component
            class="mb-40" color="red"
            v-if="!userForm.new && !userForm.edit  && (userForm.appealStatusId===3 || userForm.appealStatusId===4 || userForm.appealStatusId===5)">
          <template v-slot:title>İmtina səbəbi</template>
          <template v-slot:description>{{ userForm.rejectionReason }}</template>
        </card-info-component>
      </div>
      <div class="col-12">
        <cancel-info-component
            v-if="!userForm.new && !userForm.edit
            && (userForm.appealStatusId===2 || userForm.appealStatusId===4 || userForm.appealStatusId===5)"
            @onNewForm="resetForm"
            @changedFormData="changedFormData"
            @onCancel="$emit('onCancel')"
            class="mt-30" :userForm="userForm"
        />
      </div>
      <div class="col-12">
        <user-information-component
            v-if="userForm.new || userForm.edit || userForm.appealStatusId===1 ||userForm.appealStatusId===3"
            :organization="userData?.structureName" :tin="userData?.tin"/>
      </div>
      <div class="col-12">
        <user-form-component
            :ip4="ip4"
            :ip6="ip6"
            v-if="userForm.new || userForm.edit || ((userForm.appealStatusId===3 ||userForm.appealStatusId===1) && userForm.disabled) || userForm.appealStatusId===null"
            @onNewForm="resetForm"
            :entity="userData.entity"
            @onSingFiles="onSingFiles"
            @sendData="sendData"
            :signBtnDisabled="signBtnDisabled"
            :sendBtnDisabled="sendBtnDisabled"
            :applicantActivityAddress="applicantActivityAddress"
            :applicantTypeActivities="applicantTypeActivities"
            :applicantTypesTelecommunicationsService="applicantTypesTelecommunicationsService"
            :applicantScopeActivity="applicantScopeActivity"
            :userForm="userForm" @changedFormData="changedFormData"/>
      </div>
    </div>
  </div>
</template>

<script src="./form-appeal.component.ts"></script>
