import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import IpInputComponent from '@/core/shared/ip-input/ip-input.component.vue';

@Options({
    name: 'ip-wrapper-component',
    components: {
        IpInputComponent
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        ips: {
            type: String,
            default: ''
        }
    },
    watch: {
        ips: {
            handler: 'changeIps',
            immediate: false,
            deep: false,
        }
    }
})

export default class IpWrapperComponent extends Vue {
    public ips: string = '';
    public width = 150;
    public check = false;
    public arrData: any[] = [];

    public changeIps(val: any) {
        if (!this.check && val !== null) {
            if (val) {
                let arr: any[] = [];
                let ipsArr = val.split(',');

                ipsArr.forEach((item: any, index: any) => {
                    if (item.indexOf('-') !== -1) {
                        let indexEl = item.indexOf('-');
                        let firstIp = `${item.substr(0, indexEl + 1)}`,
                            secondIp = `${item.substr(indexEl + 1, item.length)}`;
                        arr.push({
                            id: arr.length === 0 ? 0 : arr[arr.length - 1].id + 1,
                            ip: firstIp,
                            firstFocus: false,
                            endFocus: false,
                            separator: '',
                            checkValidation: false
                        });
                        arr.push({
                            id: arr.length === 0 ? 0 : arr[arr.length - 1].id + 1,
                            ip: index === ipsArr.length - 1 ? secondIp : `${secondIp},`,
                            firstFocus: false,
                            endFocus: false,
                            separator: '',
                            checkValidation: false
                        });
                    } else {
                        arr.push({
                            id: arr.length === 0 ? 0 : arr[arr.length - 1].id + 1,
                            ip: ipsArr.length > 1 && index !== ipsArr.length - 1 ? `${item},` : item,
                            firstFocus: false,
                            endFocus: false,
                            separator: '',
                            checkValidation: false
                        });
                    }
                });
                this.arrData = arr;
                this.width = this.arrData.length * 150;
            }
            this.check = true;
        }
        if (val === null || val?.length === 0) {
            this.arrData = [
                {
                    id: 0,
                    ip: '',
                    firstFocus: false,
                    endFocus: false,
                    separator: '',
                    checkValidation: false
                }
            ];
        }
    }

    public action(info: any, index: any) {
        if (info === '-') {
            if (index === 0 && !this.arrData[index + 1]) {
                this.arrData.splice(index + 1, 0, {
                    id: this.arrData[this.arrData.length - 1].id + 1,
                    ip: '',
                    firstFocus: false,
                    endFocus: false,
                    separator: '',
                    checkValidation: false
                });
                this.changePlusWidth();
            } else {
                if (this.arrData[index - 1]) {
                    if (this.arrData[index - 1]?.ip.indexOf('-') === -1) {
                        this.arrData.splice(index + 1, 0, {
                            id: this.arrData[index - 1].id + 1,
                            ip: '',
                            firstFocus: false,
                            endFocus: false,
                            separator: '',
                            checkValidation: false
                        });
                        this.changePlusWidth();
                    } else {
                        this.arrData[index].separator = ',';
                        if (!this.arrData[index + 1]) {
                            this.arrData.push({
                                id: this.arrData[this.arrData.length - 1].id + 1,
                                ip: '',
                                firstFocus: false,
                                endFocus: false,
                                separator: '',
                                checkValidation: false
                            });
                            this.changePlusWidth();
                        }
                    }
                }

            }
        }
        if ((info === ',') && !this.arrData[index + 1]) {
            this.arrData.push({
                id: this.arrData[this.arrData.length - 1].id + 1,
                ip: '',
                firstFocus: false,
                endFocus: false,
                separator: '',
                checkValidation: false
            });
            this.changePlusWidth();
        }
        this.$emit('selectedIps', this.createdIp());
    }

    public createdIp() {
        let data = '';
        this.arrData.forEach((item: any, index) => {
            data = `${data}${item.ip}`;
        });
        return data;
    }

    public changeIP(info: any, index: any) {
        let id = this.arrData[index].id;
        this.arrData[index] = {
            id: id,
            ip: info.ip1,
            firstFocus: false,
            endFocus: false,
            separator: this.arrData[index].separator,
            checkValidation: false
        };
        this.$emit('selectedIps', this.createdIp());
    }

    public deleteItem(index: any) {
        if (this.arrData.length > 1) {
            this.arrData = this.arrData.filter((item, indexItem) => indexItem !== index);
            this.changeMinutesWidth();
            this.arrData[index - 1].endFocus = true;
        }
    }

    public changedEndFocus(index: any) {
        if (this.arrData[index + 1]) {
            this.arrData[index + 1].firstFocus = true;
        }
    }

    public changedStartFocus(index: any) {
        if (this.arrData[index - 1]) {
            this.arrData[index - 1].endFocus = true;
        }
    }

    public onsStartFocus(index: any) {
        this.arrData[index].firstFocus = false;
    }

    public onsEndFocus(index: any) {
        this.arrData[index].endFocus = false;
    }

    public changePlusWidth() {
        this.width = this.width + 150;
    }

    public changeMinutesWidth() {
        this.width = this.width - 150;
    }

    public mounted() {
        if (this.ips) {
            let arr: any[] = [];
            let ipsArr = this.ips.split(',');

            ipsArr.forEach((item: any, index: any) => {
                if (item.indexOf('-') !== -1) {
                    let indexEl = item.indexOf('-');
                    let firstIp = `${item.substr(0, indexEl + 1)}`,
                        secondIp = `${item.substr(indexEl + 1, item.length)}`;
                    arr.push({
                        id: arr.length === 0 ? 0 : arr[arr.length - 1].id + 1,
                        ip: firstIp,
                        firstFocus: false,
                        endFocus: false,
                        separator: '',
                        checkValidation: false
                    });
                    arr.push({
                        id: arr.length === 0 ? 0 : arr[arr.length - 1].id + 1,
                        ip: index === ipsArr.length - 1 ? secondIp : `${secondIp},`,
                        firstFocus: false,
                        endFocus: false,
                        separator: '',
                        checkValidation: false
                    });
                } else {
                    arr.push({
                        id: arr.length === 0 ? 0 : arr[arr.length - 1].id + 1,
                        ip: ipsArr.length > 1 && index !== ipsArr.length - 1 ? `${item},` : item,
                        firstFocus: false,
                        endFocus: false,
                        separator: '',
                        checkValidation: false
                    });
                }
            });
            this.arrData = arr;
            this.width = this.arrData.length * 150;
        } else  {
            let arr: any[] = [];
            arr.push({
                id: arr.length === 0 ? 0 : arr[arr.length - 1].id + 1,
                ip: '',
                firstFocus: false,
                endFocus: false,
                separator: '',
                checkValidation: false
            });
            this.arrData = arr;
            this.width = this.arrData.length * 150;
        }
    }


}
