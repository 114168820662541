import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import StepDescriptionComponent from '@/core/shared/step-description/step-description.component.vue';
import ProgressbarComponent from '@/core/shared/progressbar/progressbar.component.vue';
import GeneralInformationFormComponent
    from '@/pages/appeal-radio-service/components/general-information-form/general-information-form.component.vue';
import PersonalInformationFormComponent
    from '@/pages/appeal-radio-service/components/personal-information-form/personal-information-form.component.vue';
import RadioInformationFormComponent
    from '@/pages/appeal-radio-service/components/radio-Information-form/radio-Information-form.component.vue';
import PostFilesComponent
    from '@/pages/registration-radio-electronic-devices-service/components/post-files/post-files.component.vue';

@Options({
    name: 'step-registration-radio-component',
    components: {
        StepDescriptionComponent,
        ProgressbarComponent,
        GeneralInformationFormComponent,
        PersonalInformationFormComponent,
        RadioInformationFormComponent,
        PostFilesComponent
    },
    props: {
        userLogin: {
            type: Boolean,
            default: false
        },
        userData: {
            type: Object, require: true
        },
        documentSeriesType: {
            type: Array, require: true
        },
        legalPersonCarrier: {
            type: [Boolean, null], require: true
        },
        organizationLegalForm: {
            type: Array, require: true
        },
        citizenship: {
            type: Array, require: true
        },
        stepDescription: {
            type: Array,
            require: true
        },
        current: {
            type: Number, require: true
        },
        step: {
            type: Number, require: true
        },
        citizenshipValue: {
            type: Number, require: true
        },
        organizationLegalFormValue: {
            type: Number, require: true
        },
        stepOneValidation: {
            type: Object,
            require: true
        },
        personalInformation: {
            type: Object,
            require: true
        },
        errorPersonalInformation: {
            type: Object,
            require: true
        },
        errorRadioInformation: {
            type: Object,
            require: true
        },
        radioInformation: {
            type: Object,
            require: true
        },

        technology: {
            type: Array,
            require: true
        },
        organizationName: {
            type: String, require: false
        },
        fileTypesKeyValues: {
            type: Array,
            require: true
        },
        appealTechnologyKeyValues: {
            type: Array,
            require: true
        },
        getPostCreateRedAppeal:{
            type: Object,
            require: true
        },
        errorFiles:{
            type: Array,
            require: true
        },
        errorRedAppealTechnologyId:{
            type: Boolean,
            require: true
        }
    }
})

export default class StepRegistrationRadioComponent extends Vue {
    public checkTin() {
        this.$emit('checkTin');
    }
    public selectTechnology() {
        this.$emit('selectTechnology')
    }
}