import {ICertApplicantInfo} from '@/pages/issuing-certificate-conformity-service/types/cert-applicant-info';

export class CertApplicantInfoModel {
    public documentNumber: string | null | number = null;
    public pin: string | null = null;
    public documentTypeGroupId: string | null = null;

    constructor(data: ICertApplicantInfo) {
        this._setDocumentNumber(data);
        this._setPin(data);
        this._setDocumentTypeGroupId(data);
    }

    private _setDocumentNumber({documentNumber}: ICertApplicantInfo) {
        this.documentNumber = documentNumber;
    }

    private _setPin({pin}: ICertApplicantInfo) {
        this.pin = pin;
    }

    private _setDocumentTypeGroupId({documentTypeGroupId}: ICertApplicantInfo) {
        this.documentTypeGroupId = documentTypeGroupId;
    }
}