import {Login} from '@/core/configs/api';

export class PostAppealModel {
    public activityAddressId: string | null | number = null;
    public applicantActivityAddress: string | null | number = null;
    public juridicalAddress: string | null = null;
    public juridicalPersonFullName: string | null = null;
    public position: string | null = null;
    public internetResourceLink: string | null = null;
    public IPvFour: string | null = null;
    public IPvSix: string | null = null;
    public serviceDate: string | null = null;
    public ASNumber: string | null = null;
    public applicantPhoneNumber: string | null = null;
    public applicantFaxNumber: string | null = null;
    public applicantMailAddress: string | null = null;
    public appealActivityScopeIds: string | null = null;
    public appealInternetTelecommunicationsServiceTypeIds: string | null = null;
    public appealActivityTypeIds: string | null = null;
    public appealFileDtos: { appealFileTypeId: number, file: File }[]  = [];

    constructor(data: any) {
        this._setActivityAddressId(data);
        this._setApplicantActivityAddress(data);
        this._setJuridicalAddress(data);
        this._setJuridicalPersonFullName(data);
        this._setPosition(data);
        this._setInternetResourceLink(data);
        this._setIPvFour(data);
        this._setIPvSix(data);
        this._setServiceDate(data);
        this._setASNumber(data);
        this._setApplicantPhoneNumber(data);
        this._setApplicantFaxNumber(data);
        this._setApplicantMailAddress(data);
        this._setAppealActivityScopeIds(data);
        this._setAppealInternetTelecommunicationsServiceTypeIds(data);
        this._setAppealActivityTypeIds(data);
        this._setAppealFileDtos(data);
    }

    private _setApplicantActivityAddress({activityAddress}: any) {
        this.applicantActivityAddress = activityAddress;
    }

    private _setJuridicalAddress({juridicalAddress}: any) {
        this.juridicalAddress = juridicalAddress;

    }

    private _setJuridicalPersonFullName({juridicalPersonFullName}: any) {
        this.juridicalPersonFullName = juridicalPersonFullName;
    }

    private _setPosition({position}: any) {
        this.position = position;
    }

    private _setInternetResourceLink({applicantInternetAddress}: any) {
        this.internetResourceLink = applicantInternetAddress;
    }

    private _setIPvFour({iPvFour}: any) {
        this.IPvFour = iPvFour;
    }

    private _setIPvSix({iPvSix}: any) {
        this.IPvSix = iPvSix;
    }

    private _setServiceDate({serviceDate}: any) {
        this.serviceDate = serviceDate.toISOString();
    }

    private _setASNumber({asNumber}: any) {
        this.ASNumber = asNumber;
    }

    private _setApplicantPhoneNumber({applicantPhoneNumber}: any) {
        this.applicantPhoneNumber = applicantPhoneNumber;
    }

    private _setApplicantFaxNumber({applicantFaxNumber}: any) {
        this.applicantFaxNumber = applicantFaxNumber;
    }

    private _setApplicantMailAddress({applicantEmail}: any) {
        this.applicantMailAddress = applicantEmail;
    }

    private _setAppealActivityScopeIds({applicantScopeActivity}: any) {
        this.appealActivityScopeIds = applicantScopeActivity;
    }

    private _setAppealInternetTelecommunicationsServiceTypeIds({applicantTypesTelecommunicationsService}: any) {
        this.appealInternetTelecommunicationsServiceTypeIds = applicantTypesTelecommunicationsService;
    }

    private _setAppealActivityTypeIds({applicantTypeActivities}: any) {
        this.appealActivityTypeIds = applicantTypeActivities;
    }

    private _setAppealFileDtos({sendSignFile}: any) {
        this.appealFileDtos.push({
                appealFileTypeId: 1,
                file: sendSignFile
            });
    }

    private _setActivityAddressId(data: any) {
        this.activityAddressId = 1;
    }
}