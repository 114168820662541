import axios, {AxiosResponse} from 'axios';
import {apis} from '../constants/apis';
// @ts-ignore
import * as toastr from 'toastr';
import {messages} from '../constants/messages';
import {getLang} from '../constants/configs';
import {ESignFormFileModel, ESignUserCertificateV2} from '../models/sign.model';
import {ESignCertificatesV2, TSAClients} from '../types/sign';

export class SignService {
    private _cert: string | undefined;
    private version = 0;

    /**
     * Get installed version of
     */
    getVersion() {
        return new Promise((resolve, reject) => {

            this.checkV2().then(res => {

                this.version = 2;
                resolve(2);
            }).catch(error => {
                this.checkV1().then(res => {
                    this.version = 1;
                    resolve(1);
                }).catch(error => {
                    toastr.error(messages[getLang()].nonValidVersionDetected);
                    reject({
                        message: messages[getLang()].nonValidVersionDetected,
                        code: 500,
                    });
                });
            });
        });
    }

    public cert() {
        return new Promise((resolve, reject) => {
            this.getVersion().then((res: any) => {
                if (this.version === 2) {
                    this.certV2().then(cert => {

                        resolve(cert);
                    });
                } else if (this.version === 1) {
                    this.cert().then(cert => {
                        resolve(cert);
                    });
                } else {
                    reject(null);
                }
            });

        });
    }

    /**
     * Check Version 2 ESigner
     * @private
     * @returns Promise
     */
    private checkV2(): Promise<any> {
        return axios({
            method: 'GET',
            url: apis.checkV2
        });
    }

    /**
     * Check Version 1 ESigner
     * @private
     * @returns Promise
     */
    private checkV1(): Promise<any> {
        return axios({
            method: 'GET',
            url: apis.checkV1
        });
    }

    private certV2(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: apis.certificatesV2
            }).then((certv2Response: AxiosResponse<ESignCertificatesV2>) => {
                const data = new ESignUserCertificateV2(certv2Response.data);
                this._cert = data.certificate;
                axios({
                    method: 'GET',
                    url: apis.tsa
                }).then((tsaResponse: AxiosResponse<TSAClients>) => {
                    const response = tsaResponse.data;
                    data.tsaClient = response && response.output && response.output.tsaClients ? response.output.tsaClients[0] : null;
                    resolve(data);
                }).catch(error => {
                    console.error('TSA Sertifikat səhfi!', error);
                    toastr.error(messages[getLang()].certV2TSAError);
                    reject(error);
                });
            }).catch(error => {
                console.error('Sertifikat səhfi!', error);
                toastr.error(messages[getLang()].certError);
                reject(error);
            });
        });
    }

    status() {
        return new Promise((resolve, reject) => {
            if (this.version === 1) {
                axios({
                    method: 'GET',
                    url: apis.status
                }).then(response => {
                    resolve(response);
                });
            }
            resolve(null);
        });
    }

    /**
     * Sign file
     * @param requestData
     */
    public sign(requestData: any, cert: any) {
        return new Promise((resolve, reject) => {
            if (this.version === 1) {
                const form = new FormData();
                form.append('authCertificateSerialNumber', typeof this._cert === 'string' ? this._cert : '');
                for (let [i, index] of requestData) {
                    form.append(`files${[index]}`, i, requestData.serialNumber);
                }
                axios({
                    method: 'POST',
                    url: apis.signV1,
                    data: form
                }).then((response: AxiosResponse<any>) => {
                    const signed = response.data;
                    resolve(signed);
                });
            } else if (this.version === 2) {
                const form = new ESignFormFileModel(requestData, cert);
                axios({
                    method: 'POST',
                    url: apis.signV2,
                    data: form
                }).then((response: AxiosResponse<any>) => {
                    const signed = response.data;
                    let file = null;
                    if (signed.output && signed.output.edocFile) {
                        file = {
                            rawData: signed.output.edocFile.rawData,
                            fileName: signed.output.edocFile.name
                        };
                        resolve({
                            message: signed.isSuccess ? messages[getLang()].successfullSign : messages[getLang()].failedSign,
                            edocFile: file
                        });
                    }
                    resolve(signed);
                });
            } else {
                console.error('Valid version of signer not detected!');
                reject({
                    edocFile: null,
                    message: messages[getLang()].nonValidVersionDetected
                });
            }
        });
    }
}
