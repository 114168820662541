import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {IUser} from '@/core/auth/store/state';

@Options({
    name: 'general-information-form-component',
    props: {
        serviceType: {
            type: Number,
            default: 1
        },
        citizenshipValue: {
            type: Number, require: true
        },
        organizationLegalFormValue: {
            type: Number, require: true
        },
        legalPersonCarrier: {
            type: [Boolean, null], require: true
        },
        citizenship: {
            type: Array, require: true
        },
        organizationLegalForm: {
            type: Array, require: true
        },
        userData: {
            type: Object, require: true
        },
        stepOneValidation: {
            type: Object, require: true
        },
        userLogin: {
            type: Boolean, default: false
        }
    },
    watch: {
        legalPersonCarrier: {
            handler: 'changeLegalPersonCarrier',
            immediate: false,
            deep: false,
        }
    }

})

export default class GeneralInformationFormComponent extends Vue {
    public organizationLegalFormValue: number | null = null;
    public locale$: any = locale;
    public locale: any = null;
    public store = store;
    public personCarrier = true;
    public legalPersonCarrierValue: boolean | null = true;
    public legalPersonCarrier = false;
    public userData: IUser = {
        name: '',
        surname: '',
        fatherName: '',
        pin: '',
        birthDate: '',
        image: '',
        address: '',
        birthAddress: null,
        document: '',
        documentType: '',
        documentNumber: '',
        citizenshipId: null,
        dateOfIssue: '',
        registeredDepartmentName: '',
        loginType: '',
        certificateNumber: '',
        tin: '',
        structureName: '',
        entity: '',
    };

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.appealRadioService) {
                this.locale = res.appealRadioService;
            }
        });
    }

    public mounted() {
        this.legalPersonCarrierValue = this.legalPersonCarrier;
    }

    public changeLegalPersonCarrier(val: any) {
        this.legalPersonCarrierValue = val;
    }

    public selectCitizenship(info: any) {
        this.store.commit('SET_CITIZENSHIP_VALUE', info);

    }

    public selectOrganizationLegalForm(info: any) {
        this.store.commit('SET_ORGANIZATION_LEGAL_FORM_VALUE', info);

    }

    public selectPersonCarrier(data: any) {
        this.store.commit('SET_PERSON_CARRIER', data);
    }


}