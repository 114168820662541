import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import FileInputComponent from '@/core/shared/file-input/file-input.component.vue';
import DownloadFileComponent from '@/core/shared/download-file/download-file.component.vue';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'cancel-info-component',
    props: {
        userForm: {
            type: Object,
            required: false
        }
    },
    components: {
        FileInputComponent, DownloadFileComponent
    },
    emits:['onNewForm']
})

export default class CancelInfoComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;

    public created() {
        this.locale$.subscribe((res: any) => {
            if (res && res.recordKeeping) {
                this.locale = res.recordKeeping;
            }
        });
    }

    public onChangedInput(value: any, type: string) {
        let payload = {
            type: type,
            value: value
        };
        this.$emit('changedFormData', payload);
        this.$emit('changedFormData', {
            type: 'disabled',
            value: false
        });
    }
}
