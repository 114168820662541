import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import CardInfoComponent from '@/core/shared/card-info/card-info.component.vue';

@Options({
    name: 'status-component',
    props: {
        color: {
            type: String
        },
        title: {
            type: String
        }
    }
})

export default class StatusComponent extends Vue {

}