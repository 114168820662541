import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import TagComponent from '@/core/shared/tag/tag.component.vue';

@Options({
    name: 'multiple-component',
    components: {
        TagComponent
    },
    props: {
        options: {
            type: Array,
            required: false,
        },
        selected: {
            type: [Array, null],
        },
        error: {
            type: Boolean
        },
        placeholder: {
            type: [String, null]
        },
        resultPositionInInput: {
            type: Boolean,
            default: true
        },
        info: {
            type: [String, null],
            default: ''
        },
        allData: {
            type: Boolean,
            default: false
        },
        disabled:{
            type: Boolean,
            default: false
        }
    },
    watch: {
        selected: [
            {
                handler: 'onSelected',
                immediate: false,
                deep: false,
            },
        ]
    },
})
export default class MultipleComponent extends Vue {
    public disabled = false
    public isSelect = false;
    public selectItems = [];
    public click = false;
    public prevClientX = 0;
    public mainWrapperWidth = 0;
    public wrapperWidth = 0;
    public resultPositionInInput = true;

    public $refs!: {
        wrapper: HTMLElement,
        mainWrapper: HTMLElement,
    };

    public startPxClick = 0;
    public scrollPxClick = 0;

    public isOpenSelect() {
        // if (!this.resultPositionInInput) {
        if (!this.disabled) {
            this.isSelect = !this.isSelect;

        }
        // }
    }

    public onSelected(val: any) {
        this.selectItems = val;
    }

    public selectItem() {
        this.$emit('selectItem', this.selectItems);
    }

    public mounted() {
        document.addEventListener('click', this.onClickOutsideSelect);
    }

    public unmounted() {
        document.removeEventListener('click', this.onClickOutsideSelect);
    }

    public onClickOutsideSelect($event: any) {
        if (!this.$el.contains($event.target)) {
            this.isSelect = false;
        }
    }

    public mousedownElement($event: any) {
        this.startPxClick = $event.clientX;
        this.click = true;
    }

    public mouseMoveElement($event: any) {
        if (this.click) {
            this.mainWrapperWidth = this.$refs.mainWrapper.offsetWidth - 10;
            this.wrapperWidth = this.$refs.wrapper.offsetWidth;

            if (this.prevClientX > $event.clientX && (this.wrapperWidth - (Math.abs(Number(this.scrollPxClick)) + this.mainWrapperWidth) >= 0)) {
                this.scrollPxClick = -(Math.abs(Number(this.scrollPxClick)) + 4);
            }
            if (this.prevClientX < $event.clientX && this.scrollPxClick !== 0) {
                this.scrollPxClick = -(Math.abs(Number(this.scrollPxClick)) - 4);
            }

            this.prevClientX = $event.clientX;
        }
    }

    public mouseUpElement() {
        this.click = false;
    }


}
