<style src="./input-value.component.scss" lang="scss"></style>

<template>
  <div class="input-value " :class="[{'input-value--active':value?.length}, `input-value--${size}`]">
    <div class="input-value__title" v-if="value?.length && title?.length">
      {{ title }}
    </div>
    <div class="input-value__value">
      {{ value?.slice(0, sliceCount)}}
    </div>
  </div>
</template>

<script src="./input-value.component.ts"></script>