<style src="./step-description.component.scss" lang="scss"></style>

<template>
  <div class="steps-card">
    <template v-for="(item,index) in stepDescription" :key="index">
      <div class="steps-card__title" v-if="current === index+1">
        <span class="steps-card__number d-inline d-md-none">{{ index + 1 }}</span>
        <span> {{ item.description }}</span>
      </div>
    </template>
  </div>
</template>

<script src="./step-description.component.ts"></script>