<style src="./step-certificate.component.scss" lang="scss"></style>

<template>
  <div class="step-certificate">
    <step-description-component :stepDescription="stepDescription" :current="current"/>
    <progressbar-component :step="step" :current="current" :stepDescription="stepDescription"/>
    <section v-show="current === 1">
      <v-divider class="mt-24 mb-26 d-none d-lg-block"/>
      <one-step-radio-checkbox-component
          inputType="newCertificate"
          :rightData="rightCertificate"
          :leftData="leftCertificate"
          @onChangeInput="(i)=>$emit('onChangeCertificateStatus', i)" class="mb-40 mt-30"/>
      <general-information-form-component
          :serviceType="3"
          :userLogin="userLogin"
          :stepOneValidation="stepOneValidation"
          :userData="userData"
          :legalPersonCarrier="legalPersonCarrier"
          :organizationLegalFormValue="organizationLegalFormValue"
          :citizenshipValue="citizenshipValue"
          :citizenship="citizenship"
          :organizationLegalForm="organizationLegalForm"/>
    </section>
    <section v-show="current === 2">
      <foreign-citizen-form-component
          v-if="citizenshipValue===4"
          @onSelectFileInformation="(i)=>$emit('onSelectFileInformation', i)"
          :errorPersonalInformation="errorPersonalInformation"
          :filesForForCertificate="filesForForCertificate"
          :legalPersonCarrier="legalPersonCarrier"
          :certificateStatus="certificateStatus"
          :certificateCheck="true"
          :citizenshipValue="citizenshipValue"
          :personalInformation="personalInformation"/>
      <personal-information-form-component
          v-else
          :filesForForCertificate="filesForForCertificate"
          @setPersonalInformation="(i) => $emit('setPersonalInformation', i)"
          :certificateStatus="certificateStatus"
          :certificateCheck="true"
          :serviceType="3"
          :req="true"
          :organizationName="organizationName"
          @checkTin="checkTin"
          :userLogin="userLogin"
          :documentSeriesType="documentSeriesType"
          :errorPersonalInformation="errorPersonalInformation"
          :organizationLegalFormValue="organizationLegalFormValue"
          :legalPersonCarrier="legalPersonCarrier"
          :citizenshipValue="citizenshipValue"
          :personalInformation="personalInformation"
      />

    </section>
    <section v-show="current === 3">
      <v-divider class="mt-24 mb-26 d-none d-lg-block"/>
      <third-step-component
          :citizenshipValue="citizenshipValue"
          @onChangeCertificateType="(i)=>$emit('onChangeCertificateType', i)"
          @onSelectFile="(i)=>$emit('onSelectFile', i)"
          :certificateStatus="certificateStatus"
          :certificateType="certificateType"
          :filesForForCertificate="filesForForStep3"/>
    </section>
  </div>
</template>

<script src="./step-certificate.component.ts"></script>