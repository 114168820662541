export class GetCertFilesModel {
    public sxemId: number | null = null;
    public applicantTypeId: number | null = null;
    public isApplicantDifferent: boolean | null = null;
    public appealTypeId: number | null = null;
    public citizenshipId: number | null = null;

    constructor(data: any) {
        this._setSxemId(data);
        this._setApplicantTypeId(data);
        this._setIsApplicantDifferent(data);
        this._setAppealTypeId(data);
        this._setCitizenshipId(data);

    }

    private _setSxemId({sxemId}: any) {
        this.sxemId = sxemId;
    }

    private _setApplicantTypeId({applicantTypeId}: any) {
        this.applicantTypeId = applicantTypeId;
    }

    private _setIsApplicantDifferent({isApplicantDifferent}: any) {
        this.isApplicantDifferent = isApplicantDifferent;
    }

    private _setAppealTypeId({appealTypeId}: any) {
        this.appealTypeId = appealTypeId;
    }

    private _setCitizenshipId({citizenshipId}: any) {
        this.citizenshipId = citizenshipId
    }
}