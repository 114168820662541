<style src="./tern-common.component.scss" lang="scss"></style>

<template>
  <div class="tern-common">
    <div class="row">
      <div class="col-12">
        <v-divider/>
      </div>
      <div class="col-lg-5 col-12 mt-20" v-if="termInfo.serviceType.id===6">
        <div class="form__group">
          <div class="row">
            <div class="col-10">
              <label class="v-label">Radotezliklərin (kanalların) sayı</label>
            </div>
            <div class="col-10">
              <v-select
                  :allData="true"
                  placeholder="Seçin"
                  @select="selectRadioFrequenciesTypesValue"
                  :options="radioFrequenciesTypes"
                  :value="termInfo.radioFrequencyChannels?.id"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-12 col-lg-5 mt-20">
        <div class="form__group">
          <div class="row">
            <div class="col-12">
              <label class="v-label" v-if="
              termInfo.serviceType.id===9
               || termInfo.serviceType.id===10
              || termInfo.serviceType.id===7
              || termInfo.serviceType.id===5
              || termInfo.serviceType.id===8">Radiotezliklərin sayı </label>
              <label class="v-label" v-else>REV-lərin sayı </label>
            </div>
            <div class="col-10">
              <input
                  :class="{'v-input--error':errorTern.frequenciesCountMean}"
                  :disabled="!termInfo.serviceType.id" type="text"
                  @input="changeFrequenciesCount($event.target.value, termInfo.serviceType.id)" class="v-input"
                  v-model="termInfo.frequenciesCount">
            </div>
            <div class="col-2 pl-0">
              <input-value-component title="m2" :value="termInfo.frequenciesCountMean"/>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100"></div>
      <template v-if="termInfo.serviceType.id ===7 ||
      termInfo.serviceType.id ===8 || termInfo.serviceType.id ===9 || termInfo.serviceType.id ===10">
        <div class="col-12 col-lg-5 mt-20">
          <div class="form__group">
            <div class="row">
              <div class="col-12">
                <label class="v-label" v-if="termInfo.serviceType.id ===7 ||termInfo.serviceType.id ===8">
                  {{
                    `Əldə daşınan r-st. sayı (<=5Vt), (PMR 446 olmayan)`
                  }}</label>
                <label class="v-label" v-if="termInfo.serviceType.id ===9">{{
                    `Əldə daşınan r-st. sayı PMR 446`
                  }}</label>

                <label class="v-label" v-if="termInfo.serviceType.id ===10">{{
                    `Əldə daşınan trank sayı (<=5Vt)`
                  }}</label>
              </div>
              <div class="col-10">
                <input @input="changeFrequenciesProperty($event.target.value, 1)"
                       :class="{'v-input--error':errorTern.frequenciesPropertyFirst}" type="text" class="v-input"
                       v-model="termInfo.frequenciesPropertyFirst">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-5 mt-20" v-if="termInfo.serviceType.id !==9">
          <div class="form__group">
            <div class="row">
              <div class="col-12">
                <label class="v-label">Repiter və Stasionar r-st. sayı (>5Vt)</label>
              </div>
              <div class="col-10">
                <input @input="changeFrequenciesProperty($event.target.value,2)" type="text" class="v-input"
                       v-model="termInfo.frequenciesPropertySecond"
                       :class="{'v-input--error':errorTern.frequenciesPropertySecond}">
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="w-100"></div>
      <div class="col-12 mt-40">
        <v-divider/>
      </div>
      <div class="col-lg-5 col-12 mt-20">
        <div class="form__group">
          <div class="row">
            <div class="col-10">
              <label class="v-label">Radiotezlik nominalının zolaq genişliyi, MHs</label>
            </div>
            <div class="col-10">
              <v-select
                  :error="errorTern.bandwidthFrequencyNominal"
                  :disabled="selectBandwidthFrequencyNominal?.length<=1"
                  ref="throughputTypes"
                  :allData="true"
                  placeholder="Seçin"
                  @select="selectBandwidthFrequencyNominalValue"
                  :options="selectBandwidthFrequencyNominal"
                  :value="termInfo.bandwidthFrequencyNominal?.id"
              />
            </div>
            <div class="col-2 pl-0">
              <input-value-component :value="termInfo.bandwidthFrequencyNominal?.mean" title="m3"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-12 mt-20">
        <div class="form__group">
          <div class="row">
            <div class="col-10">
              <label class="v-label">Coğrafi ərazi əmsalı</label>
            </div>
            <div class="col-10">
              <v-select
                  :error="errorTern.geographicalAreas"
                  :disabled="selectGeographicalAreas?.length<=1"
                  ref="throughputTypes"
                  :allData="true"
                  placeholder="Seçin"
                  @select="selectGeographicalAreasValue"
                  :options="selectGeographicalAreas"
                  :value="termInfo.geographicalAreas?.id"
              />
            </div>
            <div class="col-2 pl-0">
              <input-value-component :value="termInfo.geographicalAreas?.mean" title="m4"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-40">
        <v-divider/>
      </div>
      <div class="col-lg-5 col-12 mt-20">
        <div class="form__group">
          <div class="row">
            <div class="col-10">
              <label class="v-label">REV-in şüalandırdığı ərazinin sahəsini nəzərə alan əmsal</label>
            </div>
            <div class="col-10">
              <v-select
                  :error="errorTern.SEMTechnologyFactor"
                  :disabled="selectSEMTechnologyFactor?.length<=1"
                  ref="throughputTypes"
                  :allData="true"
                  placeholder="Seçin"
                  @select="selectSEMTechnologyFactorValue"
                  :options="selectSEMTechnologyFactor"
                  :value="termInfo.SEMTechnologyFactor?.id"
              />
            </div>
            <div class="col-2 pl-0">
              <input-value-component :value="termInfo.SEMTechnologyFactor?.mean" title="m5"/>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-lg-5 col-12 mt-40" v-if="
      termInfo.serviceType.id ===1
      || termInfo.serviceType.id ===2
      || termInfo.serviceType.id ===3
      || termInfo.serviceType.id ===4
      || termInfo.serviceType.id ===7
       || termInfo.serviceType.id ===8
        || termInfo.serviceType.id ===9
      || termInfo.serviceType.id ===24">
        <div class="form__group">
          <div class="row">
            <div class="col-10">
              <label class="v-label" v-if="termInfo.serviceType.id ===1">
                Radio yayımı (FM) ötürücüsünün şüalandırdığı ərazinin sahəsi (xidmət sahəsi) km²</label>
              <label class="v-label" v-if="termInfo.serviceType.id ===2">
                Radio yayımı (T-DAB) ötürücüsünün şüalandırdığı ərazinin sahəsi (xidmət sahəsi) km²</label>
              <label class="v-label" v-if="termInfo.serviceType.id ===3">
                Radio yayımı (OD, QD) ötürücüsünün gücü (Vt)</label>
              <label class="v-label" v-if="termInfo.serviceType.id ===4">
                TV yayımı (DVB-T) ötürücüsünün şüalandırdığı ərazinin sahəsi (xidmət sahəsi) km²</label>
              <label class="v-label"
                     v-if="termInfo.serviceType.id ===5 || termInfo.serviceType.id ===6 || termInfo.serviceType.id ===7">
                Radiostansiya və repiterlərin toplam gücü (Vt)</label>
              <label class="v-label" v-if="termInfo.serviceType.id ===8">
                Radiostansiyaların və repterlərin toplam gücü</label>
              <label class="v-label" v-if="termInfo.serviceType.id ===9">
                Radiostansiyaların toplam gücü</label>
              <label class="v-label" v-if="termInfo.serviceType.id ===24">
                Avadanlıqların gücü (Vt)</label>
            </div>
            <div class="col-10">
              <input
                  :class="{'v-input--error':errorTern.SEMTechnologyFactor}"
                  type="text" class="v-input"
                  @input="changeSEMTechnologyFactor($event.target.value, termInfo.serviceType.id)"
                  v-model="termInfo.SEMTechnologyFactorValue">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-20">
        <v-divider/>
      </div>
      <div class="col-lg-5 col-12 mt-40">
        <div class="form__group">
          <div class="row">
            <div class="col-10">
              <label class="v-label">Tezlik zolağının əhəmiyyətlilik əmsalı</label>
            </div>
            <div class="col-10">
              <v-select
                  :error="errorTern.bandSignificances"
                  :disabled="selectBandSignificances?.length<=1"
                  ref="throughputTypes"
                  :allData="true"
                  placeholder="Seçin"
                  @select="selectBandSignificancesValue"
                  :options="selectBandSignificances"
                  :value="termInfo.bandSignificances?.id"
              />
            </div>
            <div class="col-2 pl-0">
              <input-value-component :value="termInfo.bandSignificances?.mean" title="m6"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-12 mt-20">
        <div class="form__group">
          <div class="row">
            <div class="col-10">
              <label class="v-label">Radiotezliklərə köklənmiş REV-lərin texnologiyasını nəzərə alan əmsal</label>
            </div>
            <div class="col-10">
              <v-select
                  :error="errorTern.coefficientSEMTechnology"
                  :disabled="selectCoefficientSEMTechnology?.length<=1"
                  ref="throughputTypes"
                  :allData="true"
                  placeholder="Seçin"
                  @select="selectCoefficientSEMTechnologyValue"
                  :options="selectCoefficientSEMTechnology"
                  :value="termInfo.coefficientSEMTechnology?.id"
              />
            </div>
            <div class="col-2 pl-0">
              <input-value-component :value="termInfo.coefficientSEMTechnology?.mean" title="m7"/>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-20">
        <label class="v-label"> Tarix aralığı</label>
      </div>
      <div class="col-lg-5 col-12 ">
        <div class="form__group">
          <div class="row">
            <div class="col-10">
              <v-select
                  :error="errorTern.date"
                  ref="date"
                  :allData="true"
                  placeholder="Seçin"
                  @select="selectDateTern"
                  :options="dateTern"
                  :value="termInfo?.date?.id"
              />
            </div>
            <div class="col-2 pl-0">
              <input-value-component
                  v-if="termInfo?.date?.id!==13"
                  title="M8"
                  :value="termInfo?.date?.mean"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-12 " v-if="termInfo?.date?.id===13">
        <div class="row">
          <div class="col-5">
            <div class="form__group" :class="{'datepicker--error':errorTern.dateStart}">
              <datepicker v-model="startDate" :locale="dataLang"/>
            </div>
          </div>
          <div class="col-5 ">
            <div class="form__group" :class="{'datepicker--error':errorTern.dateEnd}">
              <datepicker :lowerLimit="startDate" v-model="endDate" :locale="dataLang"/>
            </div>
          </div>
          <div class="col-2 pl-0">
            <input-value-component
                title="M8"
                :value="termInfo?.date?.mean"/>
          </div>
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-lg-5 col-12 mt-20"
           v-if="termInfo.serviceType.id !==1
           && termInfo.serviceType.id !==3
           && termInfo.serviceType.id !==5
           && termInfo.serviceType.id !==25
           && termInfo.serviceType.id !==11
           && termInfo.serviceType.id !==12
           && termInfo.serviceType.id !==13
           && termInfo.serviceType.id !==14
           && termInfo.serviceType.id !==15
           && termInfo.serviceType.id !==16
           && termInfo.serviceType.id !==17"
      >
        <div class="form__group">
          <div class="row">
            <div class="col-10">
              <label class="v-label">Uyğunlaşdırma əmsalı</label>
            </div>
            <div class="col-10">
              <v-select
                  :error="errorTern.adjustmentFactor"
                  :disabled="selectAdjustmentFactor?.length<=1"
                  ref="throughputTypes"
                  :allData="true"
                  placeholder="Seçin"
                  @select="selectAdjustmentFactorValue"
                  :options="selectAdjustmentFactor"
                  :value="termInfo.adjustmentFactor?.id"
              />
            </div>
            <div class="col-2 pl-0">
              <input-value-component :value="termInfo.adjustmentFactor?.mean" title="R"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./tern-common.component.ts"></script>
