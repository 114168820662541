<style src="./cancel-info.component.scss" lang="scss"></style>

<template>
  <div class="cancel-info">
    <div class="row">
      <div class="col-12 col-lg-4 mb-40" v-if="userForm.appealStatusId===4 || userForm.appealStatusId===5">
        <label class="v-label">Ləğv etmə ilə bağlı sənəd</label>
        <download-file-component :files="[userForm.cancelFileAnswer]"/>
      </div>
      <div class="col-12">
        <v-card>
          <div class="row">
            <div class="col-12">
              <div class="cancel-info__header">{{ locale.informationLegalEntity }}</div>
              <v-divider class="my-30"/>
            </div>
            <div class="col-12 col-lg-4 mb-30">
              <div class="cancel-info__title">{{ locale.organizationName }}</div>
              <div class="cancel-info__descr">{{ userForm.organizationName }}</div>
            </div>
            <div class="col-12 col-lg-4 mb-30">
              <div class="cancel-info__title">{{ locale.tin }}</div>
              <div class="cancel-info__descr">{{ userForm.tin }}</div>
            </div>
            <div class="w-100"></div>
            <div class="col-12 col-lg-4 mb-30">
              <div class="cancel-info__title">{{ locale.legalAddress }}</div>
              <div class="cancel-info__descr">{{ userForm.juridicalAddress }}</div>
            </div>
            <div class="col-12 col-lg-4 mb-30">
              <div class="cancel-info__title">{{ locale.activityAddress }}</div>
              <div class="cancel-info__descr">{{ userForm.activityAddress }}</div>
            </div>
            <div class="w-100"></div>
            <div class="col-12 col-lg-4 mb-30">
              <div class="cancel-info__title">{{ locale.nameLegalEntity }}</div>
              <div class="cancel-info__descr">{{ userForm.juridicalPersonFullName }}</div>
            </div>
            <div class="col-12 col-lg-4 mb-30">
              <div class="cancel-info__title">{{ locale.position }}</div>
              <div class="cancel-info__descr">{{ userForm.position }}</div>
            </div>
          </div>
        </v-card>
      </div>
      <div class="col-12 mt-50">
        <div class="row">
          <div class="col-12">
            <div class="cancel-info__header mb-40">{{ locale.applicationInformation }}</div>
          </div>
          <div class="col-12 col-lg-4 mb-30">
            <div class="cancel-info__title">{{ locale.internetAddress }}</div>
            <div class="cancel-info__descr">{{ userForm.applicantInternetAddress }}</div>
          </div>
          <div class="w-100"></div>
          <div class="col-12 col-lg-4 mb-30">
            <div class="cancel-info__title">{{ locale.typeActivitie }}</div>
            <div class="cancel-info__descr">{{ userForm.applicantTypeActivitiesText }}</div>
          </div>
          <div class="w-100"></div>
          <div class="col-12 col-lg-4 mb-30">
            <div class="cancel-info__title">{{ locale.typesTelecommunicationsService }}</div>
            <div class="cancel-info__descr">{{ userForm.applicantTypesTelecommunicationsServiceText }}</div>
          </div>
          <div class="w-100"></div>
          <div class="col-12 col-lg-4 mb-30">
            <div class="cancel-info__title">{{ locale.scopeActivity }}</div>
            <div class="cancel-info__descr">{{ userForm.applicantScopeActivityText }}</div>
          </div>
          <div class="w-100"></div>
          <div class="col-12 col-lg-4 mb-30">
            <div class="cancel-info__title">{{ locale.interval4 }}</div>
            <div class="cancel-info__descr">{{ userForm.iPvFour }}</div>
          </div>
          <div class="col-12 col-lg-4 mb-30">
            <div class="cancel-info__title">{{ locale.interval6 }}</div>
            <div class="cancel-info__descr">{{ userForm.iPvSix }}</div>
          </div>
          <div class="w-100"></div>
          <div class="col-12 col-lg-4 mb-30">
            <div class="cancel-info__title">{{ locale.date }}</div>
            <div class="cancel-info__descr">{{ userForm.serviceDate.toISOString().split('T')[0] }}</div>
          </div>
          <div class="col-12 col-lg-4 mb-30">
            <div class="cancel-info__title">{{ locale.autonomousSystem }}</div>
            <div class="cancel-info__descr">{{ userForm.asNumber }}</div>
          </div>
          <div class="w-100"></div>
          <div class="col-12 col-lg-4 mb-30">
            <div class="cancel-info__title">{{ locale.applicantPhoneNumber }}</div>
            <div class="cancel-info__descr">{{ userForm.applicantPhoneNumber }}</div>
          </div>
          <div class="col-12 col-lg-4 mb-30">
            <div class="cancel-info__title">{{ locale.applicantFaxNumber }}</div>
            <div class="cancel-info__descr">{{ userForm.applicantFaxNumber }}</div>
          </div>
          <div class="w-100"></div>
          <div class="col-12 col-lg-4 mb-30">
            <div class="cancel-info__title">{{ locale.applicantEmailNumber }}</div>
            <div class="cancel-info__descr">{{ userForm.applicantEmail }}</div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-40">
        <v-divider class="my-30"/>
        <div class="cancel-info__header">
          Sənədlər
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <label class="v-label">İmzalanmış sənədlər toplusu</label>
        <download-file-component :files="userForm.signFile"/>
      </div>
      <div class="col-12 text-center">
        <v-divider class="my-30"     v-if="userForm.appealStatusId===5 || userForm.appealStatusId===3"/>
        <v-btn
            v-if="userForm.appealStatusId===5 "
            class=" py-12 px-55"
            color="dark"
            @click.prevent="onChangedInput(true,'new')"
        >
          Yeni müraciət
        </v-btn>
        <v-btn

            v-if="userForm.appealStatusId===2"
            @click.prevent="$emit('onCancel')"
            class=" py-12 px-55 mr-12"
            color="dark-red"
        >
          Ləğv et
        </v-btn>
        <v-btn
            v-if="userForm.appealStatusId===2"
            class=" py-12 px-55"
            color="dark"
            @click.prevent="onChangedInput(true,'edit')"
        >
          Düzəliş et
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script src="./cancel-info.component.ts"></script>
