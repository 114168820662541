import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import StepDescriptionComponent from '@/core/shared/step-description/step-description.component.vue';
import ProgressbarComponent from '@/core/shared/progressbar/progressbar.component.vue';
import GeneralInformationFormComponent
    from '@/pages/appeal-radio-service/components/general-information-form/general-information-form.component.vue';
import PersonalInformationFormComponent
    from '@/pages/appeal-radio-service/components/personal-information-form/personal-information-form.component.vue';
import RadioInformationFormComponent
    from '@/pages/appeal-radio-service/components/radio-Information-form/radio-Information-form.component.vue';
import OneStepRadioCheckboxComponent
    from '@/pages/issuing-certificate-conformity-service/components/one-step-radio-checkbox/one-step-radio-checkbox.component.vue';
import ApplicantFormComponent
    from '@/pages/issuing-certificate-conformity-service/components/applicant-form/applicant-form.component.vue';
import ThirdStepComponent
    from '@/pages/issuing-certificate-conformity-service/components/third-step/third-step.component.vue';
import ForeignCitizenFormComponent
    from '@/pages/issuing-certificate-conformity-service/components/foreign-citizen-form/foreign-citizen-form.component.vue';

@Options({
    name: 'step-certificate-component',
    components: {
        StepDescriptionComponent,
        ProgressbarComponent,
        GeneralInformationFormComponent,
        PersonalInformationFormComponent,
        RadioInformationFormComponent,
        OneStepRadioCheckboxComponent,
        ApplicantFormComponent,
        ThirdStepComponent,
        ForeignCitizenFormComponent
    },
    emits: ['onChangeInput', 'checkTin', 'onChangeCertificateStatus', 'setPersonalInformation','onSelectFile'],
    props: {
        filesForForStep3: {
            type:Array,
            default: []
        },
        certificateType:{
            type: Number, require: true
        },
        filesForForCertificate: {
            type: [Array, null], require: true
        },
        certificateStatus: {
            type: Number, require: true
        },
        applicantForm: {
            type: Object, require: true
        },
        userLogin: {
            type: Boolean,
            default: false
        },
        userData: {
            type: Object, require: true
        },
        documentSeriesType: {
            type: Array, require: true
        },
        legalPersonCarrier: {
            type: [Boolean, null], require: true
        },
        organizationLegalForm: {
            type: Array, require: true
        },
        citizenship: {
            type: Array, require: true
        },
        stepDescription: {
            type: Array,
            require: true
        },
        current: {
            type: Number, require: true
        },
        step: {
            type: Number, require: true
        },
        citizenshipValue: {
            type: Number, require: true
        },
        organizationLegalFormValue: {
            type: Number, require: true
        },
        stepOneValidation: {
            type: Object,
            require: true
        },
        personalInformation: {
            type: Object,
            require: true
        },
        errorPersonalInformation: {
            type: Object,
            require: true
        },
        errorRadioInformation: {
            type: Object,
            require: true
        },
        radioInformation: {
            type: Object,
            require: true
        },

        interferenceTechnologyTypes: {
            type: Array,
            require: true
        },
        durations: {
            type: Array,
            require: true
        },
        interferenceTypes: {
            type: Array,
            require: true
        },
        frequencyUnits: {
            type: Array,
            require: true
        },
        organizationName: {
            type: String, require: false
        },
    }
})

export default class StepCertificateComponent extends Vue {
    public checkTin() {
        this.$emit('checkTin');
    }

    public leftCertificate = {
        id: 'newCertificate',
        label: 'Yeni sertifikat',
        value: 1
    };
    public rightCertificate = {
        id: 'extensionTimeCertificate',
        label: 'Müddətin uzadılması',
        value: 2
    };


}