import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';

@Options({
    name: 'tag-component',
    props: {
        color: {
            type: String,
            default: 'light'
        },
        itemId: {
            type: [String, Number],
            require: true
        },
        deleteCheck: {
            type: Boolean,
            default: true
        }
    }
})

export default class TagComponent extends Vue {
    public itemId = null;

    public onDeleteItem() {
        this.$emit('deleteItem', this.itemId);
    }

}
