import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import StepRegistrationRadioComponent
    from '@/pages/registration-radio-electronic-devices-service/components/step-registration-radio/step-registration-radio.component.vue';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {container} from 'tsyringe';
import {IUserData} from '@/pages/appeal-radio-service/types/user-data';
import {AppealRadioServiceSandbox} from '@/pages/appeal-radio-service/sandbox/appeal-radio-service.sandbox';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import {
    RegistrationRadioElectronicDevicesServiceSandbox
} from '@/pages/registration-radio-electronic-devices-service/sandbox/registration-radio-electronic-devices-service.sandbox';
import {Validation} from '@/core/mixin/validation';
import {PostApplicantModel} from '@/pages/appeal-radio-service/models/post-applicant.model';
import {load} from 'recaptcha-v3';
import {
    PostCreateRedAppeal
} from '@/pages/registration-radio-electronic-devices-service/models/post-create-red-appeal.model';
import {CreateFormData} from '@/core/mixin/mixin';
import {isArray} from 'rxjs/internal-compatibility';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import {Router} from '@/core/mixin/router';
import {IInputPayload} from '@/types/input-payload';

@Options({
    name: 'registration-radio-electronic-devices-service-component',
    components: {
        StepRegistrationRadioComponent,
        CustomerInfoComponent,
        ServiceNameComponent
    },
    watch: {
        citizenshipValue: {
            handler: 'changeCitizenshipValue',
            immediate: false,
            deep: false,
        },
    }
})

export default class RegistrationRadioElectronicDevicesServiceComponent extends mixins(Validation, CreateFormData, Router) {
    public siteKey = '6LdZg8IfAAAAAEzXF_7RQY0AzNjQ4zhPPR-5Tf5P';
    public sandboxRadio: AppealRadioServiceSandbox = container.resolve(AppealRadioServiceSandbox);
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);
    public checkFile = true;
    public sandbox: RegistrationRadioElectronicDevicesServiceSandbox = container.resolve(RegistrationRadioElectronicDevicesServiceSandbox);

    public store = store;
    public step: number = 3;
    public current: number = 1;
    public locale$: any = locale;
    public locale: any = null;
    public localeService: any = null;
    public requestCitizenship = false;
    public organizationNameValue = '';
    public oldTin = '';


    public $refs!: {
        modal: {
            openModal: () => void;
            closeModal: () => void
        },
    };
    public stepOneValidation = {
        citizenship: false,
        organizationLegalForm: false
    };

    public errorPersonalInformation = {
        documentNumber: false,
        documentTypeNumber: false,
        fin: false,
        phone: false,
        email: false,
        tin: false,
        organization: false,
        position: false,
        registration: false,
    };
    public errorFiles: object[] = [];
    public errorRedAppealTechnologyId = false;

    get getPostCreateRedAppeal() {
        return this.store.getters._getPostCreateRedAppeal;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }


    get fileTypesKeyValues() {
        if (this.store.getters._getFileTypesKeyValues?.length && this.checkFile) {
            this.store.commit('SET_ARRAY_RED_FILES', this.store.getters._getFileTypesKeyValues);
            this.createdError(this.store.getters._getFileTypesKeyValues);
            this.checkFile = false;
        }
        return this.store.getters._getFileTypesKeyValues;
    }

    get appealTechnologyKeyValues() {
        return this.store.getters._getAppealTechnologyKeyValues;
    }

    get applicantId() {
        return this.store.getters._getApplicantId;
    }

    get personalInformation() {
        return this.store.getters._getAppealPersonalInformation;
    }

    get user() {
        if (this.store.getters._getUserFullData?.pin?.length) {
            if (this.store.getters._getUserFullData?.pin?.length === 7) {
                this.store.commit('SET_CITIZENSHIP_VALUE', 1);
            } else {
                this.store.commit('SET_CITIZENSHIP_VALUE', 2);
            }
        }
        return this.store.getters._getUserFullData;
    }

    get userData(): IUserData {
        return this.store.getters._getUserDataRadio;
    }

    get citizenship() {
        return this.store.getters._getCitizenship;
    }

    get organizationLegalForm() {
        return this.store.getters._getOrganPersonType;
    }

    get legalPersonCarrier() {
        return this.store.getters._getLegalPersonCarrier;
    }

    get documentSeriesType() {
        return this.store.getters._getDocumentSeriesType;
    }

    get citizenshipValue() {
        return this.store.getters._getCitizenshipValue;
    }

    get organizationLegalFormValue() {
        return this.store.getters._getOrganizationLegalFormValue;
    }

    get stepDescription() {
        return [
            {
                number: 1,
                description: this.localeService?.step1,
            },
            {
                number: 2,
                description: this.localeService?.step2,
            },
            {
                number: 3,
                description: 'Radioelektron vasitələrlə bağlı parametrlər',
            },
        ];
    }

    get organizationName() {
        return this.store.getters._getRadioOrganizationName;
    }

    public mounted(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.elements) {
                this.locale = res.elements;
                this.localeService = res.permissionMovementService;
            }
        });
        this.sandbox.getRadioRegistrationCitizenship();
        this.sandbox.getRadioRegistrationOrganPersonType();
        this.sandbox.getFileTypesKeyValues();
        this.sandbox.getAppealTechnologyKeyValues();
        this.getServiceData();
    }

    public unmounted() {
        this.store.commit('SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE');
        this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
        this.store.commit('SET_RESET_LEGAL_PERSONAL');
    }


    public setPersonalInformation(info: IInputPayload) {
        this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', info);
    }

    public createdError(data: any) {
        data.forEach((item: any) => {
            this.errorFiles.push({
                id: item.id,
                error: false
            });
        });
    }

    public changeFileTypesKeyValues(val: any, oldVal: any) {
        if (val !== oldVal && this.checkFile) {
            this.store.commit('SET_ARRAY_RED_FILES', val);
            this.checkFile = false;
        }
    }

    public checkGeneralInfoValidation() {
        this.citizenshipValue ?
            this.stepOneValidation.citizenship = false : this.stepOneValidation.citizenship = true;
        this.organizationLegalFormValue ?
            this.stepOneValidation.organizationLegalForm = false : this.stepOneValidation.organizationLegalForm = true;
    }


    public checkValidationFirstStep() {
        this.checkGeneralInfoValidation();
        if (!this.checkValidationError(this.stepOneValidation)) {
            if (!this.user?.pin?.length && this.requestCitizenship) {
                this.sandbox.getRadioRegistrationDocumentSeriesType(this.citizenshipValue);
            }
            this.requestCitizenship = false;
            this.current = 2;
        }
    }

    public checkTin() {
        load((this.siteKey), {}).then((re) => {
            re.execute('sendFeedback').then((token) => {
                this.sandboxRadio.getRadioOrganizationName({
                    SecretKey: token,
                    applicantTypeId: this.organizationLegalFormValue,
                    tin: this.personalInformation.tin,
                });
            });
        });
    }

    public incrementCurrent() {
        switch (this.current) {
            case 1:
                this.checkValidationFirstStep();
                break;
            case 2:
                this.checkValidationSecondStep();
                break;
            case 3:
                break;
        }
    }

    public decrementCurrent(): void {
        switch (this.current) {
            case 1:
                break;
            case 2:
                this.current = --this.current;
                break;
            case 3:
                this.current = --this.current;
                break;
        }
    }

    public resetFileValidation() {
        this.selectTechnology();
    }

    public resetTinValidation() {
        this.store.commit('SET_RADIO_ORGANIZATION_NAME', {
            fullName: '',
        });
        let payload = {
            type: 'tin',
            value: ''
        };
        this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public changeCitizenshipValue(val: any, oldVal: any) {
        if (val !== oldVal) {
            this.requestCitizenship = true;
        }
    }

    public sendData() {
        let data = new PostCreateRedAppeal(this.getPostCreateRedAppeal, this.applicantId);
        let formData = new FormData();
        formData.append('RedAppealTechnologyId', String(data?.redAppealTechnologyId));
        formData.append('ApplicantId', String(data?.applicantId));
        let check = data.redAppealFileRequest?.some((item) => {
            return item.file !== null;
        });
        if (check) {
            let number = 0;
            data.redAppealFileRequest?.forEach((item, index) => {
                if (item.file !== null) {
                    formData.append(`RedAppealFileRequest[${number}].RedAppealFileTypeId`, String(item.redAppealFileTypeId));
                    if (isArray(item.file)) {
                        item.file.forEach(((element: File) => {
                            formData.append(`RedAppealFileRequest[${number}].requestFile`, element);
                        }));
                    } else {
                        if (item.file) {
                            formData.append(`RedAppealFileRequest[${number}].requestFile`, item.file);
                        }
                    }
                    number = number + 1;
                }
            });
        } else {
            formData.append(`RedAppealFileRequest`, 'null');
        }

        this.sandbox.postRedCreateAppeal(formData).then((res: any) => {
            if (res.status === 200) {
                this.openModal();
            }
        });
    }

    public finallyStep() {
        this.validation();
        let checkFiles = this.errorFiles.some((item: any) => {
            return item.error === true;
        });
        if (!this.errorRedAppealTechnologyId && !checkFiles) {
            this.sendData();
        }
    }

    // public validation() {
    //     let data = new PostCreateRedAppeal(this.getPostCreateRedAppeal, this.applicantId);
    //     data.redAppealTechnologyId ? this.errorRedAppealTechnologyId = false : this.errorRedAppealTechnologyId = true;
    //     if (data.redAppealTechnologyId !== 0) {
    //         data.redAppealFileRequest?.forEach((itemData: any) => {
    //             this.errorFiles.forEach((itemError: any) => {
    //                 if (itemData.redAppealFileTypeId === itemError.id) {
    //                     if (data.redAppealTechnologyId === 13 || data.redAppealTechnologyId === 15 || data.redAppealTechnologyId === 18) {
    //                         if (itemData.redAppealFileTypeId === 18
    //                             || itemData.redAppealFileTypeId === 14
    //                             || itemData.redAppealFileTypeId === 13
    //                             || itemData.redAppealFileTypeId === 15
    //                             || itemData.redAppealFileTypeId === 17
    //                         ) {
    //                             if (itemData.file !== null) {
    //                                 itemError.error = false;
    //                             } else {
    //                                 itemError.error = true;
    //                             }
    //                         }
    //                     }
    //                     if (data.redAppealTechnologyId === 22) {
    //                         if (itemData.redAppealFileTypeId === 18
    //                             || itemData.redAppealFileTypeId === 14
    //                             || itemData.redAppealFileTypeId === 13
    //                         ) {
    //                             console.log(itemData);
    //                             if (itemData.file !== null) {
    //                                 itemError.error = false;
    //                             } else {
    //                                 itemError.error = true;
    //                             }
    //                         }
    //                     }
    //                     if (data.redAppealTechnologyId === 16 || data.redAppealTechnologyId === 17) {
    //                         if (itemData.redAppealFileTypeId === 18
    //                             || itemData.redAppealFileTypeId === 14
    //                             || itemData.redAppealFileTypeId === 13
    //                             || itemData.redAppealFileTypeId === 16
    //                             || itemData.redAppealFileTypeId === 17
    //                         ) {
    //                             if (itemData.file !== null) {
    //                                 itemError.error = false;
    //                             } else {
    //                                 itemError.error = true;
    //                             }
    //                         }
    //                     }
    //                     if (data.redAppealTechnologyId === 14 || data.redAppealTechnologyId === 19) {
    //                         if (itemData.redAppealFileTypeId === 18
    //                             || itemData.redAppealFileTypeId === 14
    //                             || itemData.redAppealFileTypeId === 13
    //                             || itemData.redAppealFileTypeId === 17
    //                         ) {
    //                             if (itemData.file !== null) {
    //                                 itemError.error = false;
    //                             } else {
    //                                 itemError.error = true;
    //                             }
    //                         }
    //                     }
    //                     if (data.redAppealTechnologyId === 20 || data.redAppealTechnologyId === 21) {
    //                         if (itemData.redAppealFileTypeId === 18
    //                             || itemData.redAppealFileTypeId === 14
    //                             || itemData.redAppealFileTypeId === 13
    //                         ) {
    //                             if (itemData.file !== null) {
    //                                 itemError.error = false;
    //                             } else {
    //                                 itemError.error = true;
    //                             }
    //                         }
    //                     }
    //                     if (this.organizationLegalFormValue === 4 && itemData.redAppealFileTypeId === 20) {
    //                         if (itemData.file !== null) {
    //                             itemError.error = false;
    //                         } else {
    //                             itemError.error = true;
    //                         }
    //                     }
    //                 }
    //
    //             });
    //         });
    //     } else {
    //         data.redAppealFileRequest?.forEach((itemData: any) => {
    //             this.errorFiles.forEach((itemError: any) => {
    //                 if (itemData.redAppealFileTypeId === itemError.id) {
    //                     itemError.error = false;
    //                 }
    //             });
    //         });
    //     }
    // }

    public validation() {
        let data = new PostCreateRedAppeal(this.getPostCreateRedAppeal, this.applicantId);
        data.redAppealTechnologyId ? this.errorRedAppealTechnologyId = false : this.errorRedAppealTechnologyId = true;
        if (!this.personalInformation.registration) {
            if (data.redAppealTechnologyId !== 0) {
                data.redAppealFileRequest?.forEach((itemData: any) => {
                    this.errorFiles.forEach((itemError: any) => {
                        if (itemData.redAppealFileTypeId === itemError.id) {
                            if (data.redAppealTechnologyId === 13 || data.redAppealTechnologyId === 15 || data.redAppealTechnologyId === 18) {
                                if (itemData.redAppealFileTypeId === 18
                                    || itemData.redAppealFileTypeId === 14
                                    || itemData.redAppealFileTypeId === 13
                                    || itemData.redAppealFileTypeId === 15
                                    || itemData.redAppealFileTypeId === 17
                                ) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }
                            if (data.redAppealTechnologyId === 22) {
                                if (itemData.redAppealFileTypeId === 18
                                    || itemData.redAppealFileTypeId === 14
                                    || itemData.redAppealFileTypeId === 13
                                    || itemData.redAppealFileTypeId === 15
                                ) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }
                            if (data.redAppealTechnologyId === 16 || data.redAppealTechnologyId === 17) {
                                if (itemData.redAppealFileTypeId === 18
                                    || itemData.redAppealFileTypeId === 14
                                    || itemData.redAppealFileTypeId === 13
                                    || itemData.redAppealFileTypeId === 16
                                    || itemData.redAppealFileTypeId === 17
                                ) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }
                            if (data.redAppealTechnologyId === 14) {
                                if (itemData.redAppealFileTypeId === 18 || itemData.redAppealFileTypeId === 13) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }
                            if (data.redAppealTechnologyId === 19) {
                                if (itemData.redAppealFileTypeId === 18
                                    || itemData.redAppealFileTypeId === 14
                                    || itemData.redAppealFileTypeId === 13
                                    || itemData.redAppealFileTypeId === 17
                                ) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }
                            if (data.redAppealTechnologyId === 20 || data.redAppealTechnologyId === 21) {
                                if (itemData.redAppealFileTypeId === 18
                                    || itemData.redAppealFileTypeId === 14
                                    || itemData.redAppealFileTypeId === 13
                                ) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }
                            if (this.organizationLegalFormValue === 4 && itemData.redAppealFileTypeId === 20) {
                                if (itemData.file !== null) {
                                    itemError.error = false;
                                } else {
                                    itemError.error = true;
                                }
                            }
                        }
                    });
                });
            } else {
                data.redAppealFileRequest?.forEach((itemData: any) => {
                    this.errorFiles.forEach((itemError: any) => {
                        if (itemData.redAppealFileTypeId === itemError.id) {
                            itemError.error = false;
                        }
                    });
                });
            }
        } else {
            if (data.redAppealTechnologyId !== 0) {
                data.redAppealFileRequest?.forEach((itemData: any) => {
                    this.errorFiles.forEach((itemError: any) => {
                        if (itemData.redAppealFileTypeId === itemError.id) {
                            if (data.redAppealTechnologyId === 13 || data.redAppealTechnologyId === 15 || data.redAppealTechnologyId === 18) {
                                if (itemData.redAppealFileTypeId === 14 || itemData.redAppealFileTypeId === 13) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }
                            if (data.redAppealTechnologyId === 22) {
                                if (itemData.redAppealFileTypeId === 18
                                    || itemData.redAppealFileTypeId === 14
                                    || itemData.redAppealFileTypeId === 13
                                    || itemData.redAppealFileTypeId === 15
                                ) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }
                            if (data.redAppealTechnologyId === 16 || data.redAppealTechnologyId === 17) {
                                if (itemData.redAppealFileTypeId === 14
                                    || itemData.redAppealFileTypeId === 13
                                    || itemData.redAppealFileTypeId === 16 || itemData.redAppealFileTypeId === 20) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }
                            if (data.redAppealTechnologyId === 14) {
                                if (itemData.redAppealFileTypeId === 13) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }

                            if (data.redAppealTechnologyId === 19) {
                                if (itemData.redAppealFileTypeId === 14 || itemData.redAppealFileTypeId === 13) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }
                            if (data.redAppealTechnologyId === 20 || data.redAppealTechnologyId === 21) {
                                if (itemData.redAppealFileTypeId === 14 || itemData.redAppealFileTypeId === 13
                                ) {
                                    if (itemData.file !== null) {
                                        itemError.error = false;
                                    } else {
                                        itemError.error = true;
                                    }
                                }
                            }
                        }
                    });
                });
            } else {
                data.redAppealFileRequest?.forEach((itemData: any) => {
                    this.errorFiles.forEach((itemError: any) => {
                        if (itemData.redAppealFileTypeId === itemError.id) {
                            itemError.error = false;
                        }
                    });
                });
            }
        }

    }

    public openModal() {
        this.$refs.modal.openModal();
    }

    public selectTechnology() {
        this.errorRedAppealTechnologyId = false;
        this.errorFiles.forEach((item: any) => {
            item.error = false;
        });
    }


    public closeModal() {
        this.store.commit('SET_DATA_RESET');
        this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
        this.$refs.modal.closeModal();
        this.current = 1;
        this.$router.push({name: 'home'});
    }

    public checkPersonalInformationInfoValidation() {
        if (!(this.user?.pin?.length > 0)) {
            this.checkValidationData(this.errorPersonalInformation
                , this.validationDocumentNumber(this.personalInformation.documentTypeNumber?.value, this.personalInformation.documentNumber)
                , 'documentNumber');
            this.checkValidationData(this.errorPersonalInformation,
                this.personalInformation.documentTypeNumber?.id, 'documentTypeNumber');
            this.checkValidationData(this.errorPersonalInformation
                , this.validationFin(this.personalInformation.documentTypeNumber?.value, this.personalInformation.fin)
                , 'fin');
            this.checkValidationData(this.errorPersonalInformation
                , this.validationPhone(this.personalInformation.phone)
                , 'phone'
            );
        }
        this.checkValidationData(this.errorPersonalInformation
            , this.validationEmail(this.personalInformation.email)
            , 'email'
        );
        if (this.organizationLegalFormValue === 3 || this.organizationLegalFormValue === 4) {
            this.checkValidationData(this.errorPersonalInformation
                , this.validationVoen(this.personalInformation.tin)
                , 'voen'
            );

        }
    }

    public checkValidationSecondStep() {
        this.checkPersonalInformationInfoValidation();
        if (!this.checkValidationError(this.errorPersonalInformation)) {
            this.sandbox.postApplicantRegistration(new PostApplicantModel(this.personalInformation, this.organizationLegalFormValue, this.legalPersonCarrier, this.citizenshipValue)).then((res: any) => {
                this.store.commit('SET_APPLICANT', res);
                if (this.applicantId) {
                    this.current = 3;
                }
            });

        }
    }

}