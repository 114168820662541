<style src="./mobile-communication.component.scss" lang="scss"></style>

<template>
  <div class="mobile-communication">
    <form class="form">
      <div class="row">
        <div class="col-12" v-for="(item, mainIndex) in  mobileInputData" :key="mainIndex">
          <div class="row">
            <div class="col-12 mobile-communication__title">
              {{ item.title }}
            </div>
            <template v-if="item.type==='input'">
              <div class="col-lg-5 col-12 mt-20" v-for="(input, index) in item.inputs" :key="index">
                <div class="form__group">
                  <div class="row">
                    <div class="col-12">
                      <label class="v-label" v-html="input.label"></label>
                    </div>
                    <div class="col-10">
                      <input
                          @input="changeInput($event.target.value, mainIndex, index)"
                          :class="{'v-input--error':input.error}"
                          :disabled="input.disabled" type="text" class="v-input"
                          v-model="input.value">
                    </div>
                    <div class="col-2 pl-0">
                      <input-value-component
                          :sliceCount="6"
                          size="big"
                          :value="input.mean" :title="input.titleMean"/>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="item.type==='select'">
              <div class="col-12 mt-20">
                <label class="v-label"> {{ item.label }}</label>
              </div>
              <div class="col-lg-5 col-12 ">
                <div class="form__group">
                  <div class="row">
                    <div class="col-10">
                      <v-select
                          ref="date"
                          :error="item.error"
                          :allData="true"
                          placeholder="Seçin"
                          @select="(i) => this.select(i, mainIndex)"
                          :options="item.list"
                          :value="item.value.id"
                      />
                    </div>
                    <div class="col-2 pl-0">
                      <input-value-component
                          size="big"
                          v-if="item.value.id!==13"
                          :title="item.meanTitle"
                          :value="item.value.mean"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-12 " v-if="mobileInputData[mainIndex].value.id===13">
                <div class="row">
                  <div class="col-5">
                    <div class="form__group" :class="{'datepicker--error':mobileInputData[mainIndex].errorStart}">
                      <datepicker v-model="startDate" :locale="dataLang"/>
                    </div>
                  </div>
                  <div class="col-5 ">
                    <div class="form__group" :class="{'datepicker--error':mobileInputData[mainIndex].errorEnd}">
                      <datepicker :lowerLimit="startDate" v-model="endDate" :locale="dataLang"/>
                    </div>
                  </div>
                  <div class="col-2 pl-0">
                    <input-value-component
                        :sliceCount="6"
                        size="big"
                        :title="item.meanTitle"
                        :value="item.dateMean"/>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./mobile-communication.component.ts"></script>