import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';
import {IGetAppealFile} from '@/pages/issuance-special-permit-service/types/get-appeal-file';

@injectable()
export class IssuanceSpecialPermitServiceSandbox {
    private store = store;

    getAppealFileSP(payload: IGetAppealFile): void {
        this.store.dispatch('getAppealFileSP', payload);
    }

    getAppealFileOptionSP(payload: IGetAppealFile): void {
        this.store.dispatch('getAppealFileOptionSP', payload);
    }

    getRestrictedCirculationItem(): any {
        return this.store.dispatch('getRestrictedCirculationItemSP');
    }

    getRequiredCrucialData(): void {
        this.store.dispatch('getRequiredCrucialDataSP');
    }

    postCreateAppealSP(payload: FormData): any {
       return  this.store.dispatch('postCreateAppealSP', payload);
    }
}