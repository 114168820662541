import {IGetAppealFile} from '@/pages/issuance-special-permit-service/types/get-appeal-file';

export class GetAppealFileModel {
    public step: string | number | null = null;
    public applicantTypeId: string | number | null = null;
    public isApplicantDifferentWithJuridical: boolean | null = null;
    public restrictedCirculationItemId?: string | number | null = null;

    constructor(data: IGetAppealFile) {
        this._setStep(data);
        this._setApplicantTypeId(data);
        this._setIsApplicantDifferentWithJuridical(data);
        this._setRestrictedCirculationItemId(data);
    }

    private _setStep({step}: any) {
        this.step = step;
    }

    private _setApplicantTypeId({applicantTypeId}: any) {
        this.applicantTypeId = applicantTypeId;
    }

    private _setIsApplicantDifferentWithJuridical({isApplicantDifferentWithJuridical}: any) {
        this.isApplicantDifferentWithJuridical = isApplicantDifferentWithJuridical;
    }

    private _setRestrictedCirculationItemId({restrictedCirculationItemId}: any) {
        this.restrictedCirculationItemId = restrictedCirculationItemId;
    }
}