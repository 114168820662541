import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import FileInputComponent from '@/core/shared/file-input/file-input.component.vue';
import {IFileGeneration} from '@/pages/registration-radio-electronic-devices-service/types/file-generation';
import {IFileType} from '@/pages/issuing-certificate-conformity-service/types/file-type';
import {info} from 'toastr';

@Options({
    name: 'file-generation-component',
    props: {
        files: {
            type: [Array, null],
            required: true
        },
        notShow: {
            type: Array,
            default: [],
            required: false
        },
    },
    components: {
        FileInputComponent
    },
    emits: ['onSelectFile']
})

export default class FileGenerationComponent extends Vue {
    public notShow: string[] = [];
    public files: IFileGeneration[] = [];

    public selectFile(fileInfo: any) {
        this.$emit('onSelectFile', fileInfo);
    }

    public checkShow(label: string) {
        return  this.notShow.some((el:string) => el===label)
    }
}
