import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import InputValueComponent from '@/pages/calculator/components/input-value/input-value.component.vue';
import MobileCommunicationComponent
    from '@/pages/calculator/components/mobile-communication/mobile-communication.component.vue';
import TernCommonComponent from '@/pages/calculator/components/tern-common/tern-common.component.vue';
import {store} from '@/store';

@Options({
    name: 'term-payment-component',
    props: {
        userGroups: {
            type: Array,
            require: true
        },
        serviceTypes: {
            type: Array,
            require: true
        },
        mobile: {
            type: Boolean,
            require: true
        },
        mobileInputData: {
            type: Array,
            require: true
        },
        resetCheck: {
            type: Boolean,
            require: true
        },
        termInfo: {
            type: Object,
            require: true
        },
        selectBandwidthFrequencyNominal: {
            type: Array,
            require: true
        },
        selectGeographicalAreas: {
            type: Array,
            require: true
        },
        selectSEMTechnologyFactor: {
            type: Array,
            require: true
        },
        selectBandSignificances: {
            type: Array,
            require: true
        },
        selectCoefficientSEMTechnology: {
            type: Array,
            require: true
        },
        selectAdjustmentFactor: {
            type: Array,
            require: true
        },
        dateTern: {
            type: Array,
            require: true
        },
        radioFrequencyMultiplier: {
            type: Array,
            require: true
        },
        radioFrequenciesTypes: {
            type: Array,
            require: true
        },
        errorTern: {
            type: Object,
            require: true
        },
    },
    watch: {
        resetCheck: {
            handler: 'changeResetTernCheck',
            immediate: false,
            deep: false,
        }
    },
    components: {
        InputValueComponent,
        MobileCommunicationComponent,
        TernCommonComponent
    }
})

export default class TermPaymentComponent extends Vue {
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public $refs!: {
        userGroupTern: {
            reset: () => void,
        },
        serviceTypesTern: {
            reset: () => void,
        },
    };

    public changeResetTernCheck(val: boolean) {
        if (val) {
            this.$refs?.userGroupTern?.reset();
            this.$refs?.serviceTypesTern?.reset();
        }
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.calculator) {
                this.locale = res.calculator;
            }
        });
    }

    public selectUserGroup(info: any) {
        let payload = {
            type: 'userGroup',
            value: info
        };
        this.store.commit('SET_TERN_INFO', payload);

    }

    public selectServiceType(info: any) {
        if (info.id === 26) {
            this.$emit('mobile', true);
            this.store.commit('SET_TERN_INFO', {
                type: 'userGroup',
                value: {
                    id: null,
                    value: null,
                    mean: ''
                }
            });
        } else {
            let payload = {
                type: 'serviceType',
                value: info
            };
            // this.store.commit('SET_RESET_TERM');
            this.store.commit('SET_RESET_TERN_DETAILS');
            this.store.commit('SET_TERN_INFO', payload);
            this.store.commit('SET_SELECTS_FOR_TERN_PAYMENT', info.id);
            this.$emit('mobile', false);
        }
        this.$emit('resetError');

    }
}
