<style src="./file-generation.component.scss" lang="scss"></style>

<template>
  <div class="file-generation mb-20" v-for="(item) in files" :key="item.id">
    <template v-if="(!checkShow(item.name))">
      <div class="form__group">
        <div class="row">
          <div class="col-12">
            <div class="form__group-wrapper"><label class="v-label">{{ item.label }}</label>
              <span class="form__group-details-icon" v-if="item.description"></span>
              <span class="form__group-details-text" v-if="item.description">{{ item.description }}</span></div>
          </div>
          <div class="col-12">
            <file-input-component
                :multiple="item.uploadLimit>1"
                :limit="item.uploadLimit"
                :fileNameStore="item.file"
                :error="item.error"
                @file="(i) => this.selectFile({...item , file:i})"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script src="./file-generation.component.ts"></script>