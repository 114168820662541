export class PutCancelModel {
    public id: number | null = null;
    public appealStatusId: number | null = null;
    public rejectionReason: string | null = null;
    public appealFileDto: { appealFileTypeId: number, file: File } | null = null;

    constructor(form: any, id: number, appealStatusId: number) {
        this._setId(id);
        this._setAppealStatusId(appealStatusId);
        this._setRejectionReason(form);
        this._setFile(form);
    }

    private _setId(id: number) {
        this.id = id;
    }

    private _setAppealStatusId(appealStatusId: number) {
        this.appealStatusId = appealStatusId;
    }

    private _setRejectionReason({cancelText}: any) {
        this.rejectionReason = cancelText;
    }

    private _setFile({cancelFile}: any) {
        this.appealFileDto = {
            appealFileTypeId: 3,
            file: cancelFile
        };
    }
}