import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class RegistrationRadioElectronicDevicesServiceSandbox {
    private store = store;

    getRadioRegistrationCitizenship(): any {
        return this.store.dispatch('getRadioRegistrationCitizenship');
    }

    getRadioRegistrationDocumentSeriesType(payload: number): any {
        return this.store.dispatch('getRadioRegistrationDocumentSeriesType', payload);
    }

    getRadioRegistrationOrganizationName(payload: any): any {
        return this.store.dispatch('getRadioRegistrationOrganizationName', payload);
    }
    getRadioRegistrationOrganPersonType(): any {
        return this.store.dispatch('getRadioRegistrationOrganPersonType');
    }

    postApplicantRegistration(payload:any): any {
        return this.store.dispatch('postApplicantRegistration', payload);
    }

    postRedCreateAppeal(payload:any): any {
        return this.store.dispatch('postRedCreateAppeal', payload);
    }

    getFileTypesKeyValues(): any {
        return this.store.dispatch('getFileTypesKeyValues');
    }
    getAppealTechnologyKeyValues(): any {
        return this.store.dispatch('getAppealTechnologyKeyValues');
    }


}