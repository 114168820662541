import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'e-signature-file-component',
    props: {
        fileName: {
            type: String,
            required: false
        }
    }
})

export default class ESignatureFileComponent extends Vue {

}