import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import FileInputComponent from '@/core/shared/file-input/file-input.component.vue';

@Options({
    name: 'cancel-form-component',
    props: {
        fileName: {
            type: String,
            required: false
        },
        canselError: {
            type: Object
        }
    },
    components: {
        FileInputComponent
    }
})

export default class CancelFormComponent extends Vue {
    public onChangedInput(info: any, type: any,) {
        let payload = {
            type: type,
            value: info
        };
        this.$emit('onChangedInput', payload);
    }
}
