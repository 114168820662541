import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import FileInputComponent from '@/core/shared/file-input/file-input.component.vue';
import DownloadFileComponent from '@/core/shared/download-file/download-file.component.vue';
import FileGenerationComponent
    from '@/pages/issuing-certificate-conformity-service/components/file-generation/file-generation.component.vue';

@Options({
    name: 'options-form-component',
    components: {
        FileInputComponent, DownloadFileComponent, FileGenerationComponent
    },
    emits: ['addItemInformation', 'deleteItemInformation', 'onSelectFile', 'setOptionInformation',],
    props: {
        errorOptions: {
            type: Object,
            require: true
        },
        errorCommonOptions: {
            type: [Array], require: true
        },
        restrictedCirculationItems: {
            type: [Array, null], require: true
        },
        requiredCrucialData: {
            type: [Array, null], require: true
        },
        options: {
            type: Object
        },
        files: {
            type: [Array, null],
            require: true
        },
        signBtnDisabled: {
            type: Boolean,
            require: true
        }
    }

})

export default class OptionsFormComponent extends Vue {
    public signBtnDisabled: boolean = true;

    public setOptionInformation(event: any, type: string) {
        let payload = {
            type: type,
            value: event
        };
        this.$emit('setOptionInformation', payload);
    }

    public setOptionCommonInfo(type: string, $event: any, index: number) {
        let payload = {
            index: index,
            type: type,
            value: $event
        };
        this.$emit('setOptionCommonInfo', payload);
    }

}
