import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class AppealRadioServiceSandbox {
    private store = store;

    getRadioCitizenship(): any {
        return this.store.dispatch('getRadioCitizenship');
    }

    getRadioFrequencyUnits(): any {
        return this.store.dispatch('getRadioFrequencyUnits');
    }

    getRadioDocumentSeriesType(payload: number): any {
        return this.store.dispatch('getRadioDocumentSeriesType', payload);
    }

    getRadioOrganizationName(payload: any): any {
        return this.store.dispatch('getRadioOrganizationName', payload);
    }

    getRadioOrganPersonType(): any {
        return this.store.dispatch('getRadioOrganPersonType');
    }

    getInterferenceTechnologyTypes(): any {
        return this.store.dispatch('getInterferenceTechnologyTypes');
    }

    getInterferenceTypes(): any {
        return this.store.dispatch('getInterferenceTypes');
    }

    getEffectDurations(): any {
        return this.store.dispatch('getDurations');
    }

    postApplicant(payload: any): any {
        return this.store.dispatch('postApplicant', payload);
    }

    postAppeal(payload: any): any {
        return this.store.dispatch('postAppeal', payload);
    }
}