export class PostApplicantModel {
    public documentNumber: number | string | null = null;
    public pin: string | null = null;
    public phone: string | null = null;
    public email: string | null = null;
    public documentTypeId: number | null = null;
    public serviceApplicantTypeId: number | null = null;
    public applicantOrganization: object | null = null;
    public documentTypeGroupId: number | null = null;



    constructor(item: any, organizationLegalFormValue: any, legalPersonCarrier: any, citizenshipId: any) {
        this._setDocumentNumber(item);
        this._setPin(item);
        this._setPhone(item);
        this._setEmail(item);
        this._setDocumentSeriesTypeId(item);
        this._setApplicantTypeId(organizationLegalFormValue);
        this._setApplicantOrganization(item, legalPersonCarrier, organizationLegalFormValue);
        this._setCitizenshipId(citizenshipId);

    }

    private _setDocumentNumber({documentNumber}: any) {
        this.documentNumber = documentNumber;
    }

    private _setPin({fin}: any) {
        this.pin = fin;
    }

    private _setPhone({phone}: any) {
        this.phone = phone;
    }

    private _setEmail({email}: any) {
        this.email = email;
    }

    private _setDocumentSeriesTypeId({documentTypeNumber}: any) {
        this.documentTypeId = documentTypeNumber?.id;
    }

    private _setApplicantTypeId(organizationLegalFormValue: any) {
        this.serviceApplicantTypeId = organizationLegalFormValue;
    }

    private _setApplicantOrganization({
                                          tin,
                                          organization,
                                          position,
                                          registration
                                      }: any, legalPersonCarrier: any, organizationLegalFormValue: any) {
        if (organizationLegalFormValue !==1) {
            this.applicantOrganization = {
                fullname: organization,
                tin: tin,
                position: position,
                isDriRegistration: !!registration,
                isApplicantOwner: !!legalPersonCarrier
            };
        } else {
            this.applicantOrganization = null;
        }


    }

    private _setCitizenshipId(citizenshipId: any) {
        this.documentTypeGroupId = citizenshipId;
    }


}