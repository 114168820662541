import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import StepDescriptionComponent from '@/core/shared/step-description/step-description.component.vue';
import ProgressbarComponent from '@/core/shared/progressbar/progressbar.component.vue';
import GeneralInformationFormComponent
    from '@/pages/appeal-radio-service/components/general-information-form/general-information-form.component.vue';
import PersonalInformationFormComponent
    from '@/pages/appeal-radio-service/components/personal-information-form/personal-information-form.component.vue';
import ApplicantFormComponent
    from '@/pages/issuing-certificate-conformity-service/components/applicant-form/applicant-form.component.vue';
import OptionsFormComponent
    from '@/pages/issuance-special-permit-service/components/options-form/options-form.component.vue';

@Options({
    name: 'step-permit-component',
    components: {
        StepDescriptionComponent,
        ProgressbarComponent,
        GeneralInformationFormComponent,
        PersonalInformationFormComponent,
        ApplicantFormComponent,
        OptionsFormComponent
    },
    emits: [
        'setOptionCommonInfo',
        'onChangeInput',
        'setPersonalInformation',
        'onSingFiles',
        'checkTin', 'onChangeCertificateStatus', 'deleteItemInformation', 'addItemInformation', 'onSelectFile', 'setOptionInformation'],
    props: {
        errorOptions:{
            type: Object,
            require: true
        },
        options: {
            type: [Object], require: true
        },
        restrictedCirculationItems: {
            type: [Array, null], require: true
        },
        requiredCrucialData: {
            type: [Array, null], require: true
        },
        filesForStep2: {
            type: [Array, null], require: true
        },
        filesForStep3: {
            type: [Array, null], require: true
        },
        certificateStatus: {
            type: Number, require: true
        },
        applicantForm: {
            type: Object, require: true
        },
        userLogin: {
            type: Boolean,
            default: false
        },
        userData: {
            type: Object, require: true
        },
        documentSeriesType: {
            type: Array, require: true
        },
        legalPersonCarrier: {
            type: [Boolean, null], require: true
        },
        organizationLegalForm: {
            type: Array, require: true
        },
        citizenship: {
            type: Array, require: true
        },
        stepDescription: {
            type: Array,
            require: true
        },
        current: {
            type: Number, require: true
        },
        step: {
            type: Number, require: true
        },
        citizenshipValue: {
            type: Number, require: true
        },
        organizationLegalFormValue: {
            type: Number, require: true
        },
        stepOneValidation: {
            type: Object,
            require: true
        },
        personalInformation: {
            type: Object,
            require: true
        },
        errorPersonalInformation: {
            type: Object,
            require: true
        },
        errorRadioInformation: {
            type: Object,
            require: true
        },
        radioInformation: {
            type: Object,
            require: true
        },

        interferenceTechnologyTypes: {
            type: Array,
            require: true
        },
        durations: {
            type: Array,
            require: true
        },
        interferenceTypes: {
            type: Array,
            require: true
        },
        frequencyUnits: {
            type: Array,
            require: true
        },
        organizationName: {
            type: String, require: false
        },
    }
})

export default class StepPermitComponent extends Vue {
    public signBtnDisabled = true;

    public checkTin() {
        this.$emit('checkTin');
    }
}
