import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class RecordKeepingSandbox {
    private store = store;

    getAppeal(payload: { taxIdNumber: string | number | null}): any {
        return this.store.dispatch('getAppeal', payload);
    }
    changeAppealStatus(payload: any): any {
        return this.store.dispatch('changeAppealStatus', payload);
    }

    getActivityAddresses(): any {
        return this.store.dispatch('getActivityAddresses');
    }

    getActivityScopes(): any {
        return this.store.dispatch('getActivityScopes');
    }

    getActivityTypes(): any {
        return this.store.dispatch('getActivityTypes');
    }

    getAppealStatuses(payload: any): any {
        return this.store.dispatch('getAppealStatuses');
    }

    getInternetTelecommunicationsServiceTypes(): any {
        return this.store.dispatch('getInternetTelecommunicationsServiceTypes');
    }

    postIspAppeal(payload:any): any {
        return this.store.dispatch('postIspAppeal', payload);
    }
    putIspAppeal(payload:any): any {
        return this.store.dispatch('putIspAppeal', payload);
    }



    // getSignatureVersion(): any {
    //     return this.store.dispatch('getSignatureVersion');
    // }
    // getReadCertificatesFromStore(): any {
    //     return this.store.dispatch('getReadCertificatesFromStore');
    // }
    // getTsaClients(): any {
    //     return this.store.dispatch('getTsaClients');
    // }
    //
    // postFullSign(payload:any): any {
    //     return this.store.dispatch('postFullSign', payload);
    // }
}