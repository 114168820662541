import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';
import {IGetFileType} from '@/pages/issuing-certificate-conformity-service/types/get-file-type';
import {ICertApplicantInfo} from '@/pages/issuing-certificate-conformity-service/types/cert-applicant-info';

@injectable()
export class IssuingCertificateConformityServiceSandbox {
    private store = store;

    getCitizenshipCert(): void {
        this.store.dispatch('getCitizenshipCert');
    }

    getCertFiles(payload: IGetFileType): void {
        this.store.dispatch('getCertFiles', payload);
    }

    getCertApplicantInfo(payload: ICertApplicantInfo): any {
       return  this.store.dispatch('getCertApplicantInfo', payload);
    }

    getFilesForForStep3(payload: IGetFileType): void {
        this.store.dispatch('getCertFilesForStep3', payload);
    }

    postCertAppeal(payload: any): any {
        return this.store.dispatch('postCertAppeal', payload);
    }
}