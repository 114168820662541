import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {Validation} from '@/core/mixin/validation';
import {IForeignCitizen} from '@/pages/issuing-certificate-conformity-service/types/foreign-citizen';
import FileGenerationComponent
    from '@/pages/issuing-certificate-conformity-service/components/file-generation/file-generation.component.vue';

@Options({
    name: 'foreign-citizen-form-component',
    props: {
        filesForForCertificate: {
            type: [Array, null],
            require: []
        },
        certificateStatus: {
            type: Number,
        },
        certificateCheck: {
            type: Boolean,
            default: false
        },
        citizenshipValue: {
            type: Number, require: true
        },
        organizationLegalFormValue: {
            type: Number, require: true
        },
        personalInformation: {
            type: Object,
            require: true
        },
        errorPersonalInformation: {
            type: Object,
            require: true
        }
    },
    emits:['onSelectFileInformation'],
    components: {
        FileGenerationComponent
    }
})

export default class ForeignCitizenFormComponent extends mixins(Validation) {
    public personalInformation: IForeignCitizen = {
        firstName: null,
        secondName: null,
        phone: null,
        email: null,
        tinDocument: null,
        extractDocument: null,
        powerOfAttorney: null,
        IDCardDocument: null,
    };

    public deleteNumber(event: any, name: any) {
        // this.deletePhoneNumber(event, name, this.personalInformation[`${name}`], 'SET_RADIO_PERSONAL_ORGANIZATION');
    }

    public selectFile(info: File, type: string) {
        this.$emit('setPersonalInformation', {
            type: type,
            value: info
        });
    }

    public phoneChanged(event: any, name: any) {
        let payload = {
            type: name,
            value: event
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }


    public emailChanged(event: any) {
        let payload = {
            type: 'email',
            value: event.toLowerCase()
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

}