import {Vue} from 'vue-class-component';
import {load} from 'recaptcha-v3';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {container} from 'tsyringe';

export class ReCaptcha extends Vue {
    public siteKey = '6LdZg8IfAAAAAEzXF_7RQY0AzNjQ4zhPPR-5Tf5P';
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);

    public onCheckTin(data:{tin:string, applicantTypeId:string | number }) {
        load((this.siteKey), {}).then((re) => {
            re.execute('sendFeedback').then((token) => {
                this.sandboxCommon.getRadioOrganizationName({
                  SecretKey: this.siteKey,
                  tin: data.tin,
                  applicantTypeId: data.applicantTypeId,
                });
            });
        });
    }
}

