import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import '@vuepic/vue-datepicker/dist/main.css';
import {az} from 'date-fns/locale';
import {Validation} from '@/core/mixin/validation';
import {IRadioPersonalInformation} from '@/pages/appeal-radio-service/types/radio-personal-information';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import FileInputComponent from '@/core/shared/file-input/file-input.component.vue';
import {IFileType} from '@/pages/issuing-certificate-conformity-service/types/file-type';

@Options({
    name: 'personal-information-form-component',
    components: {FileInputComponent},
    props: {
        filesForForCertificate: {
            type: [Array, null],
            default: []
        },
        serviceType: {
            type: Number,
            default: 1
        },
        certificateStatus: {
            type: Number,
        },
        certificateCheck: {
            type: Boolean,
            default: false
        },
        documentSeriesType: {
            type: Array, require: true
        },
        personalInformation: {
            type: Object,
            require: true
        },
        citizenshipValue: {
            type: Number, require: true
        },
        organizationLegalFormValue: {
            type: Number, require: true
        },
        legalPersonCarrier: {
            type: Boolean, require: true
        },
        errorPersonalInformation: {
            type: Object, require: true
        },
        userLogin: {
            type: Boolean,
            default: false
        },
        organizationName: {
            type: String, require: false
        },
        req: {
            type: Boolean,
            default: false
        }


    },
    watch: {
        documentSeriesType: {
            handler: 'changeDocumentSeriesType',
            immediate: false,
            deep: false,
        },
        organizationName: {
            handler: 'changeOrganizationName',
            immediate: false,
            deep: false,
        },
        organizationLegalFormValue: {
            handler: 'changeOrganizationLegalFormValue',
            immediate: false,
            deep: false,
        }
    }


})

export default class PersonalInformationFormComponent extends mixins(Validation) {
    public date = null;
    public documentSeriesType: ISearchSelect[] = [];
    public pruningDocumentNumber = 0;
    public dateLimit = new Date();
    public dataLang = az;
    public finError = false;
    public documentTypeError = false;
    public tinError = false;
    public tinValue = '';
    public organizationNameValue = '';
    public organizationLegalFormValue: number | null = null;

    public personalInformation: IRadioPersonalInformation = {
        documentNumber: null,
        documentTypeNumber: null,
        fin: null,
        phone: '+994(',
        email: null,
        tin: null,
        organization: null,
        position: null,
        registration: null,
    };
    public locale$: any = locale;
    public locale: any = null;
    public store = store;
    public pruningFin = 8;


    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.appealRadioService) {
                this.locale = res.appealRadioService;
            }
        });
    }

    public selectFile(info: any, type: any, item: IFileType) {
        this.$emit('setPersonalInformation', {
            type: type,
            value: {
                ...item,
                file: info,
            }
        });
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', {
        //     type: type,
        //     value: info
        // });
    }

    public changeOrganizationName(val: any) {
        this.organizationNameValue = val;
    }

    public changeDocumentSeriesType(val: any, oldVal: any) {
        if (val) {
            this.selectedDocumentType(val[0]);
        }
    }

    public resetDocumentNumber(event: any, name: any) {
        let payload = {
            type: name,
            value: ''
        };
        if (this.personalInformation.documentTypeNumber) {
            payload = {
                type: name,
                value: this.validationDocumentNumberInput(this.personalInformation.documentTypeNumber?.value, event)
            };
        }
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
        return payload;
    }


    public documentNumberChanged(event: any, name: any) {
        let payload = this.resetDocumentNumber(event, name);

        if (this.personalInformation.documentNumber && this.personalInformation.documentTypeNumber) {
            if (this.validationDocumentNumber(this.personalInformation.documentTypeNumber?.value, payload.value)) {
                this.documentTypeError = false;
            } else {
                this.documentTypeError = true;
            }
        }


    }

    public resetFin(data: any) {
        let payload = {
            type: 'fin',
            value: this.validationPinDocumentNumberInput('AZE', data)
        };
        if (this.personalInformation.documentTypeNumber) {
            payload = {
                type: 'fin',
                value: this.validationPinDocumentNumberInput(this.personalInformation.documentTypeNumber.value, data)
            };
        }

        return payload;
    }

    public changeOrganizationLegalFormValue() {
        this.tinError = false;
        this.tinValue = '';
        this.store.commit('SET_RADIO_ORGANIZATION_NAME', {fullName: null});
    }

    public finChanged(event: any) {
        let payload = this.resetFin(event);
        if (this.personalInformation.documentTypeNumber && this.personalInformation.fin) {
            if (payload?.value) {
                if (this.validationFin(this.personalInformation.documentTypeNumber.value, payload?.value)) {
                    this.finError = false;
                } else {
                    this.finError = true;
                }
            }

        }
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public inputChanged(event: any, name: any) {
        let payload = {
            type: name,
            value: event
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public phoneChanged(event: any, name: any) {
        let payload = {
            type: name,
            value: event
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public emailChanged(event: any) {
        let payload = {
            type: 'email',
            value: event.toLowerCase()
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public tinChanged(event: any) {
        let payload = {
            type: 'tin',
            value: event.replace(/[^0-9]+/g, '').slice(0, 10)
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public addressChanged(event: any, name: any) {
        let payload = {
            type: name,
            value: event
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public carrierNameChanged(event: any) {
        let payload = {
            type: 'carrierName',
            value: event
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public carrierPhoneChanged(event: any) {
        let payload = {
            type: 'carrierPhone',
            value: event
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public organizationChanged(event: any) {
        let payload = {
            type: 'organization',
            value: event
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public positionChanged(event: any) {
        let payload = {
            type: 'position',
            value: event
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public selectedDocumentType(info: any) {
        let payload = {
            type: 'documentTypeNumber',
            value: info
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
        this.documentNumberChanged(this.personalInformation.documentNumber, 'documentNumber');
        this.finChanged(this.personalInformation.fin);
    }

    public setPersonalInformation(event: any, type: string) {
        let payload = {
            type: type,
            value: event
        };
        this.$emit('setPersonalInformation', payload);
        // this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
    }

    public setTin(event: any, type: string) {
        this.tinValue = this.validationVoenInput(event);
        if (this.personalInformation.tin !== this.tinValue) {
            this.store.commit('SET_RADIO_ORGANIZATION_NAME', {
                fullName: '',
            });
            let payload = {
                type: type,
                value: this.validationVoenInput(event)
            };
            this.$emit('setPersonalInformation', payload);
            this.store.commit('SET_RADIO_PERSONAL_ORGANIZATION', payload);
            if (this.personalInformation.tin?.length === 10) {
                if ((!(Number(this.personalInformation.tin[9]) === 2) && this.organizationLegalFormValue === 3) ||
                    (!(Number(this.personalInformation.tin[9]) === 1) && this.organizationLegalFormValue === 4)
                ) {
                    this.tinError = true;
                } else {
                    this.tinError = false;
                    this.$emit('checkTin');
                }
            }
        }
    }

    public deleteNumber(event: any, name: any) {
        this.deletePhoneNumber(event, name, this.personalInformation[`${name}`], 'SET_RADIO_PERSONAL_ORGANIZATION');
    }

}
