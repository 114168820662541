import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import TagComponent from '@/core/shared/tag/tag.component.vue';
import TagsComponent from '@/pages/appeal-radio-service/components/tags/tags.component.vue';
import FileInputComponent from '@/core/shared/file-input/file-input.component.vue';
import Datepicker from 'vue3-datepicker';
import {az} from 'date-fns/locale';
// import Datepicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'
import {ref} from 'vue';
import MultipleComponent from '@/core/shared/multiple/multiple.component.vue';

@Options({
    name: 'radio-information-form-component',
    props: {
        durationEffects: {
            type: Array,
            require: true
        },
        radioInformation: {
            type: Object,
            require: true
        },
        errorRadioInformation: {
            type: Object,
            require: true
        },
        interferenceTechnologyTypes: {
            type: Array,
            require: true
        },
        durations: {
            type: Array,
            require: true
        },
        interferenceTypes: {
            type: Array,
            require: true
        },
        frequencyUnits: {
            type: Array,
            require: true
        },
    },
    components: {
        TagComponent, TagsComponent, FileInputComponent, Datepicker, MultipleComponent
    }
})

export default class RadioInformationFormComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public store = store;
    public date = new Date();
    public dataLang = az;
    public time = ref({
        hours: new Date().getHours(),
        minutes: new Date().getMinutes()
    });

    public selectDuration(info: any) {
        this.setRadioInfo(info, 'duration');
    }

    public selectTypeTechnology(info: any) {
        this.setRadioInfo(info, 'typeTechnology');
    }

    public selectNatureObstacle(info: any) {
        this.setRadioInfo(info, 'natureObstacle');
    }

    public selectFrequencyUnits(info: any) {
        this.setRadioInfo(info, 'frequencyUnits');
    }

    public setRadioInfoObstacleArea(event: any) {
        this.store.commit('SET_OBSTACLE_AREA', {
            value: event
        });
    }

    // public addObstacleArea() {
    //     this.store.commit('SET_NEW_OBSTACLE_AREA');
    //     this.$emit('addObstacleArea');
    // }
    //
    // public deleteObstacleArea(index: any) {
    //     this.store.commit('SET_DELETE_OBSTACLE_AREA', index);
    //     this.$emit('deleteObstacleArea', index);
    // }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.appealRadioService) {
                this.locale = res.appealRadioService;
            }
        });
    }

    public setRadioInfo(data: any, type: string) {
        this.store.commit('SET_RADIO_INFO_ORGANIZATION', {
            type: type,
            value: data
        });
    }

    public setFrequencyBand(event: any) {
        this.store.commit('SET_RADIO_INFO_ORGANIZATION', {
            type: 'frequencyBand',
            value: event
        });
    }

    public selectFile(info: any) {
        this.store.commit('SET_RADIO_INFO_ORGANIZATION', {
            type: 'file',
            value: info
        });
    }

    public addDates() {
        this.$emit('addDates');
        this.store.commit('SET_NEW_DATES');

    }

    public deleteDate(index: number) {
        this.store.commit('SET_DELETE_DATES', index);
        this.$emit('deleteDate', index);
    }
}