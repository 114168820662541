import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {IInputPayload} from '@/types/input-payload';

@Options({
    name: 'one-step-radio-checkbox-component',
    props: {
        leftData: {
            type: Object,
            require: true
        },
        rightData: {
            type: Object,
            require: true
        },
        inputType: {
            type: String,
            require: true
        }
    },
    emits: ['onChangeInput'],
})

export default class OneStepRadioCheckboxComponent extends Vue {
    public leftData = {
        value: null,
        label: null,
        description: null
    };
    public value = this.leftData.value;
    public inputType: string = '';

    public onChangeInput(type: string, value: string) {
        let payload: IInputPayload = {
            type: type,
            value: value
        };
        this.$emit('onChangeInput', payload);
    }

    public mounted() {
        this.value =   this.leftData.value
    }

}