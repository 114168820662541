<style src="./tag.component.scss" lang="scss"></style>

<template>
  <div class="tag" :class="`tag--${color}`">
    <div class="tag__name">
      <slot></slot>
    </div>
    <div class="tag__action" @click="onDeleteItem()" v-if="deleteCheck">
      <img src="@/assets/images/icons/close.svg" alt="">
    </div>
  </div>

</template>

<script src="./tag.component.ts"></script>
