<style src="./ip-wrapper.component.scss" lang="scss"></style>

<template>

  <div class="ip-wrapper" :class="{'ip-wrapper--disabled':disabled}">
    <div :style="{'width':`${this.width}px`}">
      <ip-input-component
          :disabled="disabled"
          :class="{'ml-10':(index===0&& arrData.length>3)}"
          :length="arrData.length"
          @deleteItem="() =>{deleteItem(index)}"
          @endFocus="() =>{changedEndFocus(index)}"
          @startFocus="() =>{changedStartFocus(index)}"
          @onEndFocus="() =>{onsEndFocus(index)}"
          @onStartFocus="() =>{onsStartFocus(index)}"
          v-for="(item, index) in arrData"
          :key="item.id"
          :item="item"
          :firstFocus="item.firstFocus"
          :endFocus="item.endFocus"
          @changeIP="(i) =>{changeIP(i, index)}"
          @action="(i) =>{action(i, index)}"/>
    </div>
  </div>
</template>

<script src="./ip-wrapper.component.ts"></script>
