import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import TabRadioComponent from '@/core/shared/tab-radio/tab-radio.component.vue';
import OneTimePaymentComponent from '@/pages/calculator/components/one-time-payment/one-time-payment.component.vue';
import TermPaymentComponent from '@/pages/calculator/components/term-payment/term-payment.component.vue';
import {IOneTimePaymentInfo} from '@/pages/calculator/types/oneTimePayment';
import ResultComponent from '@/pages/calculator/components/result/result.component.vue';
import MobileCommunicationComponent
    from '@/pages/calculator/components/mobile-communication/mobile-communication.component.vue';
import {ISelectCalculator} from '@/pages/calculator/types/select-calculator';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import {container} from 'tsyringe';
import {CalculatorSandbox} from '@/pages/calculator/sandbox/calculator.sandbox';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import {Router} from '@/core/mixin/router';
import {loginStateMutations} from '@/pages/login/store/store/mutations';

@Options({
    name: 'calculator-component',
    components: {
        TabRadioComponent,
        OneTimePaymentComponent,
        TermPaymentComponent,
        ResultComponent,
        MobileCommunicationComponent,
        ServiceNameComponent
    },
    watch: {
        throughputTypes: {
            handler: 'changeThroughputTypes',
            immediate: false,
            deep: false,
        },
        bandSignificances: {
            handler: 'changeBandSignificances',
            immediate: false,
            deep: false,
        },
        geographicalAreas: {
            handler: 'changeGeographicalAreas',
            immediate: false,
            deep: false,
        },
        selectBandwidthFrequencyNominal: [
            {
                handler: 'changeSelectBandwidthFrequencyNominalValue',
                immediate: false,
                deep: false,
            },
        ],
        selectGeographicalAreas: [
            {
                handler: 'changeSelectGeographicalAreasValue',
                immediate: false,
                deep: false,
            },
        ],
        selectSEMTechnologyFactor: [
            {
                handler: 'changeSelectSEMTechnologyFactorValue',
                immediate: false,
                deep: false,
            },
        ],
        selectBandSignificances: [
            {
                handler: 'changeSelectBandSignificancesValue',
                immediate: false,
                deep: false,
            },
        ],
        selectCoefficientSEMTechnology: [
            {
                handler: 'changeSelectCoefficientSEMTechnologyValue',
                immediate: false,
                deep: false,
            },
        ],
        selectAdjustmentFactor: [
            {
                handler: 'changeSelectAdjustmentFactorValue',
                immediate: false,
                deep: false,
            },
        ],

    }
})

export default class CalculatorComponent extends mixins(Router) {
    public sandbox = container.resolve(CalculatorSandbox);
    public viewMobile = 1;
    public viewName: string = 'one-time-payment-component';
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public calculateCheck = false;
    public resetCheck = false;
    public mobile = false;
    public details = 'B';
    public errorOneTime = {
        userGroup: false,
        serviceType: false,
        frequency: false,
        bandSignificance: false,
        throughputType: false,
        geographicalArea: false,
    };

    public errorTern = {
        serviceType: false,
        userGroup: false,
        frequenciesCountMean: false,
        bandwidthFrequencyNominal: false,
        geographicalAreas: false,
        SEMTechnologyFactor: false,
        bandSignificances: false,
        coefficientSEMTechnology: false,
        date: false,
        dateStart: false,
        dateEnd: false,
        adjustmentFactor: false,
        frequenciesPropertyFirst: false,
        frequenciesPropertySecond: false
    };

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get userGroups(): ISelectCalculator[] {
        return this.store.getters._getUserGroups;
    }

    get dateTern(): ISelectCalculator[] {
        return this.store.getters._getDateTern;
    }

    get radioFrequenciesTypes(): ISelectCalculator[] {
        return this.store.getters._getRadioFrequenciesTypes;
    }

    get serviceTypeTern(): ISelectCalculator[] {
        return this.store.getters._getServiceTypeTern;
    }

    get selectBandwidthFrequencyNominal(): ISelectCalculator[] {
        return this.store.getters._getSelectBandwidthFrequencyNominal;
    }

    get selectGeographicalAreas(): ISelectCalculator[] {
        return this.store.getters._getSelectGeographicalAreas;
    }

    get selectSEMTechnologyFactor(): ISelectCalculator[] {
        return this.store.getters._getSelectSEMTechnologyFactor;
    }

    get selectBandSignificances(): ISelectCalculator[] {
        return this.store.getters._getSelectBandSignificances;
    }

    get selectCoefficientSEMTechnology(): ISelectCalculator[] {
        return this.store.getters._getSelectCoefficientSEMTechnology;
    }

    get selectAdjustmentFactor(): ISelectCalculator[] {
        return this.store.getters._getSelectAdjustmentFactor;
    }

    get termInfo(): any {
        return this.store.getters._getTermInfo;
    }

    get result(): number | null {
        return this.store.getters._getResult;
    }

    get totalResult(): number | null {
        return this.store.getters._getTotalResult;
    }

    get vat(): number | null {
        return this.store.getters._getVat;
    }

    get serviceTypes(): ISelectCalculator[] {
        return this.store.getters._getServiceTypes;
    }

    get frequencies(): ISelectCalculator[] {
        return this.store.getters._getFrequencies;
    }

    get throughputTypes(): ISelectCalculator[] {
        return this.store.getters._getThroughputTypes;
    }

    get bandSignificances(): ISelectCalculator[] {
        return this.store.getters._getBandSignificances;
    }

    get geographicalAreas(): ISelectCalculator[] {
        return this.store.getters._getGeographicalAreas;
    }

    get oneTimePaymentInfo(): IOneTimePaymentInfo {
        return this.store.getters._getOneTimePaymentInfo;
    }

    get mobileInputData() {
        return this.store.getters._getMobileInputData;
    }

    get serviceTypesOneTime() {
        return this.store.getters._getServiceTypesOneTime;
    }

    get frequency() {
        return this.store.getters._getFrequency;
    }

    get radioFrequencyMultiplier() {
        return this.store.getters._getRadioFrequencyMultiplier;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }


    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.calculator) {
                this.locale = res.calculator;
            }
        });
    }

    public mounted() {
        this.getServiceData();
    }

    public changeThroughputTypes(val: any) {
        if (val?.length == 1) {
            let payload = {
                type: 'throughputType',
                value: val[0]
            };
            this.store.commit('SET_ONE_TIME_PAYMENT', payload);
        }
    }

    public changeBandSignificances(val: any) {
        if (val?.length == 1) {
            let payload = {
                type: 'bandSignificance',
                value: val[0]
            };
            this.store.commit('SET_ONE_TIME_PAYMENT', payload);
        }
    }

    public changeGeographicalAreas(val: any) {
        if (val?.length == 1) {
            let payload = {
                type: 'geographicalArea',
                value: val[0]
            };
            this.store.commit('SET_ONE_TIME_PAYMENT', payload);
        }
    }

    public changeSelectBandwidthFrequencyNominalValue(val: any) {
        if (val?.length == 1) {
            let payload = {
                type: 'bandwidthFrequencyNominal',
                value: val[0]
            };
            this.store.commit('SET_TERN_INFO', payload);
        }
    }

    public changeSelectGeographicalAreasValue(val: any) {
        if (val?.length == 1) {
            let payload = {
                type: 'geographicalAreas',
                value: val[0]
            };
            this.store.commit('SET_TERN_INFO', payload);
        }
    }

    public changeSelectSEMTechnologyFactorValue(val: any) {
        if (val?.length == 1) {
            let payload = {
                type: 'SEMTechnologyFactor',
                value: val[0]
            };
            this.store.commit('SET_TERN_INFO', payload);
        }
    }

    public changeSelectBandSignificancesValue(val: any) {
        if (val?.length == 1) {
            let payload = {
                type: 'bandSignificances',
                value: val[0]
            };
            this.store.commit('SET_TERN_INFO', payload);
        }
    }

    public changeSelectCoefficientSEMTechnologyValue(val: any) {
        if (val?.length == 1) {
            let payload = {
                type: 'coefficientSEMTechnology',
                value: val[0]
            };
            this.store.commit('SET_TERN_INFO', payload);
        }
    }

    public changeSelectAdjustmentFactorValue(val: any) {
        if (val?.length == 1) {
            let payload = {
                type: 'adjustmentFactor',
                value: val[0]
            };
            this.store.commit('SET_TERN_INFO', payload);
        }
    }

    public checkMobile(info: any) {
        this.mobile = info;
    }

    public resetError() {
        this.errorOneTime = {
            userGroup: false,
            serviceType: false,
            frequency: false,
            bandSignificance: false,
            throughputType: false,
            geographicalArea: false,
        };

        this.errorTern = {
            serviceType: false,
            userGroup: false,
            frequenciesCountMean: false,
            bandwidthFrequencyNominal: false,
            geographicalAreas: false,
            SEMTechnologyFactor: false,
            bandSignificances: false,
            coefficientSEMTechnology: false,
            date: false,
            dateStart: false,
            dateEnd: false,
            adjustmentFactor: false,
            frequenciesPropertyFirst: false,
            frequenciesPropertySecond: false
        };
    }

    public changeViewTab(info: number) {
        this.store.commit('SET_RESET_TERM');
        this.store.commit('SET_RESET_ONE_TIME_PAYMENT');
        if (info === 1) {
            this.viewName = 'one-time-payment-component';

        } else {
            this.viewName = 'term-payment-component';
        }
        this.mobile = false;
        this.viewMobile = info;
        this.calculateCheck = false;
        let payload = {
            result: null,
            totalResult: null,
            vat: null
        };
        this.store.commit('SET_RESULTS', payload);
    }

    public oneTimeValidation() {
        this.oneTimePaymentInfo.throughputType?.id ? this.errorOneTime.throughputType = false : this.errorOneTime.throughputType = true;
        this.oneTimePaymentInfo.userGroup?.id ? this.errorOneTime.userGroup = false : this.errorOneTime.userGroup = true;
        this.oneTimePaymentInfo.serviceType?.id ? this.errorOneTime.serviceType = false : this.errorOneTime.serviceType = true;
        this.oneTimePaymentInfo.frequencyCount ? this.errorOneTime.frequency = false : this.errorOneTime.frequency = true;
        this.oneTimePaymentInfo.bandSignificance?.id ? this.errorOneTime.bandSignificance = false : this.errorOneTime.bandSignificance = true;
        this.oneTimePaymentInfo.geographicalArea?.id ? this.errorOneTime.geographicalArea = false : this.errorOneTime.geographicalArea = true;
    }

    public checkOneTimeValidation() {
        if (!this.errorOneTime.throughputType
            && !this.errorOneTime.userGroup
            && !this.errorOneTime.serviceType
            && !this.errorOneTime.frequency
            && !this.errorOneTime.bandSignificance
            && !this.errorOneTime.geographicalArea
        ) {
            return false;
        } else {
            return true;
        }
    }

    public calculateOneTime() {
        let totalResult = Number(this.oneTimePaymentInfo.userGroup?.mean)
            * Number(this.oneTimePaymentInfo.serviceType?.mean)
            * Number(this.oneTimePaymentInfo.bandSignificance?.mean)
            * Number(this.oneTimePaymentInfo.throughputType?.mean)
            * Number(this.oneTimePaymentInfo.geographicalArea?.mean)
            * Number(this.oneTimePaymentInfo.frequencyCount)
        ;
        let result = totalResult / 1.18;
        let vat = totalResult - result;
        let payload = {
            result: result.toFixed(2),
            totalResult: totalResult.toFixed(2),
            vat: vat.toFixed(2)
        };
        this.store.commit('SET_RESULTS', payload);
    };

    public calculate() {
        this.resetCheck = false;
        if (this.viewMobile === 1) {
            this.oneTimeValidation();
            if (!this.checkOneTimeValidation()) {
                this.sandbox.getCalculateResult();
                this.details = 'B';
                this.calculateCheck = true;
                this.calculateOneTime();
            } else {
                this.calculateCheck = false;
            }
        } else {
            if (this.mobile) {
                this.mobileDataValidation();
                if (!this.checkValidation()) {
                    this.sandbox.getCalculateResult();
                    this.details = 'M';
                    this.calculateCheck = true;
                    this.calculateMobile();
                } else {
                    this.calculateCheck = false;
                }
            } else {
                this.validationCalculateTern();
                if (!this.checkValidationTern()) {
                    // this.sandbox.getCalculateResult();
                    this.details = 'M';
                    this.calculateCheck = true;
                    this.calculateTern();
                } else {
                    this.calculateCheck = false;
                }

            }
        }
    }

    public checkValidationTern() {
        let check = false;
        if (!this.errorTern.serviceType
            && !this.errorTern.userGroup
            && !this.errorTern.frequenciesCountMean
            && !this.errorTern.bandwidthFrequencyNominal
            && !this.errorTern.geographicalAreas
            && !this.errorTern.SEMTechnologyFactor
            && !this.errorTern.bandSignificances
            && !this.errorTern.coefficientSEMTechnology
            && !this.errorTern.date
            && !this.errorTern.dateStart
            && !this.errorTern.dateEnd
            && !this.errorTern.adjustmentFactor
            && !this.errorTern.frequenciesPropertyFirst
            && !this.errorTern.frequenciesPropertySecond
        ) {
            check = false;
        } else {
            check = true;
        }
        return check;

    }

    public validationCalculateTern() {
        this.termInfo.serviceType.id ? this.errorTern.serviceType = false : this.errorTern.serviceType = true;
        this.termInfo.userGroup.id ? this.errorTern.userGroup = false : this.errorTern.userGroup = true;

        this.termInfo.bandwidthFrequencyNominal.id ? this.errorTern.bandwidthFrequencyNominal = false : this.errorTern.bandwidthFrequencyNominal = true;
        this.termInfo.geographicalAreas.id ? this.errorTern.geographicalAreas = false : this.errorTern.geographicalAreas = true;
        this.termInfo.SEMTechnologyFactor.mean ? this.errorTern.SEMTechnologyFactor = false : this.errorTern.SEMTechnologyFactor = true;
        this.termInfo.bandSignificances.id ? this.errorTern.bandSignificances = false : this.errorTern.bandSignificances = true;
        this.termInfo.coefficientSEMTechnology.id ? this.errorTern.coefficientSEMTechnology = false : this.errorTern.coefficientSEMTechnology = true;
        if (this.termInfo.serviceType.id !== 1
            && this.termInfo.serviceType.id !== 3
            && this.termInfo.serviceType.id !== 5
            && this.termInfo.serviceType.id !== 25
            && this.termInfo.serviceType.id !== 11
            && this.termInfo.serviceType.id !== 12
            && this.termInfo.serviceType.id !== 13
            && this.termInfo.serviceType.id !== 14
            && this.termInfo.serviceType.id !== 15
            && this.termInfo.serviceType.id !== 16
            && this.termInfo.serviceType.id !== 17
        ) {
            this.termInfo.adjustmentFactor.id ? this.errorTern.adjustmentFactor = false : this.errorTern.adjustmentFactor = true;
        }
        this.termInfo.date.id ? this.errorTern.date = false : this.errorTern.date = true;
        if (this.termInfo.date.id === 13) {
            this.termInfo.dateStart ? this.errorTern.dateStart = false : this.errorTern.dateStart = true;
            this.termInfo.dateEnd ? this.errorTern.dateEnd = false : this.errorTern.dateEnd = true;
        }
        this.termInfo.date.id ? this.errorTern.date = false : this.errorTern.date = true;
        if (this.termInfo.serviceType.id !== 7
            && this.termInfo.serviceType.id !== 8
            && this.termInfo.serviceType.id !== 9
            && this.termInfo.serviceType.id !== 10) {
            this.termInfo.frequenciesCountMean ? this.errorTern.frequenciesCountMean = false : this.errorTern.frequenciesCountMean = true;
        }
        // if (this.termInfo.serviceType.id === 7
        //     || this.termInfo.serviceType.id === 8
        //     || this.termInfo.serviceType.id === 10) {
        //     this.termInfo.frequenciesPropertyFirst || this.termInfo.frequenciesPropertyFirst === 0 ? this.errorTern.frequenciesPropertyFirst = false : this.errorTern.frequenciesPropertyFirst = true;
        //     this.termInfo.frequenciesPropertySecond || this.termInfo.frequenciesPropertySecond === 0 ? this.errorTern.frequenciesPropertySecond = false : this.errorTern.frequenciesPropertySecond = true;
        // }
        // if (this.termInfo.serviceType.id === 9) {
        //     this.termInfo.frequenciesPropertyFirst ? this.errorTern.frequenciesPropertyFirst = false : this.errorTern.frequenciesPropertyFirst = true;
        // }
        if (this.termInfo.date.id === 13) {
            this.termInfo.startDate ? this.errorTern.dateStart = false : this.errorTern.dateStart = true;
            this.termInfo.endDate ? this.errorTern.dateEnd = false : this.errorTern.dateEnd = true;
        }
    }

    public calculateTern() {

        let totalResult = 0;
        let result = 0;
        let vat = 0;

        let totalResultCommon = Number(this.termInfo.userGroup.mean) * (
            Number(this.termInfo.serviceType.mean)
            * Number(this.termInfo.frequenciesCountMean)
            * Number(this.termInfo.bandwidthFrequencyNominal.mean)
            * Number(this.termInfo.geographicalAreas.mean)
            * Number(this.termInfo.SEMTechnologyFactor.mean)
            * Number(this.termInfo.bandSignificances.mean)
            * Number(this.termInfo.coefficientSEMTechnology.mean)
            * Number(this.termInfo.date.mean));


        if (this.termInfo.serviceType.id === 1
            || this.termInfo.serviceType.id === 3
            || this.termInfo.serviceType.id === 5
            || this.termInfo.serviceType.id === 25
            || (this.termInfo.serviceType.id >= 11) && (this.termInfo.serviceType.id <= 17)
        ) {
            totalResult = totalResultCommon;

            // totalResult = Number(this.termInfo.userGroup.mean)
            //     * Number(this.termInfo.serviceType.mean)
            //     * Number(this.termInfo.frequenciesCountMean)
            //     * Number(this.termInfo.bandwidthFrequencyNominal.mean)
            //     * Number(this.termInfo.geographicalAreas.mean)
            //     * Number(this.termInfo.SEMTechnologyFactor.mean)
            //     * Number(this.termInfo.bandSignificances.mean)
            //     * Number(this.termInfo.coefficientSEMTechnology.mean)
            //     * Number(this.termInfo.date.mean)
            // ;
        } else {
            if (this.termInfo.serviceType.id === 7 || this.termInfo.serviceType.id === 8 || this.termInfo.serviceType.id === 9 || this.termInfo.serviceType.id === 10) {
                if (this.termInfo.serviceType.id === 9) {
                    totalResult = (Number(this.termInfo.serviceType.mean)
                            * Number(this.termInfo.frequenciesCountMean)
                            * Number(this.termInfo.bandwidthFrequencyNominal.mean)
                            * Number(this.termInfo.geographicalAreas.mean)
                            * Number(this.termInfo.SEMTechnologyFactor.mean)
                            * Number(this.termInfo.bandSignificances.mean)
                            * Number(this.termInfo.coefficientSEMTechnology.mean)
                            * Number(this.termInfo.adjustmentFactor.mean)
                            + (Number(this.termInfo.frequenciesPropertyFirst) * 8 * Number(this.termInfo.adjustmentFactor.mean))) * Number(this.termInfo.date.mean) * Number(this.termInfo.userGroup.mean)
                } else {
                    totalResult = Number(this.termInfo.userGroup.mean) *
                        (Number(this.termInfo.serviceType.mean)
                            * Number(this.termInfo.frequenciesCountMean)
                            * Number(this.termInfo.bandwidthFrequencyNominal.mean)
                            * Number(this.termInfo.geographicalAreas.mean)
                            * Number(this.termInfo.SEMTechnologyFactor.mean)
                            * Number(this.termInfo.bandSignificances.mean)
                            * Number(this.termInfo.coefficientSEMTechnology.mean)
                            * Number(this.termInfo.adjustmentFactor.mean)
                            + ((Number(this.termInfo.frequenciesPropertyFirst) * 12) + (Number(this.termInfo.frequenciesPropertySecond) * 24)) * Number(this.termInfo.adjustmentFactor.mean)) * Number(this.termInfo.date.mean);
                }
            } else {
                totalResult = totalResultCommon
                    * Number(this.termInfo.adjustmentFactor.mean);
            }
        }

        result = totalResult / 1.18;
        vat = totalResult - result;

        let payload = {
            result: result.toFixed(2),
            totalResult: totalResult.toFixed(2),
            vat: vat.toFixed(2)
        };
        //     (this.termInfo.serviceType.mean),
        //     (this.termInfo.frequenciesCountMean),
        //     (this.termInfo.bandwidthFrequencyNominal.mean),
        //     (this.termInfo.geographicalAreas.mean),
        //     (this.termInfo.SEMTechnologyFactor.mean),
        //     (this.termInfo.bandSignificances.mean),
        //     (this.termInfo.coefficientSEMTechnology.mean),
        //     (this.termInfo.date.mean),
        //     this.termInfo.adjustmentFactor.mean,
        //     ((Number(this.termInfo.frequenciesPropertyFirst) * 8) * Number(this.termInfo.adjustmentFactor.mean)),
        //     ((Number(this.termInfo.frequenciesPropertyFirst) * 12) + (Number(this.termInfo.frequenciesPropertySecond) * 24) * Number(this.termInfo.adjustmentFactor.mean)));
        this.store.commit('SET_RESULTS', payload);

    }

    public calculateMobile() {
        let totalResult = 0,
            resultA = 0,
            resultB = 0,
            vat = 0,
            result = 0,
            x = this.mobileInputData[4].inputs[0].mean,
            y = this.mobileInputData[4].inputs[1].mean,
            z = this.mobileInputData[4].inputs[2].mean,
            f = 0,
            aGroup = this.mobileInputData[0].inputs,
            bGroup = this.mobileInputData[1].inputs,
            cGroup = this.mobileInputData[3].inputs;
        if (this.mobileInputData[2].value.id === 13) {
            f = this.mobileInputData[2].dateMean;
        } else {
            f = this.mobileInputData[2].value.mean;
        }
        resultA = Number(y) * (
            (Number(aGroup[0].mean) * Number(cGroup[0].mean))
            + (Number(aGroup[1].mean) * Number(cGroup[1].mean))
            + (Number(aGroup[2].mean) * Number(cGroup[2].mean))
            + (Number(aGroup[3].mean) * Number(cGroup[3].mean))
            + (Number(aGroup[4].mean) * Number(cGroup[4].mean))
            + (Number(aGroup[5].mean) * Number(cGroup[5].mean))
            + (Number(aGroup[6].mean) * Number(cGroup[6].mean))
        );
        resultB = Number(z) * (
            (Number(bGroup[0].mean) * Number(cGroup[0].mean))
            + (Number(bGroup[1].mean) * Number(cGroup[1].mean))
            + (Number(bGroup[2].mean) * Number(cGroup[2].mean))
            + (Number(bGroup[3].mean) * Number(cGroup[3].mean))
            + (Number(bGroup[4].mean) * Number(cGroup[4].mean))
            + (Number(bGroup[5].mean) * Number(cGroup[5].mean))
            + (Number(bGroup[6].mean) * Number(cGroup[6].mean))
        );
        totalResult = Number(x) * (resultA + resultB) * (Number(f));
        result = totalResult / 1.18;
        vat = totalResult - result;
        let payload = {
            result: result.toFixed(2),
            totalResult: totalResult.toFixed(2),
            vat: vat.toFixed(2)
        };
        this.store.commit('SET_RESULTS', payload);

    };

    public checkValidation() {
        let checkOne = this.mobileInputData[0].inputs.some((item: any) => {
            return item.error === true;
        });
        let checkTwo = this.mobileInputData[1].inputs.some((item: any) => {
            return item.error === true;
        });
        let checkThree = this.mobileInputData[2].error;
        let checkStartDate = this.mobileInputData[2].errorStart;
        let checkEndDate = this.mobileInputData[2].errorEnd;
        if (!checkOne && !checkTwo && !checkThree && !checkEndDate && !checkStartDate) {
            return false;
        } else {
            return true;
        }
    }

    public mobileDataValidation() {
        this.mobileInputData.forEach((item: any) => {
            if (item.type === 'input') {
                item.inputs.forEach((itemInput: any) => {
                    itemInput.value ? itemInput.error = false : itemInput.error = true;
                });
            }
            if (item.type === 'select') {
                if (item.value.id === 13) {
                    item.startDate ? item.errorStart = false : item.errorStart = true;
                    item.endDate ? item.errorEnd = false : item.errorEnd = true;
                    item.error = false;
                } else {
                    item.value.id ? item.error = false : item.error = true;
                }
            }
        });
    }

    public resetData() {
        this.calculateCheck = false;
        this.resetCheck = true;
        let payload = {
            result: null,
            totalResult: null,
            vat: null
        };
        this.store.commit('SET_RESULTS', payload);
        this.store.commit('SET_RESET_ONE_TIME_PAYMENT');
        this.store.commit('SET_RESET_MOBILE_INPUTS_DATA');
        this.store.commit('SET_RESET_TERM');

    }

}
