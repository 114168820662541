import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'step-description-component',
    props: {
        stepDescription: {
            type: Array,
            require: true
        },
        current: {
            type: [Number, String],
            require: true
        }
    }
})

export default class StepDescriptionComponent extends Vue {

}