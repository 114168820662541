<style src="./term-payment.component.scss" lang="scss"></style>

<template>
  <div class="term-payment">
    <form class="form">
      <div class="row">
        <div class="col-lg-5 col-12" v-if="!mobile">
          <div class="form__group">
            <div class="row">
              <div class="col-12">
                <label class="v-label"> {{ locale.userGroup }}</label>
              </div>
              <div class="col-10">
                <v-select
                    :error="errorTern.userGroup"
                    ref="userGroupTern"
                    :allData="true"
                    placeholder="Seçin"
                    @select="selectUserGroup"
                    :options="userGroups"

                />
              </div>
              <div class="col-2 pl-0">
                <input-value-component
                    :value="termInfo.userGroup.mean"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-100" v-if="!mobile"></div>
        <div class="col-lg-5 col-12" :class="{'mt-20':!mobile}">
          <div class="form__group">
            <div class="row">
              <div class="col-12">
                <label class="v-label"> {{ locale.serviceType }}</label>
              </div>
              <div class="col-10">
                <v-select
                    :error="errorTern.serviceType"
                    ref="serviceTypesTern"
                    :allData="true"
                    placeholder="Seçin"
                    @select="selectServiceType"
                    :options="serviceTypes"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-100" v-if="!mobile"></div>
        <div class="col-lg-5 col-12  mt-20" v-if="!mobile">
          <div class="form__group">
            <div class="row">
              <div class="col-12">
                <label class="v-label"> Sabit dəyər kəmiyyəti</label>
              </div>
              <div class="col-10">
                <input :class="{'v-input--error':errorTern.serviceType}" type="text" class="v-input" disabled v-model="termInfo.serviceType.value">
              </div>
              <div class="col-2 pl-0">
                <input-value-component
                    title="M1"
                    :value="termInfo.serviceType.mean"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="mobile">
          <transition
              :duration="150"
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut"
              mode="out-in">
            <mobile-communication-component
                class="mt-20"
                :resetCheck="resetCheck"
                :mobileInputData="mobileInputData"/>
          </transition>
        </div>
        <div class="col-12" v-else>
          <transition
              :duration="150"
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut"
              mode="out-in">
            <tern-common-component
                :errorTern="errorTern"
                :radioFrequenciesTypes="radioFrequenciesTypes"
                :radioFrequencyMultiplier="radioFrequencyMultiplier"
                :dateTern="dateTern"
                :selectBandwidthFrequencyNominal="selectBandwidthFrequencyNominal"
                :selectGeographicalAreas="selectGeographicalAreas"
                :selectSEMTechnologyFactor="selectSEMTechnologyFactor"
                :selectBandSignificances="selectBandSignificances"
                :selectCoefficientSEMTechnology="selectCoefficientSEMTechnology"
                :selectAdjustmentFactor="selectAdjustmentFactor"
                class="mt-20"
                :resetCheck="resetCheck"
                :termInfo="termInfo"
            />
          </transition>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./term-payment.component.ts"></script>
