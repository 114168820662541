import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import InputValueComponent from '@/pages/calculator/components/input-value/input-value.component.vue';
import Datepicker from 'vue3-datepicker';
import {az} from 'date-fns/locale';

@Options({
    name: 'mobile-communication-component',
    components: {
        InputValueComponent,
        Datepicker
    },
    props: {
        mobileInputData: {
            type: Array,
            required: true
        },
        resetCheck: {
            type: Boolean,
            require: true
        },
    },
    watch: {
        endDate: [
            {
                handler: 'changeEndDate',
                immediate: false,
                deep: false,
            },
        ],
        startDate: [
            {
                handler: 'changeStartDate',
                immediate: false,
                deep: false,
            },
        ],
        resetCheck: [
            {
                handler: 'changeResetCheckMobile',
                immediate: false,
                deep: false,
            },
        ],
    }
})

export default class MobileCommunicationComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public store = store;
    public dataLang = az;
    public startDate: Date | null = null;
    public endDate: Date | null = null;

    public select(info: any, index: any) {
        let payload = {
            type: 'select',
            index: index,
            value: info
        };
        this.store.commit('SET_MOBILE_INPUTS_DATA', payload);
    }

    public changeInput($event: any, mainIndex: number, index: number) {
        let value = $event.replace(/[^\d\.]/g, '').slice(0, 6);
        if (value[0] === '.') {
            value = '0.';
        }
        if (value.match(/\./g)?.length > 1) {
            value = value.substr(0, value.lastIndexOf('.'));
        }
        let payload = {
            type: 'input',
            index: index,
            mainIndex: mainIndex,
            value: value
        };
        this.store.commit('SET_MOBILE_INPUTS_DATA', payload)
    }

    public mounted(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.calculator) {
                this.locale = res.calculator;
            }
        });
    }

    public changeEndDate(val: any) {
        let payload = {
            type: 'select',
            startDate: this.startDate,
            endDate: val,
            mean: ''
        };
        if (this.startDate !== null) {
            payload.mean = String(this.diffDates(this.startDate, this.endDate));
        }

        this.store.commit('SET_MOBILE_DATE_INPUTS', payload);
    }

    public changeStartDate(val: any) {
        let payload = {
            type: 'select',
            startDate: val,
            endDate: this.endDate,
            mean: ''
        };
        if (this.endDate !== null) {
            payload.mean = String(this.diffDates(this.startDate, this.endDate));
        }
        this.store.commit('SET_MOBILE_DATE_INPUTS', payload);
    }

    public diffDates(dateStart: any, dateEnd: any) {
        return ((dateEnd - dateStart) / (60 * 60 * 24 * 1000)) / 365;
    }

    public changeResetCheckMobile(val: boolean) {
        if (val) {
            this.startDate = null;
            this.endDate = null;
        }
    }
}