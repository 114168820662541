/**
 * Locale of messages
 */
let locale: 'az' | 'en' | 'ru' = 'az';
export function getLang(): 'az' | 'en' | 'ru' {
  return locale;
}
export function setLang(newLocale: 'az' | 'en' | 'ru'): void {
  locale = newLocale;
}
/**
 * Login type is the way of determining user esign provider
 * loginWithAsanSign => Asan Sign esign provider
 * loginWithMhmSign => SIMA Token esign provider
 */
let loginType: 'loginWithAsanSign' | 'loginWithMhmSign' = 'loginWithMhmSign';
export function getLoginType() {
  return loginType;
}
export function setLoginType(newLoginType: 'loginWithAsanSign' | 'loginWithMhmSign') {
  loginType = newLoginType;
}
