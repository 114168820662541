<style src="./card-info.component.scss" lang="scss"></style>

<template>
  <div class="card-info p-24" :class="[`card-info--${color}`]">
    <div class="card-info__title mb-15">
      <slot name="title"></slot>
    </div>
    <div class="card-info__description">
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script src="./card-info.component.ts"></script>
