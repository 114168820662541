<style src="./user-information.component.scss" lang="scss"></style>

<template>
  <div class="user-information">
    <div class="row">
      <div class="col-md-5 col-12">
        <div class="user-information__wrapper">
          <div class="user-information__title">
            Təşkilat adı:
          </div>
          <div class="user-information__description">
            {{ organization }}
          </div>
        </div>
      </div>
      <div class="col-md-5 col-12">
        <div class="user-information__wrapper">
          <div class="user-information__title">
            VÖEN:
          </div>
          <div class="user-information__description">
            {{ tin }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./user-information.component.ts"></script>