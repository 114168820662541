export class Signed {
  isSigned: boolean = false;
  mimeType: string | null = null;
  name: string | null = null;
  base64: string | null = null;
  binary: Blob | null = null;
  constructor(data: any) {
    if (data.file) {
      this.isSigned = true;
      this.base64 = data.file;
      this.name = data.name;
      this.binary = data.binary;
      this.mimeType = data.mimeType;
    } else {
      this.isSigned = false;
      this.name = null;
      this.base64 = null;
      this.binary = null;
      this.mimeType = null;
      console.error('Imzalanma baş vermədi!')
    }
  }
}
