import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import InputValueComponent from '@/pages/calculator/components/input-value/input-value.component.vue';
import {store} from '@/store';
import Datepicker from 'vue3-datepicker';
import {az} from 'date-fns/locale';
import {ISelectCalculator} from '@/pages/calculator/types/select-calculator';
import {loginStateMutations} from '@/pages/login/store/store/mutations';

@Options({
    name: 'tern-common-component',
    props: {
        termInfo: {
            type: Object,
            require: true
        },
        selectBandwidthFrequencyNominal: {
            type: Array,
            require: true
        },
        selectGeographicalAreas: {
            type: Array,
            require: true
        },
        selectSEMTechnologyFactor: {
            type: Array,
            require: true
        },
        selectBandSignificances: {
            type: Array,
            require: true
        },
        selectCoefficientSEMTechnology: {
            type: Array,
            require: true
        },
        selectAdjustmentFactor: {
            type: Array,
            require: true
        },
        type: Array,
        require: {
            type: Array,
            require: true
        },
        dateTern: {
            type: Array,
            require: true
        },
        radioFrequencyMultiplier: {
            type: Array,
            require: true
        },
        radioFrequenciesTypes: {
            type: Array,
            require: true
        },
        errorTern: {
            type: Object,
            require: true
        }
    },
    components: {
        InputValueComponent,
        Datepicker
    },
    watch: {
        endDate: [
            {
                handler: 'changeEndDateTern',
                immediate: false,
                deep: false,
            },
        ],
        startDate: [
            {
                handler: 'changeStartDateTern',
                immediate: false,
                deep: false,
            },
        ],
    }
})

export default class TernCommonComponent extends Vue {
    public radioFrequencyMultiplier: ISelectCalculator[] = [];
    private store = store;
    public dataLang = az;
    public startDate: Date | null = null;
    public endDate: Date | null = null;
    public termInfo = {
        userGroup: {
            id: null, value: null, mean: null
        },
        serviceType: {
            id: null, value: null, mean: null
        },
        frequenciesCount: null,
        bandwidthFrequencyNominal: {
            id: null, value: null, mean: null
        },
        geographicalAreas: {
            id: null, value: null, mean: null
        },
        date: {
            id: null, value: null, mean: null
        },
        adjustmentFactor: {
            id: null, value: null, mean: null
        },
        bandSignificances: {
            id: null, value: null, mean: null
        },
        coefficientSEMTechnology: {
            id: null, value: null, mean: null
        },
        broadcastCoverageArea: null,
        SEMTechnologyFactor: {
            id: null, value: null, mean: null
        },
        radioFrequencyMultiplier: null,
        radioFrequencyChannels: {
            id: 1, value: 'RRX (dupleks)', mean: null, factor: '1'
        }
    };

    public selectBandwidthFrequencyNominalValue(info: any) {
        let payload = {
            type: 'bandwidthFrequencyNominal',
            value: info
        };
        this.store.commit('SET_TERN_INFO', payload);

    }

    public changeFrequenciesProperty($event: any, data: number) {
        let payload = {
            type: '',
            value: Number($event.replace(/[^\d]/g, ''))
        };
        if (data === 1) {
            payload.type = 'frequenciesPropertyFirst';
        } else {
            payload.type = 'frequenciesPropertySecond';
        }
        this.store.commit('SET_TERN_INFO', payload);

    }

    public selectRadioFrequenciesTypesValue(info: any) {
        let payload = {
            type: 'radioFrequencyChannels',
            value: info
        };
        this.store.commit('SET_TERN_INFO', payload);
        if (this.termInfo.frequenciesCount) {
            this.changeFrequenciesCount(this.termInfo.frequenciesCount, 6);
        }
    }

    public selectGeographicalAreasValue(info: any) {
        let payload = {
            type: 'geographicalAreas',
            value: info
        };
        this.store.commit('SET_TERN_INFO', payload);
    }

    public selectCoefficientSEMTechnologyValue(info: any) {
        let payload = {
            type: 'coefficientSEMTechnology',
            value: info
        };
        this.store.commit('SET_TERN_INFO', payload);
    }

    public changeBroadcastCoverageArea($event: any) {
        let value = $event.replace(/[^\d\.]/g, '').slice(0, 4);
        if (value[0] === '.') {
            value = '0.';
        }
        if (value.match(/\./g)?.length > 1) {
            value = value.substr(0, value.lastIndexOf('.'));
        }
        let payload = {
            type: 'broadcastCoverageArea',
            value: value
        };
        this.store.commit('SET_TERN_INFO', payload);
    }

    public changeSEMTechnologyFactor($event: any, serviceTypeId: number) {
        let data = $event.replace(/[^\d\.]/g, '');
        if (data[0] === '.') {
            data = '0.';
        }
        if (data.match(/\./g)?.length > 1) {
            data = data.substr(0, data.lastIndexOf('.'));
        }
        let payload = {
            type: 'mean',
            parent: 'SEMTechnologyFactor',
            value: ''
        };
        if (serviceTypeId === 1) {
            if (data <= 3000) {
                payload.value = '10';
            }
            if (3000 < data && data <= 9000) {
                payload.value = '13';
            }
            if (data > 9000) {
                payload.value = '16';
            }
        }
        if (serviceTypeId === 2) {
            if (data <= 3000) {
                payload.value = '5';
            }
            if (3000 < data && data <= 9000) {
                payload.value = '7';
            }
            if (data > 9000) {
                payload.value = '9';
            }
        }
        if (serviceTypeId === 3) {
            if (data <= 10000) {
                payload.value = '1000';
            } else {
                payload.value = '1200';
            }
        }
        if (serviceTypeId === 4) {
            if (data <= 200) {
                payload.value = '1.5';
            }
            if (data > 200 && data <= 800) {
                payload.value = '2';
            }
            if (data > 800 && data <= 2500) {
                payload.value = '2.5';
            }
            if (data > 2500 && data <= 4000) {
                payload.value = '4.5';
            }
            if (data > 4000) {
                payload.value = '6';
            }
        }
        if (serviceTypeId === 7 || serviceTypeId === 9) {
            if (data <= 100) {
                payload.value = '1';
            }
            if (100 < data && data <= 1000) {
                payload.value = '2';
            }
            if (data > 1000) {
                payload.value = '3';
            }
        }
        if (serviceTypeId === 24) {
            if (data <= 100) {
                payload.value = '2';
            }
            if (100 < data && data <= 1000) {
                payload.value = '3';
            }
            if (data > 1000) {
                payload.value = '5';
            }
        }
        if (serviceTypeId === 8) {
            if (data <= 100) {
                payload.value = '10';
            }
            if (100 < data && data <= 1000) {
                payload.value = '20';
            }
            if (data > 1000) {
                payload.value = '30';
            }
        }
        this.store.commit('SET_TERN_INFO_PROPERTY', payload);
        this.store.commit('SET_TERN_INFO', {
            value: data,
            type: 'SEMTechnologyFactorValue',
        });
    }

    public selectBandSignificancesValue(info: any) {
        let payload = {
            type: 'bandSignificances',
            value: info
        };
        this.store.commit('SET_TERN_INFO', payload);
    }

    public selectSEMTechnologyFactorValue(info: any) {
        let payload = {
            type: 'SEMTechnologyFactor',
            value: info
        };
        this.store.commit('SET_TERN_INFO', payload);
    }

    public selectDateTern(info: any) {
        let payload = {
            type: 'date',
            value: info
        };
        this.store.commit('SET_TERN_INFO', payload);
    }

    public selectAdjustmentFactorValue(info: any) {
        let payload = {
            type: 'adjustmentFactor',
            value: info
        };
        this.store.commit('SET_TERN_INFO', payload);
    }

    public senFrequenciesCount(data: any, factor: number) {
        this.store.commit('SET_TERN_INFO', {
            type: 'frequenciesCount',
            value: data.replace(/[^\d]/g, '').slice(0, 3)
        });
        this.store.commit('SET_TERN_INFO', {
            type: 'frequenciesCountMean',
            value: String(Number(data.replace(/[^\d]/g, '').slice(0, 3)) * Number(factor))
        });
    }


    public changeFrequenciesCount($event: any, serviceTypeId: number) {
        let payload = {
            type: '',
            value: null
        };
        if (serviceTypeId === 6) {
            this.senFrequenciesCount($event, Number(this.termInfo.radioFrequencyChannels.factor));
        } else {
            this.radioFrequencyMultiplier.forEach((item: any) => {
                item.typesIds.forEach((typeItem: number) => {
                    if (typeItem === serviceTypeId) {
                        this.senFrequenciesCount($event, item.factor);

                    }
                });
            });
        }
    }

    public changeEndDateTern(val: any) {
        let payload = {
            startDate: this.startDate,
            endDate: val,
            mean: ''
        };
        if (this.startDate !== null) {
            if (this.diffDates(this.startDate, this.endDate) > 0) {
                payload.mean = String(this.diffDates(this.startDate, this.endDate));
            } else {
                this.endDate = null;
            }
        }

        this.store.commit('SET_TERN_INFO_DATE', payload);

    }

    public changeStartDateTern(val: any) {
        let payload = {
            startDate: val,
            endDate: this.endDate,
            mean: ''
        };
        if (this.endDate !== null) {
            payload.mean = String(this.diffDates(this.startDate, this.endDate));
        }
        this.store.commit('SET_TERN_INFO_DATE', payload);
    }

    public diffDates(dateStart: any, dateEnd: any) {
        return ((dateEnd - dateStart) / (60 * 60 * 24 * 1000)) / 365;
    }
}
