export const hosts = {
  signV2Raw: 'http://localhost:18230/',
  signV2: 'http://localhost:18230/api/v1/signer/',
  signV1: 'https://localhost:10281/api/sign/',
}
export const apis = {
  checkV2: hosts.signV2Raw + 'version',
  checkV1: hosts.signV1 + 'version',
  certificates: hosts.signV1 + 'certificates',
  certificatesV2: hosts.signV2 + 'readcertificatesfromstore',
  tsa: hosts.signV2 + 'tsaclients',
  status: hosts.signV1 + 'status',
  signV1: hosts.signV1 + 'localy',
  signV2: hosts.signV2 + 'fullsign',
}
