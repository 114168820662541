import 'reflect-metadata';
import {locale} from '@/assets/db/db.conf.ts';
import {Options, Vue} from 'vue-class-component';
import ESignatureFileComponent
    from '@/pages/record-keeping-service/components/e-signature-file/e-signature-file.component.vue';
import FileInputComponent from '@/core/shared/file-input/file-input.component.vue';
import Datepicker from 'vue3-datepicker';
import UserInformationComponent
    from '@/pages/record-keeping-service/components/user-information/user-information.component.vue';
import UserFormComponent from '@/pages/record-keeping-service/components/user-form/user-form.component.vue';
import {PayloadForm} from '@/pages/permission-movement-service/types/payload-form';
import StatusComponent from '@/core/shared/status/status.component.vue';
import StatusInfoComponent from '@/pages/record-keeping-service/components/status-info/status-info.component.vue';
import CardInfoComponent from '@/core/shared/card-info/card-info.component.vue';
import CancelInfoComponent from '@/pages/record-keeping-service/components/cancel-info/cancel-info.component.vue';

@Options({
    name: 'form-appeal-component',
    props: {
        userForm: {
            type: Object,
            require: true
        },
        applicantActivityAddress: {
            type: Array,
            require: true
        },
        applicantTypeActivities: {
            type: Array,
            require: true
        },
        applicantTypesTelecommunicationsService: {
            type: Array,
            require: true
        },
        applicantScopeActivity: {
            type: Array,
            require: true
        },
        signBtnDisabled: {
            type: Boolean,
            default: true
        },
        sendBtnDisabled: {
            type: Boolean,
            default: true
        },
        userData: {
            type: Object
        },
        ip4: {
            type: String
        },
        ip6: {
            type: String
        }
    },
    components: {
        UserFormComponent,
        ESignatureFileComponent,
        FileInputComponent,
        Datepicker,
        UserInformationComponent,
        StatusComponent,
        StatusInfoComponent,
        CardInfoComponent,
        CancelInfoComponent
    },
    emits: ['changedFormData', 'onSingFiles', 'onNewForm']
})

export default class FormAppealComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;

    public mounted() {
        this.locale$.subscribe((res: any) => {
            if (res && res.elements) {
                this.locale = res.elements;
            }
        });
    }

    public onSingFiles() {
        this.$emit('onSingFiles');
    }

    public resetForm() {
        this.$emit('onNewForm');
    }

    public changedFormData(info: PayloadForm) {
        this.$emit('changedFormData', info);
    }

    public sendData() {
        this.$emit('sendData');
    }

    public setStatus(statusId: any) {
        if (statusId === 1) {
            return 'orange';
        }
        if (statusId === 2) {
            return 'green';
        }
        if (statusId === 3) {
            return 'red';
        }
        if (statusId === 4) {
            return 'orange';
        }
    }


}
