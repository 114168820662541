<style src="./personal-information-form.component.scss" lang="scss"></style>

<template>
  <div class="personal-information-form">
    <form class="form">
      <div class="row">
        <div class="col-12 d-none d-lg-block">
          <v-divider class="mt-30 mb-50"/>
        </div>
        <div class="col-12 form__title  mb-30"
             v-if="((serviceType===3 || ( this.organizationLegalFormValue===4)) && legalPersonCarrier) || serviceType===4">
          Müraciətçi məlumatları
        </div>
        <div class="col-12 col-lg-4 password-margin" v-if="!userLogin">
          <div class="row">
            <div class="col-4 col-md-4">
              <div class="form__group v-select-mobile">
                <label class="v-label"> {{ locale.series }}</label>
                <v-select
                    :allData="true"
                    :error="errorPersonalInformation.documentTypeNumber "
                    @select="selectedDocumentType"
                    :options="documentSeriesType"
                    :value="personalInformation.documentTypeNumber?.id"
                />
              </div>
            </div>
            <div class="col-8 col-md-8 pl-0">
              <div class="form__group">
                <label
                    v-if="citizenshipValue===1"
                    class="v-label">{{ locale.passportNumber }}</label>
                <label
                    v-else
                    class="v-label">{{ locale.passwordName }}</label>
                <input
                    :class="{'v-input--error':errorPersonalInformation.documentNumber || documentTypeError}"
                    v-model="personalInformation.documentNumber"
                    @input="documentNumberChanged($event.target.value, 'documentNumber')"
                    name="documentNumber"
                    type="text"
                    class="v-input" :placeholder="locale.passportNumberPlaceholder">
                <div class="form__group-info--error" v-if=" documentTypeError">Məlumat tam daxil edilməmişdir</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4" v-if="!userLogin">
          <div class="form__group">
            <label class="v-label">{{ locale.fin }}</label>
            <input
                name="fin"
                :class="{'v-input--error':errorPersonalInformation.fin || finError}"
                placeholder="1234ABC"
                @input="finChanged($event.target.value)" type="text"
                class="v-input" v-model="personalInformation.fin">
            <div class="form__group-info--error" v-if=" finError">Məlumat tam daxil edilməmişdir</div>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.phoneNumber }}</label>
            <input
                name="phone"
                :class="{'v-input--error':errorPersonalInformation.phone}"
                @keyup="deleteNumber($event, 'phone')"
                @keydown="deleteNumber($event,'phone')"
                v-mask="['+994(##)-###-##-##', '+994(##)-###-##-##']"
                @input="phoneChanged($event.target.value, 'phone')" type="text"
                class="v-input" v-model="personalInformation.phone">
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.mail }}</label>
            <input
                name="email"
                :class="{'v-input--error':errorPersonalInformation.email}"
                :placeholder="locale.emailOrganizationPlaceholder"
                @input="emailChanged($event.target.value)" type="text"
                class="v-input" v-model="personalInformation.email">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20" v-if="organizationLegalFormValue!==1">
          <div class="form__group">
            <label class="v-label">{{ locale.tin }}</label>
            <input
                name="tin"
                v-model="tinValue"
                :class="{'v-input--error':errorPersonalInformation.tin || tinError }"
                :placeholder="locale.tinPlaceholder"
                @input="setTin($event.target.value, 'tin')" type="text"
                class="v-input">
            <div class="form__group-info--error" v-if="tinError">Məlumat düzgün daxil edilməmişdir</div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20" v-if="organizationLegalFormValue!==1">
          <div class="form__group">
            <label class="v-label" v-if="serviceType!==4 & serviceType!==3">{{ locale.nameOrganization }}</label>
            <label class="v-label" v-else-if="organizationLegalFormValue===4">Təşkilatın adı</label>
            <label class="v-label" v-else-if="organizationLegalFormValue===3">Sahibkarın adı</label>
            <input

                :data-title="organizationName"
                disabled
                :class="{'v-input--error':errorPersonalInformation.organization}"
                :placeholder="locale.nameOrganizationPlaceholder"
                v-model="organizationNameValue"
                type="text"
                class="v-input">
            <div class="input-title">
              {{ organizationName }}
            </div>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20"
             v-if="serviceType ===4 && (organizationLegalFormValue===3 )"
        >
          <div class="form__group">
            <label class="v-label">Ünvan</label>
            <input
                placeholder="Küçə"
                :class="{'v-input--error':errorPersonalInformation.address}"
                @input="setPersonalInformation($event.target.value, 'address')" type="text"
                class="v-input" v-model="personalInformation.address">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20"
             v-if="(organizationLegalFormValue!==1 && (serviceType ===3 && (organizationLegalFormValue===4 && legalPersonCarrier)) || (serviceType !==3 && serviceType!==4 && organizationLegalFormValue!==1)) || (serviceType===4 && this.organizationLegalFormValue===4 && this.legalPersonCarrier)"
        >
          <div class="form__group">
            <label class="v-label">{{ locale.position }}</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.position}"
                @input="setPersonalInformation($event.target.value, 'position')" type="text"
                class="v-input" v-model="personalInformation.position">
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20"
             v-if="(serviceType === 4 && organizationLegalFormValue===4 && !legalPersonCarrier)"
        >
          <div class="form__group">
            <label class="v-label">Hüquqi şəxsin vəzifəsi</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.positionLegalEntity}"
                @input="setPersonalInformation($event.target.value, 'positionLegalEntity')" type="text"
                class="v-input" v-model="personalInformation.positionLegalEntity">
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20"
             v-if="organizationLegalFormValue!==1 &&
              (serviceType === 3 && organizationLegalFormValue!==4 && certificateStatus!==2)
             ||( serviceType === 4 && organizationLegalFormValue===4 && !legalPersonCarrier)"
        >
          <div class="form__group">
            <label class="v-label">Hüquqi ünvan</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.legalEntityAddress}"
                @input="setPersonalInformation($event.target.value, 'legalEntityAddress')" type="text"
                class="v-input" v-model="personalInformation.legalEntityAddress">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20" v-if="organizationLegalFormValue!==1 && serviceType===1">
          <div class="form__group d-flex">
            <input
                id="registration"
                @input="setPersonalInformation($event.target.checked, 'registration')" type="checkbox"
                class="v-checkbox v-checkbox--dark-blue v-checkbox--square mr-12"
                v-model="personalInformation.registration">

            <label class="v-label  mt-5 v-label-registration" for="registration" v-if="!req">DRİ-də REV-in
              qeydiyyatı</label>
            <label class="v-label  mt-5 v-label-registration" for="registration" v-if="req">DRİ ilə müqavilə
              mövcuddur</label>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20"
             v-if="((serviceType===3 && certificateCheck) || serviceType===4)  && filesForForCertificate?.length!==0">
          <template v-for="item in filesForForCertificate">
            <template
                v-if="(item.name === 'poweroftheattorneydoc' && serviceType===3) || (serviceType===4 && item.name === 'powerofattorney')">
              <div class="form__group">
                <label class="v-label">{{ item.label }}</label>
                <file-input-component
                    :multiple="item.uploadLimit>1"
                    :limit="item.uploadLimit"
                    :error="errorPersonalInformation.poweroftheattorneydoc"
                    :fileNameStore="personalInformation.poweroftheattorneydoc"
                    @file="(i)=> selectFile(i,'poweroftheattorneydoc', item )"/>
              </div>
            </template>
            <template v-if="item.name === 'govregistercopy' && !legalPersonCarrier">
              <div class="form__group">
                <label class="v-label">{{ item.label }}</label>
                <file-input-component
                    :multiple="item.uploadLimit>1"
                    :limit="item.uploadLimit"
                    :error="errorPersonalInformation.govregistercopy"
                    :fileNameStore="personalInformation.govregistercopy"
                    @file="(i)=> selectFile(i,'govregistercopy', item )"/>
              </div>
            </template>
          </template>
        </div>
        <div class="w-100"></div>
        <div class="col-12"
             v-if="(((serviceType ===3 && certificateStatus===1 && legalPersonCarrier) || (serviceType ===4  && legalPersonCarrier)) && (organizationLegalFormValue===4 ))">
          <div class="row">
            <div class="col-12 form__title  mt-50">
              Hüquqi şəxs üzrə məlumatlar
            </div>
            <div class="col-12 col-lg-4 mt-20">
              <div class="form__group">
                <label class="v-label">Hüquqi şəxsin adı və soyadı</label>
                <input
                    :class="{'v-input--error':errorPersonalInformation.legalEntityFullName}"
                    @input="setPersonalInformation($event.target.value, 'legalEntityFullName')"
                    v-model="personalInformation.legalEntityFullName"
                    type="text"
                    class="v-input">
              </div>
            </div>
            <div class="col-12 col-lg-4 mt-20">
              <div class="form__group">
                <label class="v-label">Hüquqi şəxsin vəzifəsi</label>
                <input
                    :class="{'v-input--error':errorPersonalInformation.positionLegalEntity}"
                    @input="setPersonalInformation($event.target.value, 'positionLegalEntity')"
                    v-model="personalInformation.positionLegalEntity"
                    type="text"
                    class="v-input">
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-12 col-lg-4 mt-20" v-if="serviceType===4">
              <div class="form__group">
                <label class="v-label">Hüquqi ünvan</label>
                <input
                    :class="{'v-input--error':errorPersonalInformation.addressLegalEntity}"
                    @input="setPersonalInformation($event.target.value, 'addressLegalEntity')"
                    v-model="personalInformation.addressLegalEntity"
                    type="text"
                    class="v-input">
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-12 col-lg-4 mt-20" v-if="(serviceType===4 || serviceType===3) && legalPersonCarrier">
            <template v-for="item in filesForForCertificate">
                <template v-if="item.name === 'govregistercopy'">
                  <div class="form__group">
                    <label class="v-label">{{ item.label }}</label>
                    <file-input-component
                        :multiple="item.uploadLimit>1"
                        :limit="item.uploadLimit"
                        :error="errorPersonalInformation.copyExtractFile"
                        :fileNameStore="personalInformation.copyExtractFile"
                        @file="(i)=> selectFile(i,'copyExtractFile', item )"/>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./personal-information-form.component.ts"></script>
