<style src="./ip-input.component.scss" lang="scss"></style>

<template>
  <div class="ip-input">
    <input
        :style="{'width':`${widthFirst}px`}"
        :disabled="disabled"
        type="text"
        :class="{'ip-input__error':error.firstInput}"
        ref="firstInputRef"
        v-model="firstInput"
        @input="validation($event.target.value,'firstInput')"
        @keydown="moveCursor($event, 'firstInput')"
        @keyup="deleteNumber($event, 'firstInput') , moveCursor($event, 'firstInput')"
    >
    <span>.</span>
    <input
        :style="{'width':`${widthSecond}px`}"
        type="text"
        :disabled="disabled"
        :class="{'ip-input__error':error.secondInput}"
        ref="secondInputRef"
        v-model="secondInput"
        @keydown="moveCursor($event, 'secondInput')"
        @keyup="deleteNumber($event, 'secondInput') , moveCursor($event, 'secondInput')"
        @input="validation($event.target.value,'secondInput')">
    <span>.</span>
    <input
        :style="{'width':`${widthThird}px`}"
        :disabled="disabled"
        :class="{'ip-input__error':error.thirdInput}"
        ref="thirdInputRef"
        v-model="thirdInput"
        type="text"
        @input="validation($event.target.value,'thirdInput')"
        @keyup="deleteNumber($event, 'thirdInput') , moveCursor($event, 'thirdInput')"
        @keydown="moveCursor($event, 'thirdInput')"
    >
    <span>.</span>
    <input
        :style="{'width':`${widthFourth}px`}"
        :disabled="disabled"
        :class="{'ip-input__error':error.fourthInput}"
        class="last-input"
        ref="fourthInputRef"
        v-model="fourthInput"
        @change="onCheckAddSeparator($event,'fourthInput')"
        type="text" @input="validation($event,'fourthInput')"
        @keydown="moveCursor($event, 'fourthInput')"
        @keyup="deleteNumber($event, 'fourthInput'), moveCursor($event, 'fourthInput')"
    >
  </div>
</template>

<script src="./ip-input.component.ts"></script>
