<style src="./user-form.component.scss" lang="scss"></style>

<template>
  <div class="user-form mt-50">
    <form action="" class="form">
      <div class="row">
        <div class="col-12 mb-30">
          <div class="user-form__sub" v-if="entity===1">{{ locale.informationLegalEntity }}</div>
          <div class="user-form__sub" v-else>{{ locale.individualEntity }}</div>
        </div>
        <div class="col-12 col-lg-4" v-if="entity===1">
          <div class="form__group">
            <label for="legalAddress" class="v-label">{{ locale.legalAddress }}</label>
            <input
                :disabled="userForm.disabled"
                :class="{'v-input-error':error.legalAddress}"
                v-model="userForm.juridicalAddress"
                @input="onChangedInput($event.target.value, 'juridicalAddress')"
                :placeholder="locale.insert" id="legalAddress" type="text" class="v-input">
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form__group">
            <label for="activityAddress" class="v-label">{{ locale.activityAddress }}</label>
            <input
                :disabled="userForm.disabled"
                :class="{'v-input-error':error.activityAddress}"
                v-model="userForm.activityAddress"
                @input="onChangedInput($event.target.value, 'activityAddress')"
                :placeholder="locale.insert" id="activityAddress" type="text" class="v-input">
          </div>
        </div>
        <div class="col-12 col-lg-4" v-if="entity===2">
          <div class="form__group">
            <label for="activityAddress" class="v-label">{{ locale.dutyNaturalPerson }}</label>
            <input
                :disabled="userForm.disabled"
                :class="{'v-input-error':error.dutyNaturalPerson}"
                v-model="userForm.dutyNaturalPerson"
                @input="onChangedInput($event.target.value, 'dutyNaturalPerson')"
                :placeholder="locale.insert" id="dutyNaturalPerson" type="text" class="v-input">
          </div>
        </div>
        <div class="col-12 my-30" v-if="entity===1">
          <div class="form__group d-flex align-center">
            <input
                :disabled="userForm.disabled"
                :class="{'v-input-error':error.activityAddress}"
                @change="onChangedInput($event.target.checked, 'ownerApplicantSame')" id="ownerApplicantSame"
                type="checkbox" class="v-checkbox v-checkbox--dark-blue v-checkbox--square">
            <label for="ownerApplicantSame" class="v-label pl-12 mb-0">{{ locale.ownerApplicantSame }}</label>
          </div>
        </div>
        <div class="col-12  mb-50" v-if="entity===1 && userForm.ownerApplicantSame">
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="form__group">
                <label for="nameLegalEntity" class="v-label">{{ locale.nameLegalEntity }}</label>
                <input
                    :disabled="userForm.disabled"
                    v-model="userForm.juridicalPersonFullName"
                    @input="onChangedInput($event.target.value, 'juridicalPersonFullName')"
                    :placeholder="locale.insert" id="nameLegalEntity" type="text" class="v-input">
              </div>
            </div>
            <div class="col-12 col-lg-4 ">
              <div class="form__group">
                <label for="dutyLegalEntity" class="v-label">{{ locale.dutyLegalEntity }}</label>
                <input
                    :disabled="userForm.disabled"
                    :class="{'v-input-error':error.dutyLegalEntity}"
                    v-model="userForm.position"
                    @input="onChangedInput($event.target.value, 'position')"
                    id="dutyLegalEntity" :placeholder="locale.insert" type="text" class="v-input">
              </div>
            </div>
          </div>
        </div>

        <!--        appeal-->
        <div class="col-12 mb-30">
          <div class="user-form__sub">{{ locale.applicationInformation }}</div>
        </div>
        <!--        <div class="col-12 col-lg-4">-->
        <!--          <div class="form__group">-->
        <!--            <label class="v-label">{{ locale.activityAddress }}</label>-->
        <!--            <v-select-->
        <!--                :error="error.applicantActivityAddress"-->
        <!--                ref="activityAddress"-->
        <!--                :allData="true"-->
        <!--                placeholder="Seçin"-->
        <!--                @select="(i)=> {onChangedInput(i, 'applicantActivityAddress')}"-->
        <!--                :options="applicantActivityAddress"-->
        <!--                :value="userForm.applicantActivityAddress?.id"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-12 col-lg-4 mb-30">
          <div class="form__group">
            <label for="internetAddress" class="v-label">{{ locale.internetAddress }}</label>
            <input
                :disabled="userForm.disabled"
                :class="{'v-input--error':error.applicantInternetAddress}"
                v-model="userForm.applicantInternetAddress"
                @input="onChangedInput($event.target.value, 'applicantInternetAddress')"
                id="internetAddress" :placeholder="locale.insertLink" type="text" class="v-input">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4">
          <div class="form__group">
            <label for="applicantTypeActivities" class="v-label">{{ locale.typeActivitie }}</label>
            <multiple-component
                :disabled="userForm.disabled"
                info="Bir və ya bir neçə məlumat seçilə bilər"
                :error="error.applicantTypeActivities"
                :selected="userForm.applicantTypeActivities"
                :resultPositionInInput="false"
                :placeholder="locale.select"
                :options="applicantTypeActivities"
                @selectItem="(i)=>{onChangedInput(i,'applicantTypeActivities')}"/>
          </div>
        </div>
        <div class="col-12 mt-12">
          <div class="d-flex">
            <template v-for="item in applicantTypeActivities">
              <template v-for="itemSelect in userForm.applicantTypeActivities">
                {{ itemSelect.value }}
                <tag-component
                    :deleteCheck="!userForm.disabled"
                    class="mr-10"
                    v-if="itemSelect === item.id"
                    :itemId="itemSelect"
                    @deleteItem="(i)=>{onDeleteMultipleItem(i,'applicantTypeActivities')}"
                >
                  {{ item.value }}
                </tag-component>
              </template>
            </template>
          </div>
        </div>
        <div class="w-100 mb-30 mb-30"></div>
        <div class="col-12 col-lg-4 ">
          <div class="form__group">
            <label for="typesTelecommunicationsService" class="v-label">{{
                locale.typesTelecommunicationsService
              }}</label>
            <multiple-component
                :disabled="userForm.disabled"
                :error="error.applicantTypesTelecommunicationsService"
                :selected="userForm.applicantTypesTelecommunicationsService"
                :resultPositionInInput="false"
                :placeholder="locale.select"
                :options="applicantTypesTelecommunicationsService"
                @selectItem="(i)=>{onChangedInput(i,'applicantTypesTelecommunicationsService')}"/>
          </div>
        </div>
        <div class="col-12 mt-12">
          <div class="d-flex">
            <template v-for="item in applicantTypesTelecommunicationsService">
              <template v-for="itemSelect in userForm.applicantTypesTelecommunicationsService">
                {{ itemSelect.value }}
                <tag-component
                    :deleteCheck="!userForm.disabled"
                    class="mr-10"
                    v-if="itemSelect === item.id"
                    :itemId="itemSelect"
                    @deleteItem="(i)=>{onDeleteMultipleItem(i,'applicantTypesTelecommunicationsService')}"
                >
                  {{ item.value }}
                </tag-component>
              </template>
            </template>
          </div>
        </div>
        <div class="w-100 mb-30"></div>
        <div class="col-12 col-lg-4 ">
          <div class="form__group">
            <label for="scopeActivity" class="v-label">{{ locale.scopeActivity }}</label>
            <multiple-component
                :disabled="userForm.disabled"
                info="Bir və ya bir neçə məlumat seçilə bilər"
                :error="error.applicantScopeActivity"
                :selected="userForm.applicantScopeActivity"
                :resultPositionInInput="false"
                :placeholder="locale.select"
                :options="applicantScopeActivity"
                @selectItem="(i)=>{onChangedInput(i,'applicantScopeActivity')}"/>
          </div>
        </div>
        <div class="col-12 mt-12">
          <div class="d-flex">
            <template v-for="item in applicantScopeActivity">
              <template v-for="itemSelect in userForm.applicantScopeActivity">
                {{ itemSelect.value }}
                <tag-component
                    :deleteCheck="!userForm.disabled"
                    class="mr-10"
                    v-if="itemSelect === item.id"
                    :itemId="itemSelect"
                    @deleteItem="(i)=>{onDeleteMultipleItem(i,'applicantScopeActivity')}"
                >
                  {{ item.value }}
                </tag-component>
              </template>
            </template>
          </div>
        </div>
        <div class="w-100 mb-30"></div>
        <div class="col-12 col-lg-4 mb-30">
          <div class="form__group">
            <label for="interval4" class="v-label">{{ locale.interval4 }}</label>
            <ip-wrapper-component
                :ips="ip4"
                :disabled="userForm.disabled"
                @selectedIps="(i)=>{onChangedInput(i, 'iPvFour')}"
            />
            <!--            <input-->
            <!--                :class="{'v-input-error':error.applicantInterval4}"-->
            <!--                v-model="userForm.applicantInterval4"-->
            <!--                @input="onChangedInput($event.target.value, 'applicantInterval4')"-->
            <!--                v-mask="['###.###.###','##.##.##', '#.#.#']"-->
            <!--                id="interval4" placeholder="10.10.10.10"-->
            <!--                type="text" class="v-input">-->
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-30">
          <div class="form__group">
            <label for="interval6" class="v-label">{{ locale.interval6 }}</label>
            <ip-wrapper-component
                :ips="ip6"
                :disabled="userForm.disabled"
                @selectedIps="(i)=>{onChangedInput(i, 'iPvSix')}"/>
            <!--            <input-->
            <!--                v-mask="['###.###.###']"-->
            <!--                :class="{'v-input-error':error.applicantInterval6}"-->
            <!--                v-model="userForm.applicantInterval6"-->
            <!--                @input="onChangedInput($event.target.value, 'applicantInterval6')"-->
            <!--                id="interval6" placeholder="8.8.8.8" type="text" class="v-input">-->
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mb-30">
          <div class="form__group" :class="{'form__group-error':error.applicantDate}">
            <label for="date" class="v-label">{{ locale.date }}</label>
            <datepicker
                :disabled="userForm.disabled"
                v-model="userForm.serviceDate"
                :placeholder="locale.placeholderDate"
                id="date"
                :locale="dataLang"
            />
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-30">
          <div class="form__group">
            <label for="autonomousSystem" class="v-label">{{ locale.autonomousSystem }}</label>
            <input
                :disabled="userForm.disabled"
                v-mask="['!A!S###################################################']"
                :class="{'v-input-error':error.applicationAutonomousSystem}"
                v-model="userForm.asNumber"
                @input="onChangedInput($event.target.value, 'asNumber')"
                :placeholder="locale.placeholderNumber" id="autonomousSystem" type="text" class="v-input">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mb-30">
          <div class="form__group">
            <label for="applicantPhoneNumber" class="v-label">{{ locale.applicantPhoneNumber }}</label>
            <input
                :disabled="userForm.disabled"
                @keyup="deleteNumber($event, 'applicantPhoneNumber')"
                @keydown="deleteNumber($event,'applicantPhoneNumber')"
                :class="{'v-input--error':error.applicantPhoneNumber}"
                v-model="userForm.applicantPhoneNumber"
                @input="onChangedInput($event.target.value, 'applicantPhoneNumber')"
                placeholder="+994("
                v-mask="['+994(##)-###-##-##', '+994(##)-###-##-##']" id="applicantPhoneNumber" type="text"
                class="v-input">
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-30">
          <div class="form__group">
            <label for="applicantFaxNumber" class="v-label">{{ locale.applicantFaxNumber }}</label>
            <input
                :disabled="userForm.disabled"
                @keyup="deleteNumber($event, 'applicantFaxNumber')"
                @keydown="deleteNumber($event,'applicantFaxNumber')"
                :class="{'v-input--error':error.applicantFaxNumber}"
                v-model="userForm.applicantFaxNumber"
                @input="onChangedInput($event.target.value, 'applicantFaxNumber')"
                placeholder="+994("
                v-mask="['+994(##)-###-##-##', '+994(##)-###-##-##']" id="applicantFaxNumber" type="text"
                class="v-input">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mb-30">
          <div class="form__group">
            <label for="applicantEmail" class="v-label">{{ locale.applicantEmailNumber }}</label>
            <input
                :disabled="userForm.disabled"
                :class="{'v-input--error':error.applicantEmail}"
                v-model="userForm.applicantEmail"
                @input="onChangedInput($event.target.value, 'applicantEmail')"
                :placeholder="locale.insert" id="applicantEmail" type="text" class="v-input">
          </div>
        </div>
        <div class="col-12">
          <v-divider/>
        </div>
        <div class="col-12">
          <div class="user-form__sub my-40">{{ locale.docs }}</div>
        </div>

        <div class="col-12" v-if="!userForm.disabled">
          <div class="row">
            <div class="col-12 mb-30" v-if="entity===1">
              <div class="row">
                <div class="col-12 col-lg-4 mt-4">
                  <label class="v-label">{{ locale.copyCharter }}</label>
                </div>
                <div class="w-100"></div>
                <div class="col-12 col-lg-4 mt-4" v-if="!userForm.docCopyCharter?.name">
                  <file-input-component
                      accept="application/pdf"
                      :viewFiles="false"
                      @file="(i)=>{onChangedInput(i, 'docCopyCharter')}"/>
                </div>
                <div class="col-12 col-lg-4 mt-4" v-else>
                  <e-signature-file-component :fileName="userForm.docCopyCharter?.name"
                                              @onDeleteFile="onDeleteFile('docCopyCharter')"/>
                </div>
              </div>
            </div>
            <div class="col-12 ">
              <div class="row">
                <div class="col-12 col-lg-4">
                  <label for="treaty" class="v-label">{{ locale.treaty }}</label>
                </div>
                <div class="w-100"></div>
                <div class="col-12 col-lg-4 mt-4">
                  <file-input-component
                      accept="application/pdf"
                      :fileNameStoreArr="userForm.docTreaty"
                      :multiple="true"
                      :limit="10"
                      :viewFiles="false"
                      @file="(i)=>{onChangedInput(i, 'docTreaty')}"/>
                </div>
                <div class="col-12 col-lg-4 mt-4" v-for="(item, index) in userForm.docTreaty" :key="index">
                  <e-signature-file-component @onDeleteFile="onDeleteFile('docTreaty',index)" :fileName="item.name"/>
                </div>
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-12 mt-30">
              <div class="row">
                <div class="col-12 col-lg-4 mt-4">
                  <label class="v-label">{{ locale.documentAuthority }}</label>
                </div>
                <div class="w-100"></div>
                <div class="col-12 col-lg-4 mt-4" v-if="!userForm.docDocumentAuthority?.name">
                  <file-input-component
                      accept="application/pdf"
                      :fileNameStore="userForm.docDocumentAuthority"
                      :limit="10"
                      :viewFiles="false"
                      @file="(i)=>{onChangedInput(i, 'docDocumentAuthority')}"/>
                </div>
                <div class="col-12 col-lg-4 mt-4" v-else>
                  <e-signature-file-component
                      @onDeleteFile="onDeleteFile('docDocumentAuthority')"
                      :fileName="userForm.docDocumentAuthority?.name"/>
                </div>
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-12 col-lg-4 my-30">
              <v-btn class="sign-btn p-12" color="dark" :disabled="signBtnDisabled" @click.prevent="onSingFiles">
                Sənədləri
                imzala
              </v-btn>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 " v-if="userForm.signFile?.url">
          <label class="v-label">İmzalanmış sənədlər toplusu</label>
          <download-file-component :fileLink="userForm.signFile.url"/>
        </div>
        <div class="col-12" v-if="!(userForm.appealStatusId===1)">
          <v-divider class="my-40"/>
        </div>
        <div class="col-12 text-center mb-40">
          <v-btn
              @click.prevent="onChangedInput(true, 'disabled'),onChangedInput(false, 'edit')"
              v-if="userForm.edit"
              class=" py-12 px-55 mr-12"
              color="black">
            Geri
          </v-btn>
          <v-btn
              :disabled="sendBtnDisabled"
              v-if="userForm.edit || (userForm.new && !userForm.request) || userForm.appealStatusId===null"
              class=" py-12 px-55"
              color="dark"
              @click.prevent="sendData()">
            Göndər
          </v-btn>
          <v-btn
              v-if=" (userForm.appealStatusId===3 && userForm.disabled)"
              class=" py-12 px-55"
              color="dark"
              @click.prevent="$emit('onNewForm'),onChangedInput(true, 'new')"
          >
            Yeni müraciət
          </v-btn>
          <!--          <v-btn-->
          <!--              @click.prevent="$emit('onCancel')"-->
          <!--              class=" py-12 px-55"-->
          <!--              color="dark-red"-->
          <!--          >-->
          <!--            Ləğv et-->
          <!--          </v-btn>-->
          <!--          <v-btn-->
          <!--              class=" py-12 px-55"-->
          <!--              color="dark"-->
          <!--              @click.prevent="sendData"-->
          <!--          >-->
          <!--            Düzəliş et-->
          <!--          </v-btn>-->
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./user-form.component.ts"></script>
