<style src="./status-info.component.scss" lang="scss"></style>

<template>
  <div class="status-wrapper">
    <div class="status-info d-flex justify-between align-center">
      <div class="status-info__title pt-30 pb-30">{{ title }}</div>
      <div class="status-info__action d-flex ">
        <a :href="link" class="d-block" download v-if="statusColor==='green'">
          <v-btn color="dark" class="d-flex"><span class="mt-3 d-block">Bildirişi yüklə</span><img class="ml-12" src="@/assets/images/icons/download-white.svg" alt=""></v-btn>
        </a>
        <status-component :color="statusColor" class="ml-10">
          {{ statusName }}
        </status-component>
      </div>

    </div>
    <v-divider class="mb-24"/>
  </div>
</template>

<script src="./status-info.component.ts"></script>