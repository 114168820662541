import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import StatusComponent from '@/core/shared/status/status.component.vue';

@Options({
    name: 'status-info-component',
    components: {
        StatusComponent
    },
    props: {
        title: {
            type: String
        },
        statusName: {
            type: String
        },
        statusColor: {
            type: String
        },
        link:{
            type: String
        }
    }
})

export default class StatusInfoComponent extends Vue {

}