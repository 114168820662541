<style src="./registration-radio-electronic-devices-service.component.scss" lang="scss"></style>

<template>
  <div class="registration-radio-electronic-devices mb-100 mt-16">
    <div class="container-fluid v-container h-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20 mb-16">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
        </div>
        <div class="col-md-12">
          <customer-info-component v-if="userData && current>=3" :user="userData"/>
          <customer-info-component v-if="user?.name?.length && current>=2" :user="user"/>
        </div>
        <div class="col-12">
          <v-card>
            <step-registration-radio-component
                @setPersonalInformation="setPersonalInformation"
                @selectTechnology="selectTechnology"
                :errorRedAppealTechnologyId="errorRedAppealTechnologyId"
                :errorFiles="errorFiles"
                :getPostCreateRedAppeal="getPostCreateRedAppeal"
                :fileTypesKeyValues="fileTypesKeyValues"
                :appealTechnologyKeyValues="appealTechnologyKeyValues"
                :organizationName="organizationName"
                @checkTin="checkTin"
                :userLogin="!!user?.pin?.length"
                :documentSeriesType="documentSeriesType"
                :personalInformation="personalInformation"
                :errorPersonalInformation="errorPersonalInformation"
                :stepOneValidation="stepOneValidation"
                :organizationLegalFormValue="organizationLegalFormValue"
                :citizenshipValue="citizenshipValue"
                :legalPersonCarrier="legalPersonCarrier"
                :organizationLegalForm="organizationLegalForm"
                :citizenship="citizenship"
                :step="step"
                :stepDescription="stepDescription"
                :current="current"
            />
            <v-divider class="my-40"/>
            <div class="btn__wrapper d-flex justify-center mt-40">
              <v-btn v-if="current!==1" @click="decrementCurrent" color="white mr-12">{{ locale?.prevStep }}</v-btn>
              <v-btn @click="incrementCurrent" v-if="!(step===current)" color="dark">{{ locale?.nextStep }}</v-btn>
              <v-btn v-if="current === step" color="dark" @click="finallyStep">{{ locale?.complete }}</v-btn>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <v-modal
        @closeModal="closeModal"
        title="Məlumat"
        ref="modal"
    >
      <template v-slot:body>
        <img class="service__img"
             src="@/assets/images/icons/modal-info.svg" alt="">
        <div class="mb-20"> Müraciətiniz qeydə alındı. Təşəkkür edirik!</div>

      </template>
      <template v-slot:footer>
        <div class="modal-btn-group text-center">
          <v-btn @click="closeModal">{{ locale?.close }}
          </v-btn>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script src="./registration-radio-electronic-devices-service.component.ts"></script>