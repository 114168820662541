import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import {IInputPayload} from '@/types/input-payload';

@Options({
    name: 'applicant-form-component',
    props: {
        applicantForm: {
            type: Object,
            required: true
        },
        documentSeriesType: {
            type: Array,
            required: true
        },
        citizenshipValue: {
            type: Number,
            required: true
        }
    }
})

export default class ApplicantFormComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public documentTypeError = false;
    public tinError = false;
    public tinValue = '';
    public pruningFin = 8;
    public organizationNameValue = '';
    public payload: IInputPayload = {
        type: null,
        value: null
    };

    public created() {
        this.locale$.subscribe((res: any) => {
            if (res && res.issuingCertificateConformity) {
                this.locale = res.issuingCertificateConformity;
            }
        });
    }

    public selectedDocumentType(info: ISearchSelect) {
        this.payload = {
            type: 'documentType',
            value: info
        };
        this.$emit('onChangeInput', this.payload);
    }

    public onChangeInput(value: any, type: string) {
        this.payload = {
            type: type,
            value: value
        };
        this.$emit('onChangeInput');
    }

    public changeOrganizationName(val: any) {
        this.organizationNameValue = val;
    }
}