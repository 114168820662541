<style src="./options-form.component.scss" lang="scss"></style>

<template>
  <div class="options-form">
    <form class="form">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="form__group mb-20">
            <label class="v-label">Mülki dövriyyəsi məhdudlaşdırılmış əşyalar</label>
            <v-select
                :allData="true"
                ref="itemsCivilCirculation"
                @select="(i)=>setOptionInformation(i,'restrictedCivilCirculation')"
                :value="options.restrictedCivilCirculation?.id"
                :options="restrictedCirculationItems"/>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4">
          <file-generation-component
              :notShow="['Blank']"
              @onSelectFile="(i)=>$emit('onSelectFile', i)"
              :files="files"/>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4" v-if="options.restrictedCivilCirculation?.id===4">
          <div class="form__group">
            <label class="v-label">Tələb olunan zəruri̇ məlumatlar</label>
            <v-select
                :error="this.errorOptions.requiredCrucialData"
                placeholder="Seçin"
                :allData="true"
                ref="requiredCrucialData"
                @select="(i)=>setOptionInformation(i,'requiredCrucialData')"
                :value="options.requiredCrucialData?.id"
                :options="requiredCrucialData"/>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4" v-if="options.restrictedCivilCirculation?.id===4">
          <file-generation-component
              :notShow="['DocOrgProtection', 'appealform']"
              @onSelectFile="(i)=>$emit('onSelectFile', i)"
              :files="files"/>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./options-form.component.ts"></script>
