export class GetAppealModel {
    public taxIdNumber: string | number | null = null;

    constructor(item: any) {
        this._setTaxIdNumber(item);
    }

    private _setTaxIdNumber(item: any) {
        this.taxIdNumber = item;
    }
}