import {IApplicantDto} from '@/pages/issuing-certificate-conformity-service/types/get-cert-appeal';
import {IApplicantCommonForm} from '@/types/applicant-form';
import {IFileType} from '@/pages/issuing-certificate-conformity-service/types/file-type';

export class CertApplicantFormModel {
    public sxemId: number | null = null;
    public appealTypeId: number | null = null;
    public isApplicantDifferent: boolean | null = null;
    public appealFileDtos: any[] | null = null;
    public applicantDto: IApplicantDto | null = null;


    constructor(sxemId: number | null,
                appealTypeId: number,
                isApplicantDifferent: boolean,
                files: IFileType[],
                applicantInfo: IApplicantCommonForm,
                citizenShipId: number,
                applicantTypeId: number
    ) {
        this._setSxemId(sxemId, citizenShipId);
        this._setAppealTypeId(appealTypeId, citizenShipId);
        this._setIsApplicantDifferent(isApplicantDifferent);
        this._setAppealFileDtos(files);
        this._setApplicantDto(applicantInfo, applicantTypeId, citizenShipId);
    }

    private _setSxemId(sxemId: number | null, citizenShipId: number) {
        if (citizenShipId === 3) {
            this.sxemId = 6;
        } else {
            this.sxemId = sxemId;
        }
    }

    private _setAppealTypeId(appealTypeId: number, citizenShipId: number) {
        if (citizenShipId === 3) {
            this.appealTypeId = 6;
        } else {
            this.appealTypeId = appealTypeId;
        }
    }

    private _setIsApplicantDifferent(isApplicantDifferent: boolean) {
        this.isApplicantDifferent = isApplicantDifferent;
    }

    private _setAppealFileDtos(files: IFileType[]) {
        this.appealFileDtos = files.map((item) => {
            return {
                appealFileTypeId: item.id,
                file: item?.file ? item?.file : ''

            };
        });
    }

    private _setApplicantDto(applicantInfo: IApplicantCommonForm, applicantTypeId: any, citizenShipId: number) {
        this.applicantDto = new ApplicantDto(applicantInfo, applicantTypeId, citizenShipId);
    }
}

export class ApplicantDto {
    public applicantTypeId: number | null = null;
    public documentTypeGroupId?: number | null = null;
    public pin?: string | null = null;
    public documentNumber?: string | null = null;
    public documentSerial?: string | null = null;
    public tin?: string | null = null;
    public phone: string | null = null;
    public email: string | null = null;
    public organizationName?: string | null = null;
    public position?: string | null = null;
    public juridicalAddress?: string | null = null;
    public ownerName?: string | null = null;
    public ownerSurname?: string | null = null;
    public juridicalPersonFullName?: string | null = null;
    public juridicalPersonPosition?: string | null = null;
    public citizenShipId: string | null = null;

    constructor(data: IApplicantCommonForm,
                applicantTypeId: number,
                citizenShipId: number
    ) {
        this._setApplicantTypeId(applicantTypeId);
        this._setDocumentTypeGroupId(citizenShipId);
        this._setPin(data);
        this._setDocumentNumber(data);
        this._setDocumentSerial(data);
        this._setTin(data);
        this._setPhone(data);
        this._setEmail(data);
        this._setOrganizationName(data);
        this._setPosition(data);
        this._setJuridicalAddress(data);
        this._setOwnerName(data);
        this._setOwnerSurname(data);
        this._setJuridicalPersonFullName(data);
        this._setJuridicalPersonPosition(data);
        this._setCitizenShipId(citizenShipId);
    }

    private _setApplicantTypeId(applicantTypeId: number | null) {
        this.applicantTypeId = applicantTypeId;
    }

    private _setDocumentTypeGroupId(citizenShipId: number | null) {
        this.documentTypeGroupId = citizenShipId;
    }

    private _setPin({fin}: any) {
        this.pin = fin;
    }

    private _setDocumentNumber({documentNumber}: any) {
        this.documentNumber = documentNumber;
    }

    private _setDocumentSerial({documentTypeNumber}: any) {
        this.documentSerial = documentTypeNumber?.value;
    }

    private _setTin({tin}: any) {
        this.tin = tin;
    }

    private _setPhone({phone}: any) {
        this.phone = phone;
    }

    private _setEmail({email}: any) {
        this.email = email;
    }

    private _setOrganizationName({organizationName}: any) {
        this.organizationName = organizationName;
    }

    private _setPosition({position}: any) {
        this.position = position;
    }

    private _setJuridicalAddress({legalEntityAddress}: any) {
        this.juridicalAddress = legalEntityAddress;
    }

    private _setOwnerName({name}: any) {
        this.ownerName = name;
    }

    private _setJuridicalPersonFullName({legalEntityFullName}: any) {
        this.juridicalPersonFullName = legalEntityFullName;
    }

    private _setJuridicalPersonPosition({positionLegalEntity}: any) {
        this.juridicalPersonPosition = positionLegalEntity;
    }

    private _setCitizenShipId(citizenShipId: any) {
        this.citizenShipId = citizenShipId;
    }

    private _setOwnerSurname({secondName}: any) {
        this.ownerSurname = secondName;
    }
}