// @ts-ignore
import * as toastr from 'toastr';
import {messages} from './constants/messages';
import {getLang, getLoginType} from './constants/configs';
import {ISign} from './types/sign';
import {ESignSandbox} from './esign/sign.sandbox';
import {Signed} from './models/success-sign.model';

export class SignSandbox {
    esign: ESignSandbox = new ESignSandbox();

    constructor() {
        this._guessSigner();
    }

    private loginType: 'loginWithAsanSign' | 'loginWithMhmSign' = getLoginType();

    public async sign(signRequest: ISign): Promise<any> {
        // new Promise((resolve, reject) => {
        // if (this.loginType === 'loginWithAsanSign') {
        //   // this.asign.sign(signRequest)
        //   reject('Asan Imza inteqrasiya edilməmişdir!')
        // } else
        if (this.loginType === 'loginWithMhmSign') {
            let res;
            await this.esign.sign(signRequest).then(file => {
                res = new Signed(file);
                // return  resolve(new Signed(file));
            });
            return res;
        } else {
            toastr.info(messages[getLang()].connectionTypeError);
            //   reject(messages[getLang()].connectionTypeError);
            // }
        }
    }

    /**
     * Guess login method
     * @private
     */
    private _guessSigner() {
        this.loginType = getLoginType();
        if (this.loginType === 'loginWithMhmSign') {
            this.esign.check();
        }
    }
}
