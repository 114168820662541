import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import FormAppealComponent from '@/pages/record-keeping-service/components/form-appeal/form-appeal.component.vue';
import {container} from 'tsyringe';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {RecordKeepingSandbox} from '@/pages/record-keeping-service/sandbox/record-keeping.sandbox';
import {store} from '@/store';
import ToastComponent from '@/packages/VToasts/toast.component.vue';
import {GetAppealModel} from '@/pages/record-keeping-service/models/getAppeal.model';
import {PostAppealModel} from '@/pages/record-keeping-service/models/postAppeal.model';
import {CreateFormData} from '@/core/mixin/mixin';
import {locale} from '@/assets/db/db.conf.ts';
import CancelFormComponent from '@/pages/record-keeping-service/components/cancel-form/cancel-form.component.vue';
import {ICancelInfo} from '@/pages/record-keeping-service/types/cancelInfo';
import {SignSandbox} from '@/core/sign/sign.sandbox';
import {PutAppealModel} from '@/pages/record-keeping-service/models/putAppeal.model';
import {PutCancelModel} from '@/pages/record-keeping-service/models/putCancel.model';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';


@Options({
    name: 'record-keeping-component',
    components: {
        CustomerInfoComponent,
        FormAppealComponent,
        ToastComponent,
        CancelFormComponent,
        NotDataComponent
    },

})

export default class RecordKeepingComponent extends mixins(CreateFormData) {
    public ip4 = '';
    public ip6 = '';
    public locale$: any = locale;
    public locale: any = null;
    public signBtnDisabled = true;
    public sendBtnDisabled = true;
    public check = false;
    public requestCancel = false;
    public store = store;
    public changedFirstIP = false;
    public fileForSignature = [];
    public sandbox: RecordKeepingSandbox = container.resolve(RecordKeepingSandbox);
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);
    public request = true;
    public canselError = {
        text: false,
        file: false
    };
    public cancelForm: ICancelInfo = {
        cancelFile: null,
        cancelText: null,
    };
    public $refs!: {
        modal: {
            openModal: () => void;
            closeModal: () => void
        },
        modalCancel: {
            openModal: () => void;
            closeModal: () => void
        }
    };

    public openCancel() {
        this.$refs.modalCancel.openModal();
    }

    public closeCancel() {
        this.$refs.modalCancel.closeModal();
    }

    get certificates() {
        return this.store.getters._getCertificates;
    }

    get userData() {

        if (this.request && this.store.getters._getUserFullData.tin) {
            this.sandbox.getAppeal(new GetAppealModel(this.store.getters._getUserFullData.tin));
        }
        return this.store.getters._getUserFullData;
    }

    get userForm() {
        if (!this.changedFirstIP && this.store.getters._getUserForm.iPvSix !== null) {
            this.ip4 = this.store.getters._getUserForm.iPvFour;
            this.ip6 = this.store.getters._getUserForm.iPvSix;
            this.changedFirstIP = true;
        }


        return this.store.getters._getUserForm;
    }

    // dropdown data

    get applicantActivityAddress() {
        return this.store.getters._getApplicantActivityAddress;
    }

    get applicantTypeActivities() {
        return this.store.getters._getApplicantTypeActivities;
    }

    get applicantTypesTelecommunicationsService() {
        return this.store.getters._getApplicantTypesTelecommunicationsService;
    }

    get applicantScopeActivity() {
        return this.store.getters._getApplicantScopeActivity;
    }

    public mounted() {
        this.locale$.subscribe((res: any) => {
            if (res && res.recordKeeping) {
                this.locale = res.recordKeeping;
            }
        });
        this.sandbox.getActivityAddresses();
        this.sandbox.getActivityScopes();
        this.sandbox.getInternetTelecommunicationsServiceTypes();
        this.sandbox.getActivityTypes();
    }

    public onChangedInputCancel(info: any) {
        this.cancelForm[info.type] = info.value;
    }

    public sendCancelData() {
        this.validationCansel();
        // if (this.canselError.file && this.canselError.text) {
        let data = new PutCancelModel(this.cancelForm, this.userForm.id, this.userForm.applicantId);
        let formData = new FormData();
        formData.append('Id', String(data.id));
        formData.append('AppealStatusId', String(data.appealStatusId));
        formData.append('RejectionReason', String(data.rejectionReason));
        formData.append('RespondentPin', String(null));
        formData.append('AppealFileDto.AppealFileTypeId', String(data.appealFileDto?.appealFileTypeId));
        // @ts-ignore
        formData.append('AppealFileDto.File', data.appealFileDto?.file);
        // let formData = this.createFormData(new PutCancelModel(this.cancelForm, this.userForm.id, this.userForm.applicantId))
        this.sandbox.changeAppealStatus(formData);
        // }
    }

    public validationCansel() {
        this.cancelForm.cancelText ? this.canselError.text = false : this.canselError.text = true;
        this.cancelForm.cancelFile ? this.canselError.file = false : this.canselError.file = true;
    }

    public resetForm() {
        this.ip4 = '';
        this.ip6 = '';
        this.store.commit('SET_RESET_USER_FORM');
    }

    public sendData() {
        if (this.userForm.id) {
            let formData = this.createFormData(new PutAppealModel(this.userForm));
            this.sandbox.putIspAppeal(formData).then((res: any) => {
                if (res.status === 200) {
                    this.sandbox.getAppeal(new GetAppealModel(this.store.getters._getUserFullData.tin));
                }
            });
        } else {
            let formData = this.createFormData(new PostAppealModel(this.userForm));
            this.sandbox.postIspAppeal(formData).then((res: any) => {
                if (res.status === 200) {
                    this.sandbox.getAppeal(new GetAppealModel(this.store.getters._getUserFullData.tin));
                }
            });
        }
    }

    public closeModal() {
        this.$refs.modal.closeModal();
        this.$router.push({name: 'home'});
    }

    public changedFormData(info: any) {
        this.store.commit('SET_IN_USER_FORM', info);
        if (info.type.indexOf('doc') !== -1) {
            if (this.userData.entity === 1) {
                if (this.userForm.docTreaty?.length > 0
                    && this.userForm.docCopyCharter?.name
                    && this.userForm.docDocumentAuthority?.name) {
                    this.signBtnDisabled = false;
                } else {
                    this.signBtnDisabled = true;
                }
            }
            if (this.userData.entity === 2) {
                if (this.userForm.docTreaty?.length > 0
                    && this.userForm.docDocumentAuthority?.name) {
                    this.signBtnDisabled = false;
                } else {
                    this.signBtnDisabled = true;
                }
            }
        }
    }

    public onSingFiles() {
        if (!this.signBtnDisabled) {
            let filesForSign = this.userForm.docTreaty.map((item: File) => item);
            filesForSign.push(this.userForm.docCopyCharter, this.userForm.docDocumentAuthority);
            new SignSandbox().sign({file: filesForSign}).then((res: any) => {
                let file = new File([res?.binary], 'name');

                this.changedFormData({
                    type: 'signFile', value: {
                        name: res?.name,
                        url: `data:application/e-doc;base64,${res.base64}`
                    }
                });
                this.changedFormData({
                    type: 'sendSignFile', value: file
                });
                this.sendBtnDisabled = false;
            }).catch((err: any) => {

            });
        }
    }
}
