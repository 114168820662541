import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'card-info-component',
    props: {
        color: {
            type: String,
        },

    }
})

export default class CardInfoComponent extends Vue {

}