<style src="./general-information-form.component.scss" lang="scss"></style>

<template>
  <div class="general-information-form">
    <form class="form">
      <div class="row">
        <div class="col-12 col-lg-4 password-margin">
          <div class="form__group">
            <label class="v-label">
              {{ locale.citizenship }}
            </label>
            <v-select
                :disabled="userLogin"
                :error="stepOneValidation.citizenship"
                :value="citizenshipValue"
                ref="selectCitizenship"
                placeholder="Vətəndaşlığı seçin"
                @select="selectCitizenship"
                :options="citizenship"/>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form__group">
            <label class="v-label">
              {{ locale.organizationLegalForm }}
            </label>
            <v-select
                :placeholder="locale.organizationLegalFormPlaceholder"
                :error="stepOneValidation.organizationLegalForm"
                :value="organizationLegalFormValue"
                ref="selectOrganizationLegalForm"
                @select="selectOrganizationLegalForm"
                :options="organizationLegalForm"/>
          </div>
          <div class="form__group d-flex align-center mt-22" v-if="((organizationLegalFormValue === 2 || organizationLegalFormValue === 4) && (serviceType!==3 || serviceType!==4)) || (serviceType===3 || serviceType===4)">
            <input @change="selectPersonCarrier($event.target.checked)" v-model="legalPersonCarrierValue"
                   id="personCarrier"
                   type="checkbox" class="v-checkbox v-checkbox--square v-checkbox--dark-blue mr-12">
           <label for="personCarrier" class="v-label mt-7" v-if="serviceType!==3 && serviceType!==4"> {{
                locale.ownerApplicantSame
              }}</label>
            <label for="personCarrier" class="v-label mt-7" v-else>Sahibkar və müraciət edən fərqlidir</label>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script src="./general-information-form.component.ts"></script>
