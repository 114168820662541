import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import Datepicker from 'vue3-datepicker';
import {az} from 'date-fns/locale';
import FileInputComponent from '@/core/shared/file-input/file-input.component.vue';
import ESignatureFileComponent
    from '@/pages/record-keeping-service/components/e-signature-file/e-signature-file.component.vue';
import MultipleComponent from '@/core/shared/multiple/multiple.component.vue';
import TagComponent from '@/core/shared/tag/tag.component.vue';
import {PayloadForm} from '@/pages/permission-movement-service/types/payload-form';
import {IUserForm} from '@/pages/record-keeping-service/types/user-form';
import {Validation} from '@/core/mixin/validation';
import IpWrapperComponent from '@/core/shared/ip-wrapper/ip-wrapper.component.vue';
import DownloadFileComponent from '@/core/shared/download-file/download-file.component.vue';

@Options({
    name: 'user-form-component',
    props: {
        userForm: {
            type: Object,
            require: true
        },
        applicantActivityAddress: {
            type: Array,
            require: true
        },
        applicantTypeActivities: {
            type: Array,
            require: true
        },
        applicantTypesTelecommunicationsService: {
            type: Array,
            require: true
        },
        applicantScopeActivity: {
            type: Array,
            require: true
        },
        signBtnDisabled: {
            type: Boolean,
            default: true
        },
        sendBtnDisabled: {
            type: Boolean,
            default: true
        },
        entity: {
            type: Number,
            require: true
        },
        ip4: {
            type: String
        },
        ip6: {
            type: String
        }
    },
    components: {
        Datepicker,
        FileInputComponent,
        ESignatureFileComponent,
        MultipleComponent,
        TagComponent,
        IpWrapperComponent,
        DownloadFileComponent
    },
    emits: ['changedFormData', 'onSingFiles', 'onNewForm']
})

export default class UserFormComponent extends mixins(Validation) {
    public entity: number | null = null;
    public signBtnDisabled = false;
    public sendBtnDisabled = false;
    public locale$: any = locale;
    public locale: any = null;
    public dataLang = az;
    public files = [];
    public userForm: IUserForm | null = null;
    public payload: PayloadForm = {
        type: '',
        value: null
    };
    public error = {
        legalAddress: false,
        activityAddress: false,
        ownerApplicantSame: false,
        nameLegalEntity: false,
        dutyLegalEntity: false,
        applicantActivityAddress: false,
        applicantInternetAddress: false,
        applicantTypeActivities: false,
        applicantTypesTelecommunicationsService: false,
        applicantScopeActivity: false,
        applicantInterval4: false,
        applicantInterval6: false,
        applicantDate: false,
        applicationAutonomousSystem: false,
        applicantPhoneNumber: false,
        applicantFaxNumber: false,
        applicantEmail: false,
        applicantAutonomousSystem: false,
        docTreaty: false,
        docDocumentAuthority: false,
        docCopyCharter: false,
        signFile: false
    };

    public created() {
        this.locale$.subscribe((res: any) => {
            if (res && res.recordKeeping) {
                this.locale = res.recordKeeping;
            }
        });
    }

    public onSingFiles() {
        if (!this.signBtnDisabled) {
            this.$emit('onSingFiles');
        }
    }

    public onDeleteMultipleItem(id: number, type: any) {
        let newData: number[] | null = [];
        if (this.userForm) {
            newData = this.userForm[`${type}`].filter((item: number) => item !== id);
        }
        this.payload = {
            type: type,
            value: newData
        };
        this.$emit('changedFormData', this.payload);
    }

    public onDeleteFile(type: string, index?: number,) {
        if (type === 'docTreaty' && this.userForm?.docTreaty) {
            let arr = this.userForm?.docTreaty.filter((item: any, indexA) => indexA !== index);
            this.payload = {
                type: type,
                value: arr.length ? arr : []
            };
        } else {
            this.payload = {
                type: type,
                value: null
            };
        }
        this.$emit('changedFormData', this.payload);

    }

    public onChangedInput(value: any, type: string) {
        this.payload = {
            type: type,
            value: value
        };
        this.$emit('changedFormData', this.payload);
    }

    public sendData() {
        this.validation();

        if (!this.error.legalAddress
            && !this.error.activityAddress
            && !this.error.ownerApplicantSame
            && !this.error.nameLegalEntity
            && !this.error.dutyLegalEntity
            && !this.error.applicantActivityAddress
            && !this.error.applicantInternetAddress
            && !this.error.applicantTypeActivities
            && !this.error.applicantTypesTelecommunicationsService
            && !this.error.applicantScopeActivity
            && !this.error.applicantInterval4
            && !this.error.applicantInterval6
            && !this.error.applicantDate
            && !this.error.applicationAutonomousSystem
            && !this.error.applicantPhoneNumber
            && !this.error.applicantFaxNumber
            && !this.error.applicantEmail
            && !this.error.applicantAutonomousSystem
            && !this.error.docTreaty
            && !this.error.docDocumentAuthority
            && !this.error.docCopyCharter
            && !this.error.signFile) {
            this.$emit('sendData');
        }
    }


    public checkValidation() {
        let check = false;

    }

    public validation() {
        // appeal
        if (this.entity === 2) {
            this.userForm?.dutyNaturalPerson ? this.error.dutyLegalEntity = false : this.error.dutyLegalEntity = true;
        }
        this.userForm?.applicantInternetAddress && this.isURL(this.userForm?.applicantInternetAddress) ? this.error.applicantInternetAddress = false : this.error.applicantInternetAddress = true;
        this.userForm?.applicantTypeActivities ? this.error.applicantTypeActivities = false : this.error.applicantTypeActivities = true;
        this.userForm?.applicantTypesTelecommunicationsService ? this.error.applicantTypesTelecommunicationsService = false : this.error.applicantTypesTelecommunicationsService = true;
        this.userForm?.applicantScopeActivity ? this.error.applicantScopeActivity = false : this.error.applicantScopeActivity = true;
        this.userForm?.iPvFour ? this.error.applicantInterval4 = false : this.error.applicantInterval4 = true;
        this.userForm?.iPvSix ? this.error.applicantInterval6 = false : this.error.applicantInterval6 = true;
        this.userForm?.serviceDate ? this.error.applicantDate = false : this.error.applicantDate = true;
        this.userForm?.applicantEmail && this.validationEmail(this.userForm?.applicantEmail)
            ? this.error.applicantEmail = false : this.error.applicantEmail = true;
        this.userForm?.applicantFaxNumber ? this.error.applicantFaxNumber = false : this.error.applicantFaxNumber = true;
        this.userForm?.applicantPhoneNumber ? this.error.applicantPhoneNumber = false : this.error.applicantPhoneNumber = true;
        this.userForm?.signFile ? this.error.signFile = false : this.error.signFile = true;
    }

    public deleteNumber(event: any, name: any) {
        if (this.userForm) {
            this.deletePhoneNumber(event, name, this.userForm[name], 'SET_IN_USER_FORM');
        }
    }


}
