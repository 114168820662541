import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import TagComponent from '@/core/shared/tag/tag.component.vue';

@Options({
  name: 'tags-component',
  components:{
    TagComponent
  }
})

export default class TagsComponent extends Vue {

}