<style src="./one-time-payment.component.scss" lang="scss"></style>

<template>
  <div class="one-time-payment">
    <form class="form">
      <div class="row">
        <div class="col-lg-5 col-12">
          <div class="form__group">
            <div class="row">
              <div class="col-12">
                <label class="v-label"> {{ locale.userGroup }}</label>
              </div>
              <div class="col-10">
                <v-select
                    :error="errorOneTime.userGroup"
                    ref="userGroup"
                    :allData="true"
                    placeholder="Seçin"
                    @select="selectUserGroup"
                    :options="userGroups"
                    :value="oneTimePaymentInfo.userGroup?.id"
                />
              </div>
              <div class="col-2 pl-0">
                <input-value-component
                    :value="oneTimePaymentInfo.userGroup?.mean"/>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-lg-5 col-12 mt-20">
          <div class="form__group">
            <div class="row">
              <div class="col-12">
                <label class="v-label"> {{ locale.serviceType }}</label>
              </div>
              <div class="col-10">
                <v-select
                    :error="errorOneTime.serviceType"
                    placeholder="Seçin"
                    ref="serviceTypes"
                    :allData="true"
                    @select="selectServiceType"
                    :options="serviceTypesOneTime"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-lg-5 col-12 mt-20">
          <div class="form__group">
            <div class="row">
              <div class="col-12">
                <label class="v-label"> {{ locale.fixedCost }}</label>
              </div>
              <div class="col-10">
                <input disabled type="text" class="v-input" v-model="serviceType">
              </div>
              <div class="col-2 pl-0">
                <input-value-component title="b1" :value="oneTimePaymentInfo.serviceType?.mean"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-12 mt-20">
          <div class="form__group">
            <div class="row">
              <div class="col-12">
                <label class="v-label"> {{ locale.frequencies }}</label>
              </div>
              <div class="col-10">
                <input
                       :class="{'v-input--error':errorOneTime.frequency}" type="text" class="v-input"
                       @input="changeInput($event.target.value )" v-model="oneTimePaymentInfo.frequencyCount">
              </div>
              <div class="col-2 pl-0">
                <input-value-component :value="String(oneTimePaymentInfo.frequencyCount)" title="b2"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-12 mt-20">
          <div class="form__group">
            <div class="row">
              <div class="col-10">
                <label class="v-label" v-html="locale.throughput"></label>
              </div>
              <div class="col-10">
                <v-select
                    :error="errorOneTime.throughputType"
                    :disabled="throughputTypes?.length<=1"
                    ref="throughputTypes"
                    :allData="true"
                    placeholder="Seçin"
                    @select="selectThroughputType"
                    :options="throughputTypes"
                    :value="oneTimePaymentInfo.throughputType?.id"
                />
              </div>
              <div class="col-2 pl-0">
                <input-value-component :value="oneTimePaymentInfo.throughputType?.mean" title="b3"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-12 mt-20">
          <div class="form__group">
            <div class="row">
              <div class="col-10">
                <label class="v-label"> {{ locale.geographicalArea }}</label>
              </div>
              <div class="col-10">
                <v-select
                    :error="errorOneTime.geographicalArea"
                    :disabled="geographicalAreas?.length<=1"
                    ref="geographicalAreas"
                    :allData="true"
                    placeholder="Seçin"
                    @select="selectGeographicalArea"
                    :options="geographicalAreas"
                    :value="oneTimePaymentInfo.geographicalArea?.id"
                />
              </div>
              <div class="col-2 pl-0">
                <input-value-component :value="oneTimePaymentInfo.geographicalArea?.mean" title="b4"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-12 mt-20">
          <div class="form__group">
            <div class="row">
              <div class="col-10">
                <label class="v-label"> {{ locale.bandSignificance }}</label>
              </div>
              <div class="col-10">
                <v-select
                    :error="errorOneTime.bandSignificance"
                    ref="bandSignificances"
                    :disabled="bandSignificances?.length<=1"
                    :allData="true"
                    placeholder="Seçin"
                    @select="selectBandSignificance"
                    :options="bandSignificances"
                    :value="oneTimePaymentInfo.bandSignificance?.id"
                />
              </div>
              <div class="col-2 pl-0">
                <input-value-component :value="oneTimePaymentInfo.bandSignificance?.mean" title="b5"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./one-time-payment.component.ts"></script>