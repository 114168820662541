import {PostAppealModel} from '@/pages/record-keeping-service/models/postAppeal.model';

export class PutAppealModel extends PostAppealModel {
    public id: number | null = null;
    public applicantIp: number | null = null;

    constructor(item: any) {
        super(item);
        this._setId(item);
        this._setApplicantIp(item);
    }

    private _setId({id}: any) {
        this.id = id;
    }

    private _setApplicantIp({applicantId}: any) {
        this.applicantIp = applicantId;
    }
}