<style src="./result.component.scss" lang="scss"></style>

<template>
  <div class="result">
    <div class="result__title">
      {{ title }}
      <span v-if="additions">({{ additions }})</span>
    </div>
    <div class="result__price" :class="`result__price--${color}`">
      {{ price }}
    </div>
  </div>
</template>

<script src="./result.component.ts"></script>