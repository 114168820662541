<style src="./step-permit.component.scss" lang="scss"></style>

<template>
  <div class="step-certificate">
    <step-description-component :stepDescription="stepDescription" :current="current"/>
    <progressbar-component :step="step" :current="current" :stepDescription="stepDescription"/>
    <section v-show="current === 1">
      <v-divider class="mt-24 mb-26 d-none d-lg-block"/>
      <general-information-form-component
          :serviceType="3"
          :userLogin="userLogin"
          :stepOneValidation="stepOneValidation"
          :userData="userData"
          :legalPersonCarrier="legalPersonCarrier"
          :organizationLegalFormValue="organizationLegalFormValue"
          :citizenshipValue="citizenshipValue"
          :citizenship="citizenship"
          :organizationLegalForm="organizationLegalForm"/>
    </section>
    <section v-show="current === 2">
      <personal-information-form-component
          @setPersonalInformation="(i) => $emit('setPersonalInformation', i)"
          @checkTin="checkTin"
          :filesForForCertificate="filesForStep2"
          :serviceType="4"
          :req="true"
          :organizationName="organizationName"
          :userLogin="userLogin"
          :documentSeriesType="documentSeriesType"
          :errorPersonalInformation="errorPersonalInformation"
          :organizationLegalFormValue="organizationLegalFormValue"
          :legalPersonCarrier="legalPersonCarrier"
          :citizenshipValue="citizenshipValue"
          :personalInformation="personalInformation"
      />
    </section>
    <section v-show="current === 3">
      <v-divider class="mt-24 mb-26 d-none d-lg-block"/>
      <options-form-component
          @setOptionCommonInfo="(i) => $emit('setOptionCommonInfo', i)"
          @setOptionInformation="(i) => $emit('setOptionInformation', i)"
          @onSingFiles="$emit('onSingFiles')"
          @onSelectFile="(i) => $emit('onSelectFile', i)"
          @addItemInformation="$emit('addItemInformation')"
          @deleteItemInformation="(i) => $emit('deleteItemInformation', i)"
          @setPersonalInformation="(i) => $emit('setPersonalInformation', i)"
          :errorOptions="errorOptions"
          :restrictedCirculationItems="restrictedCirculationItems"
          :requiredCrucialData="requiredCrucialData"
          :signBtnDisabled="signBtnDisabled"
          :options="options"
          :itemsCivilCirculation="[]" :files="filesForStep3"/>
    </section>
  </div>
</template>

<script src="./step-permit.component.ts"></script>
