import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'download-file-component',
    props: {
        fileLink: {
            type: String,
        },
        name: {
            type: String,
            default:'Ümumi'
        }
    }
})

export default class DownloadFileComponent extends Vue {
    public files: any[] = [];

    // public downloadFiles() {
    //     for (const link in this.files) {
    //         fetch(this.files[link].imageUrl)
    //             .then((res) => res.blob())
    //             .then((res) => {
    //                 const blob = new Blob([res], );
    //                 const downloadLink = document.createElement('a');
    //                 downloadLink.href = URL.createObjectURL(blob);
    //                 downloadLink.download = this.files[link].name;
    //                 downloadLink.click();
    //                 URL.revokeObjectURL(downloadLink.href);
    //             });
    //     }
    // };
}