<style src="./calculator.component.scss" lang="scss"></style>

<template>
  <div class="calculator">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
        </div>
        <div class="col-12">
          <section class="mt-20 mb-100">
            <v-card class="h-container">
              <!--              <services-subtitle-component>-->
              <!--                {{ locale.header }}-->
              <!--              </services-subtitle-component>-->
              <span class="mb-43 mt-23 d-block link">Ödənişlərin hesablanması üzrə Kalkulyatordan istifadə zamanı kömək məqsədilə <a
                  target="_blank"
                  href="https://dri.az/storage/Yeni%20Tarif%20Metodikası.pdf">Yeni Tarif Metodikasından </a> istifadə edilməsi tövsiyə olunur.</span>
              <a href=""></a>
              <div class="row">
                <div class="col-12">
                  <tab-radio-component
                      class="mb-40"
                      @resetError="resetError"
                      @changeViewTab="changeViewTab"
                      :nameRightInput="locale.termPayment"
                      :nameLeftInput="locale.oneTimePayment"/>
                </div>
                <div class="col-12">
                  <transition
                      :duration="150"
                      enter-active-class="animate__animated animate__fadeIn"
                      leave-active-class="animate__animated animate__fadeOut"
                      mode="out-in">
                    <div :key="viewName">
                      <KeepAlive>
                        <component
                            @resetError="resetError"
                            :errorTern="errorTern"
                            :radioFrequenciesTypes="radioFrequenciesTypes"
                            :radioFrequencyMultiplier="radioFrequencyMultiplier"
                            :dateTern="dateTern"
                            :selectBandwidthFrequencyNominal="selectBandwidthFrequencyNominal"
                            :selectGeographicalAreas="selectGeographicalAreas"
                            :selectSEMTechnologyFactor="selectSEMTechnologyFactor"
                            :selectBandSignificances="selectBandSignificances"
                            :selectCoefficientSEMTechnology="selectCoefficientSEMTechnology"
                            :selectAdjustmentFactor="selectAdjustmentFactor"
                            :termInfo="termInfo"
                            :frequency="frequency"
                            :errorOneTime="errorOneTime"
                            :serviceTypesOneTime="serviceTypesOneTime"
                            @mobile="checkMobile"
                            :mobile="mobile"
                            :mobileInputData="mobileInputData"
                            :is="viewName"
                            :resetCheck="resetCheck"
                            :serviceTypes="serviceTypes"
                            :frequencies="frequencies"
                            :throughputTypes="throughputTypes"
                            :bandSignificances="bandSignificances"
                            :geographicalAreas="geographicalAreas"
                            :oneTimePaymentInfo="oneTimePaymentInfo"
                            :userGroups="userGroups">
                        </component>
                      </KeepAlive>
                    </div>
                  </transition>
                </div>
                <transition
                    :duration="300"
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut"
                    mode="out-in">
                  <div class="col-12" v-if="calculateCheck">
                    <div class="row">
                      <div class="col-lg-4 col-12 mt-40">
                        <result-component title="ƏDV-siz məbləğ" :price="`${result} AZN`"/>
                      </div>
                      <div class="col-lg-4 col-12 mt-40">
                        <result-component title="ƏDV" :price="`${vat} AZN`"/>
                      </div>
                      <div class="col-lg-4 col-12 mt-40">
                        <result-component title="Yekun məbləğ, ƏDV daxil" :additions="details" color="blue"
                                          :price="`${totalResult} AZN`" price="40 AZN"/>
                      </div>
                    </div>
                  </div>
                </transition>
                <div class="col-12">
                  <v-divider class="my-40"/>
                  <div class="btn--group text-center">
                    <v-btn color="black" class="mr-12" @click="resetData" v-if="calculateCheck">Sıfırla</v-btn>
                    <v-btn color="dark" @click="calculate">Hesabla</v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./calculator.component.ts"></script>
