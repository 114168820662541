<style src="./download-file.component.scss" lang="scss"> </style>

<template>
  <a class="download-file pl-44 py-15 d-flex justify-between pr-14" :href="fileLink" download="umumi.edoc" >
    <div class="download-file__name">
      {{`${name.slice(0, 15)}${name.length>=14?'...':''}` }}
    </div>
    <div class="download-file__download" >
      <img src="@/assets/images/icons/download-file.svg" alt="">
    </div>
  </a>
</template>

<script src="./download-file.component.ts"></script>