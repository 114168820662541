// type FileCallbackInterface = (message: string | null) => void;

export   function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
// export function convertToBase64(file: File, callback: FileCallbackInterface): void {
//     if (file) {
//         const blob = file;
//         const reader = new FileReader();
//         reader.readAsDataURL(blob);
//         reader.onload = () => {
//             return callback(typeof reader.result === 'string' ? reader.result : null);
//         };
//         reader.onerror = (error) => {
//             console.error(error);
//             return callback(null);
//         };
//     } else {
//         console.warn('<input type="file"> is empty please choose file to convert!');
//         return callback(null);
//     }
//     /**
//      * Extract Base64 from url string (JS & TS)
//      * Lambda Extractor
//      * @param urlStr: string
//      * @returns string
//      */
//     const extractBase64 = (urlStr: string) => urlStr.split(',')[1];
// }

/**  * Convert BASE64 DATA URL to File  * @param b64: string  * @param mimeType: string  * @param filename: string  * @returns ConvertableFile  */
export function convertToFile(b64: string, mimeType: string, filename: string): File {
    const mime = mimeType;
    const bstr = atob(b64);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);
    let nlength = n;
    while (nlength--) {
        u8arr[nlength] = bstr.charCodeAt(nlength);
    }
    return new File([u8arr], filename, {type: mime});
}
