export const messages = {
  az: {
    connectionTypeError: 'Yalnız Asan imza və Elektron imza ilə imzalamaq olar!',
    nonValidVersionDetected: 'Token Sima imzalayıcı ilə əlaqə qurula bilmədi!',
    certError: 'Sertifikat alınarkən səhf baş verdi! Davam etmək mümkünsüzdür!',
    certV2TSAError: `TSA Sertifikat alınarkən səhf baş verdi! Davam etmək mümkünsüzdür`,
    signing: 'İmzalanır...',
    successfullSign: 'Müvəffəqiyyətlə imzalandı!',
    failedSign: 'İmza xətası',
    versionNotSpecified: 'İmzalayıcı versiyası bəlli deyil'
  },
  en: {
    connectionTypeError: 'EN Yalnız Asan imza və Elektron imza ilə imzalamaq olar!',
    nonValidVersionDetected: 'Token Sima imzalayıcı ilə əlaqə qurula bilmədi!',
    certError: 'Sertifikat alınarkən səhf baş verdi! Davam etmək mümkünsüzdür!',
    certV2TSAError: `TSA Sertifikat alınarkən səhf baş verdi! Davam etmək mümkünsüzdür`,
    signing: 'İmzalanır...',
    successfullSign: '',
    failedSign: '',
    versionNotSpecified: ''
  },
  ru: {
    connectionTypeError: 'RU Yalnız Asan imza və Elektron imza ilə imzalamaq olar!',
    nonValidVersionDetected: 'Token Sima imzalayıcı ilə əlaqə qurula bilmədi!',
    certError: 'Sertifikat alınarkən səhf baş verdi! Davam etmək mümkünsüzdür!',
    certV2TSAError: `TSA Sertifikat alınarkən səhf baş verdi! Davam etmək mümkünsüzdür`,
    signing: 'İmzalanır...',
    successfullSign: '',
    failedSign: '',
    versionNotSpecified: ''
  }
}
