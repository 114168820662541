<style src="./e-signature-file.component.scss" lang="scss"> </style>

<template>
  <div class="e-signature-file pl-44 py-15" >
    <div class="e-signature-file__name">
      {{fileName.slice(0, 25) }}...
    </div>
    <div class="e-signature-file__delete" @click="this.$emit('onDeleteFile')">
      <img src="@/assets/images/icons/delete-file-sign.svg" alt="">
    </div>
  </div>
</template>

<script src="./e-signature-file.component.ts"></script>
