import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'input-value-component',
    props: {
        title: {
            type: String,
            required:false
        },
        value: {
            type: [String, Number],
            required:false,
            default:''
        },
        size:{
            type: String,
           default: 'small'
        },
        sliceCount:{
            type: Number,
            default: 4
        }
    }
})

export default class InputValueComponent extends Vue {

}