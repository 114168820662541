<style src="./one-step-radio-checkbox.component.scss" lang="scss"></style>

<template>
  <div class="step-radio-checkbox form">
    <div class="row">
      <div :class="!leftData.description?'col-lg-2 col-12':'col-lg-4 col-12'">
        <div class="form__group d-flex">
          <input @change="onChangeInput(inputType, leftData.value)" v-model="value" :value="leftData.value" :id="leftData.id"
                 type="radio" class="v-radio v-radio--circle v-radio--dark-blue mr-12">
          <div class="wrapper">
            <label :for="leftData.id" class="v-label  mt-5">{{ leftData.label }}</label>
            <div class="form__group-info">{{ leftData.description }}</div>
          </div>
        </div>
      </div>
      <div :class="!rightData.description?'col-lg-3  col-12':'col-lg-4 col-12'">
        <div class="form__group d-flex">
          <input @change="onChangeInput(inputType, rightData.value)" v-model="value" :value="rightData.value" :id="rightData.id"
                 type="radio" class="v-radio v-radio--circle v-radio--dark-blue mr-12">
          <div class="wrapper">
            <label :for="rightData.id" class="v-label mt-5">{{ rightData.label }}</label>
            <div class="form__group-info">{{ rightData.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./one-step-radio-checkbox.component.ts"></script>