<style src="./record-keeping-service.component.scss" lang="scss" xmlns="http://www.w3.org/1999/html"></style>

<template>
  <div class="record-keeping mb-100 mt-16">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20 mb-16">
            <!--            <service-name-component-->
            <!--                :serviceName="commonService.name"-->
            <!--                :organization="commonService.structureName"-->
            <!--                :img="commonService.file"-->
            <!--                :payment="commonService.paymentRequired"-->
            <!--                :registered="commonService.authRequired"-->
            <!--                :serviceId="commonService.id"-->
            <!--                :structureId="commonService.structureId"-->
            <!--                :headers="serviceHeader"-->
            <!--            />-->
          </section>
        </div>
        <div class="col-md-12">
          <customer-info-component v-if="userData" :user="userData"/>
        </div>
        <div class="col-12">
          <v-card>
            <form-appeal-component
                v-if="userData.loginType='loginWithMhmSign'"
                :ip4="ip4"
                :ip6="ip6"
                @onNewForm="resetForm"
                @onCancel="openCancel"
                :userData="userData"
                :signBtnDisabled="signBtnDisabled"
                :sendBtnDisabled="sendBtnDisabled"
                :applicantActivityAddress="applicantActivityAddress"
                :applicantTypeActivities="applicantTypeActivities"
                :applicantTypesTelecommunicationsService="applicantTypesTelecommunicationsService"
                :applicantScopeActivity="applicantScopeActivity"
                :userForm="userForm"
                @sendData="sendData"
                @changedFormData="changedFormData"
                @onSingFiles="onSingFiles"
            />
            <not-data-component v-else>Zəhmət olmasa E-imza ilə daxil olun</not-data-component>
          </v-card>
        </div>
      </div>
    </div>
    <v-modal
        @closeModal="closeModal"
        title="Məlumat"
        ref="modal"
    >
      <template v-slot:body>
        <img class="service__img"
             src="@/assets/images/icons/modal-info.svg" alt="">
        <div class="mb-20"> Müraciətiniz qeydə alındı. Təşəkkür edirik!</div>

      </template>
      <template v-slot:footer>
        <div class="modal-btn-group text-center">
          <v-btn @click="closeModal">Bağla
          </v-btn>
        </div>
      </template>
    </v-modal>
    <v-modal
        title="Məlumat"
        ref="modalCancel"
    >
      <template v-slot:body>
        <div class="mb-30 ">
          <div class="cancel text-center">
            <img src="@/assets/images/icons/modal-info.svg" v-if="!requestCancel" alt="">
            <img src="@/assets/images/icons/modal-accept.svg" v-else alt="">
            <div class="cancel-title" v-if="!requestCancel">{{ locale?.modalCancelTitleInfo }}</div>
            <div class="cancel-title" v-else>{{ locale?.modalCancelTitleTrue }}</div>
            <div class="cancel-descr mt-13" v-if="!requestCancel">{{ locale?.modalCancelDescrInfo }}</div>
            <div class="cancel-descr mt-13" v-else>{{ locale?.modalCancelDescrTrue }}</div>
          </div>

          <cancel-form-component
              :error="canselError"
              class="mt-40" v-if="!requestCancel"
              @onChangedInput="onChangedInputCancel"/>
        </div>
      </template>
      <template v-slot:footer>
        <div class="modal-btn-group text-center" v-if="!requestCancel">
          <v-btn
              @click.prevent="closeCancel"
              class=" py-12 px-55 mr-12"
              color="black">İmtina
          </v-btn>
          <v-btn
              @click.prevent="sendCancelData"
              class=" py-12 px-55"
              color="dark">Göndər
          </v-btn>
        </div>
        <div class="modal-btn-group text-center" v-else>
          <v-btn
              @click.prevent="closeCancel"
              class=" py-12 px-55"
              color="black">Bağla
          </v-btn>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script src="./record-keeping-service.component.ts"></script>
