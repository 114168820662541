import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import InputValueComponent from '@/pages/calculator/components/input-value/input-value.component.vue';
import {IOneTimePaymentInfo} from '@/pages/calculator/types/oneTimePayment';
import {Login} from '@/core/configs/api';

@Options({
    name: 'one-time-payment-component',
    components: {
        InputValueComponent
    },
    props: {
        userGroups: {
            type: Array,
            require: true
        },
        serviceTypesOneTime: {
            type: Array,
            require: true
        },
        frequencies: {
            type: Array,
            require: true
        },
        throughputTypes: {
            type: Array,
            require: true
        },
        bandSignificances: {
            type: Array,
            require: true
        },
        geographicalAreas: {
            type: Array,
            require: true
        },
        oneTimePaymentInfo: {
            type: Object,
            require: true
        },
        resetCheck: {
            type: Boolean,
            require: true
        },
        errorOneTime: {
            type: Object,
            require: true
        },
        frequency: {
            type: [Number, null, String],
            require: true
        }
    },
    watch: {
        resetCheck: {
            handler: 'changeResetCheck',
            immediate: false,
            deep: false,
        }
    }
})

export default class OneTimePaymentComponent extends Vue {
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public serviceType = '';
    public $refs!: {
        userGroup: {
            reset: () => void,
        },
        serviceTypes: {
            reset: () => void,
        },
        throughputTypes: {
            reset: () => void,
        },
        geographicalAreas: {
            reset: () => void,
        },
        bandSignificances: {
            reset: () => void,
        },
    };

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.calculator) {
                this.locale = res.calculator;
            }
        });
    }

    public selectUserGroup(info: number) {
        let payload = {
            type: 'userGroup',
            value: info
        };
        this.store.commit('SET_ONE_TIME_PAYMENT', payload);
    }

    public selectServiceType(info: any) {

        let payload = {
            type: 'serviceType',
            value: info
        };
        // this.store.commit('SET_FREQUENCY', info.countFrequencies);
        this.store.commit('SET_RESET_ONE_TIME_PAYMENT_DETAILS');
        this.store.commit('SET_ONE_TIME_PAYMENT', payload);
        this.store.commit('SET_ONE_TIME_PAYMENT', {
            type:'frequencyCount',
            value: info.countFrequencies
        });
        this.store.commit('SET_SELECT_THROUGHPUT_TYPES_ONE_TIME', payload.value.id);
        this.serviceType = info.value;

    }

    public selectBandSignificance(info: any) {
        let payload = {
            type: 'bandSignificance',
            value: info
        };
        this.store.commit('SET_ONE_TIME_PAYMENT', payload);
    }

    public selectGeographicalArea(info: any) {
        let payload = {
            type: 'geographicalArea',
            value: info
        };
        this.store.commit('SET_ONE_TIME_PAYMENT', payload);
    }

    public selectThroughputType(info: any) {
        let payload = {
            type: 'throughputType',
            value: info
        };
        this.store.commit('SET_ONE_TIME_PAYMENT', payload);
    }

    public changeInput(event: any, name: any) {
        let value = event.replace(/[^\d\.]/g, '').slice(0, 4);
        if (value[0] === '.') {
            value = '0.';
        }
        if (value.match(/\./g)?.length > 1) {
            value = value.substr(0, value.lastIndexOf('.'));
        }
        let payload = {
            type: 'frequencyCount',
            value: value
        };
        this.store.commit('SET_ONE_TIME_PAYMENT', payload);
    }

    public changeResetCheck(val: boolean, oldVal: boolean) {
        if (val) {
            this.$refs.userGroup.reset();
            this.$refs.serviceTypes.reset();
            this.$refs.throughputTypes.reset();
            this.$refs.geographicalAreas.reset();
            this.$refs.bandSignificances.reset();
            this.serviceType = '';
        }
    }
}