import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "v-input-multiple--title" }
const _hoisted_2 = {
  key: 0,
  class: "form__group-details-icon"
}
const _hoisted_3 = { class: "form__group-details-text" }
const _hoisted_4 = {
  key: 2,
  class: "v-input-multiple__select"
}
const _hoisted_5 = { class: "v-input-multiple__select-item v-input-multiple__select-item--active" }
const _hoisted_6 = ["for"]
const _hoisted_7 = ["onChange", "value", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.isOpenSelect && _ctx.isOpenSelect(...args)), ["self"])),
    onMouseleave: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.mouseUpElement && _ctx.mouseUpElement(...args)), ["self"])),
    class: _normalizeClass(["v-input v-input-multiple", {'v-input--error':_ctx.error, 'v-input-multiple--active':_ctx.isSelect, 'v-input-multiple--disabled':_ctx.disabled}])
  }, [
    (_ctx.resultPositionInInput)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "v-input-multiple__wrapper",
          ref: "mainWrapper",
          onMouseleave: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.mouseUpElement && _ctx.mouseUpElement(...args)), ["self"])),
          onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mousedownElement && _ctx.mousedownElement(...args))),
          onMousemove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mouseMoveElement && _ctx.mouseMoveElement(...args))),
          onMouseup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.mouseUpElement && _ctx.mouseUpElement(...args))),
          onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.isOpenSelect && _ctx.isOpenSelect(...args)), ["self"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "v-input-multiple--title__wrapper",
              ref: "wrapper",
              style: _normalizeStyle({'transform':`translate3d(${(this.scrollPxClick)}px, 0px, 0px)`,
            'transition-duration':'0ms'})
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (itemOptions) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectItems, (item) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (item === itemOptions.id)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            style: _normalizeStyle({'cursor': _ctx.click?'grabbing!important':'grab'}),
                            class: "v-input-multiple__item"
                          }, _toDisplayString(itemOptions.value), 5))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256))
                ], 64))
              }), 256))
            ], 4)
          ])
        ], 544))
      : _createCommentVNode("", true),
    (!_ctx.resultPositionInInput)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.isOpenSelect && _ctx.isOpenSelect(...args)), ["self"])),
          class: "v-input-multiple__placeholder"
        }, _toDisplayString(_ctx.placeholder), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["v-input-multiple__wrapper-action", {'v-input-multiple__wrapper-action-info':_ctx.info.length}])
    }, [
      _createElementVNode("div", {
        class: "v-input-multiple__toggle",
        onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.isOpenSelect && _ctx.isOpenSelect(...args)), ["self"]))
      }),
      (_ctx.info.length)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.info), 1)
    ], 2),
    (_ctx.isSelect)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createElementVNode("label", {
                class: "v-label",
                for: item.id
              }, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectItems) = $event)),
                  onChange: ($event: any) => (_ctx.selectItem(item.id)),
                  value: item.id,
                  class: "v-checkbox v-checkbox--dark-blue v-checkbox--square",
                  type: "checkbox",
                  id: item.id
                }, null, 40, _hoisted_7), [
                  [_vModelCheckbox, _ctx.selectItems]
                ]),
                _createTextVNode(" " + _toDisplayString(item.value), 1)
              ], 8, _hoisted_6)
            ]))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ], 34))
}