<style src="./issuance-special-permit-service.component.scss" lang="scss"></style>

<template>
  <div class="issuance-special-permit-service mb-100 mt-16">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20 mb-16">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
        </div>
        <div class="col-md-12">
            <customer-info-component v-if="userData" :user="userData"/>
            <customer-info-component v-if="user?.name?.length" :user="user"/>
        </div>
        <div class="col-12">
          <v-card>
            <step-permit-component
                @setPersonalInformation="setPersonalInformation"
                @setOptionInformation="setOptionInformation"
                @onSelectFile="onSelectFile"
                @onChangeInput="onChangeInput"
                @checkTin="checkTin"
                @addItemInformation="addItemInformation"
                @deleteItemInformation="deleteItemInformation"
                :errorOptions="errorOptions"
                :restrictedCirculationItems="restrictedCirculationItems"
                :requiredCrucialData="requiredCrucialData"
                :options="options"
                :filesForStep2="filesForStep2"
                :filesForStep3="filesForStep3"
                :organizationName="organizationName"
                :errorPersonalInformation="errorPersonalInformation"
                :personalInformation="personalInformation"
                :documentSeriesType="documentSeriesType"
                :applicantForm="applicantForm"
                :userLogin="!!user?.pin?.length"
                :stepOneValidation="stepOneValidation"
                :legalPersonCarrier="legalPersonCarrier"
                :organizationLegalFormValue="organizationLegalFormValue"
                :citizenshipValue="citizenshipValue"
                :citizenship="citizenship"
                :organizationLegalForm="organizationLegalForm"
                :step="step"
                :stepDescription="stepDescription"
                :current="current"
            />
            <v-divider class="my-40"/>
            <div class="btn__wrapper d-flex justify-center mt-40">
              <v-btn v-if="current!==1" @click="decrementCurrent" color="white mr-12">{{ locale?.prev }}</v-btn>
              <v-btn @click="incrementCurrent" v-if="!(step===current)" color="dark">{{ locale?.next }}</v-btn>
              <v-btn v-if="current === step"  color="dark" @click.prevent="finallyStep">Göndər</v-btn>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
  <v-modal
      @closeModal="closeModal"
      title="Məlumat"
      ref="modal"
  >
    <template v-slot:body>
      <img class="service__img"
           src="@/assets/images/icons/modal-info.svg" alt="">
      <div class="mb-20"> Müraciətiniz qeydə alındı. Təşəkkür edirik!</div>

    </template>
    <template v-slot:footer>
      <div class="modal-btn-group text-center">
        <v-btn @click="closeModal">Bağla
        </v-btn>
      </div>
    </template>
  </v-modal>
</template>

<script src="./issuance-special-permit-service.component.ts"></script>
