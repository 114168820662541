<style src="./third-step.component.scss" lang="scss"></style>

<template>
  <div class="third-step">
    <div class="row">
      <div class="col-12">
        <one-step-radio-checkbox-component
            v-if="certificateStatus===1 && this.citizenshipValue!==4"
            :rightData="certificateType5"
            :leftData="certificateType4"
            @onChangeInput="(i)=>$emit('onChangeCertificateType', i)"/>
      </div>
      <div class="col-12 col-lg-4 mt-20">
        <file-generation-component
            @onSelectFile="(i)=>$emit('onSelectFile', i)"
            :files="filesForForCertificate"/>
      </div>
    </div>
  </div>
</template>

<script src="./third-step.component.ts"></script>