<style src="./radio-Information-form.component.scss" lang="scss"></style>

<template>
  <div class="radio-Information-form">
    <form class="form">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="form__group">
            <label
                class="v-label"> {{ locale.durationEffect }}</label>
            <v-select
                :placeholder="locale.durationEffectPlaceholder"
                :error="errorRadioInformation.duration"
                @select="selectDuration"
                :options="durations"
            />
          </div>
        </div>
        <div class="w-100"></div>
        <template v-if="radioInformation.duration !== 2">
          <div class="col-12 col-md-8" v-for="(item, index)  in  radioInformation.dates">
            <div class="row">
              <div class="col-12 delete-element-wrapper" v-if="radioInformation.dates.length>1">
                <span @click="deleteDate(index)" class="delete-element-item"></span>
              </div>
              <div class="col-8 col-md-4 mt-20">
                <div class="form__group" :class="{'form__group-error':errorRadioInformation.dates[index]?.startDate}">
                  <label class="v-label">{{ locale.startDate }}</label>
                  <datepicker v-model="item.startDate" :locale="dataLang"
                  />
                </div>
              </div>
              <div class="col-4 col-md-2 mt-20 pl-0">
                <div class="form__group" :class="{'form__group-error':errorRadioInformation.dates[index]?.startTime}">
                  <label class="v-label">{{ locale.time }}</label>
                  <input type="time" class="v-input time" v-mask="['##:##', '##:##']" v-model="item.startTime">
                </div>
              </div>
              <div class="col-8 col-md-4 mt-20">
                <div class="form__group" :class="{'form__group-error':errorRadioInformation.dates[index]?.endDate}">
                  <label class="v-label">{{ locale.endDate }}</label>
                  <datepicker v-model="item.endDate" :locale="dataLang"
                              :lowerLimit="item.startDate"/>
                </div>
              </div>
              <div class="col-4 col-md-2 mt-20 pl-0">
                <div class="form__group" :class="{'form__group-error':errorRadioInformation.dates[index]?.endTime}">
                  <label class="v-label">{{ locale.time }}</label>
                  <input type="time" class="v-input time" v-mask="['##:##', '##:##']" v-model="item.endTime">
                </div>
              </div>
              <div class="w-100"></div>
            </div>
          </div>
          <div class="col-12 mt-20">
            <div class="add-element" @click="addDates">
              <img src="@/assets/images/icons/plus.svg" alt="" class="mr-12">
              <span>{{ locale.addDates }}</span>
            </div>
          </div>
        </template>
        <div class="col-12 col-md-4 mt-20 password-margin">
          <div class="row">
            <div class="col-12">
              <label class="v-label"> {{ locale.frequencyBand }}</label>
            </div>
            <div class="col-8">
              <div class="form__group">
                <input
                    v-model="radioInformation.frequencyBand"
                    :class="{'v-input--error':errorRadioInformation.frequencyBand}"
                    :placeholder="locale.frequencyBandPlaceholder"
                    @input="setFrequencyBand($event.target.value)"
                    type="text"
                    class="v-input">
                <span class="form__group-details-icon"></span>
                <span class="form__group-details-text">{{ locale.frequencyBandPlaceholderInfo }}</span>
              </div>
            </div>
            <div class="col-4 pl-0">
              <v-select
                  placeholder="QHs"
                  :error="errorRadioInformation.frequencyUnits"
                  @select="selectFrequencyUnits"
                  :options="frequencyUnits"
                  :value="radioInformation.frequencyUnits"
              />
            </div>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-md-4 mt-20">
          <div class="form__group">
            <label class="v-label"> {{ locale.typeTechnology }}</label>
            <v-select
                :placeholder="locale.areaInfluencePlaceholder"
                :error="errorRadioInformation.typeTechnology"
                @select="selectTypeTechnology"
                :options="interferenceTechnologyTypes"
            />
          </div>
        </div>
        <div class="w-100"></div>
        <!--        <div class="col-12 col-md-4 mt-20">-->
        <!--          <multiple-component :options="interferenceTypes"/>-->
        <!--        </div>-->
        <div class="w-100"></div>
        <div class="col-12 col-md-4 mt-20">
          <div class="form__group">
            <label class="v-label"> {{ locale.natureObstacle }}</label>
            <multiple-component :options="interferenceTypes" @selectItem="selectNatureObstacle"/>
            <!--            <v-select-->
            <!--                :placeholder="locale.natureObstaclePlaceholder"-->
            <!--                :error="errorRadioInformation.natureObstacle"-->
            <!--                @select="selectNatureObstacle"-->
            <!--                :options="interferenceTypes"-->
            <!--            />-->
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-8 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.probableDirection }}</label>
            <textarea
                :class="{'v-textarea--error':errorRadioInformation.obstacleDirection}"
                :placeholder="locale.probableDirectionPlaceholder"
                @input="setRadioInfo($event.target.value, 'obstacleDirection')"
                class="v-textarea"></textarea>
          </div>
        </div>

        <div class="w-100"></div>
        <div class="col-12 obstacleArea">
          <div class="row">
            <div class="col-12 col-md-4 mt-20">
              <div class="form__group">
                <label class="v-label">{{ locale.obstacleArea }}</label>
                <input
                    :class="{'v-input--error':errorRadioInformation.obstacleArea}"
                    :placeholder="locale.obstacleAreaPlaceholder"
                    @input="setRadioInfoObstacleArea($event.target.value)"
                    type="text"
                    class="v-input">
              </div>
            </div>
            <div class="w-100"></div>
          </div>
        </div>


        <!--        <div class="col-12 mt-20">-->
        <!--          <div class="add-element" @click="addObstacleArea">-->
        <!--            <img src="@/assets/images/icons/plus.svg" alt="" class="mr-12">-->
        <!--            <span>{{ locale.addArea }}</span>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="w-100"></div>
        <div class="col-12 col-lg-8 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.technicalParameters }}</label>
            <textarea
                v-model="radioInformation.technicalIndicators"
                @input="setRadioInfo($event.target.value, 'technicalIndicators')"
                :class="{'v-input--error':errorRadioInformation.technicalIndicators}"
                :placeholder="locale.technicalParametersPlaceholder"
                class="v-textarea"></textarea>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 mt-20">
          <div class="form__group">
            <label class="v-label" :class="{'v-label--error':errorRadioInformation.file}">{{ locale.file }}</label>
            <file-input-component :limit="10" :multiple="true" :fileNameStore="radioInformation.file" @file="selectFile"/>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./radio-Information-form.component.ts"></script>