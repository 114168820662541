/**
 * Convert (JS)
 * @param file: File
 * @return Promise<string>
 */
export function convertToBase64(file: File) {
  return new Promise((resolve, reject) => {
    if (file) {
      const blob = file;
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        if (typeof reader.result === 'string') {
          resolve(extractBase64(reader.result));
        } else {
          console.error('Base64 extract edilə bilmədi')
          reject('Base 64 extract edilə bilmədi')
        }
      };
      reader.onerror = function (error) {
        reject(null);
      };
    } else {
      console.warn('<input type="file"> is empty please choose file to convert!');
      reject(null);
    }
    /**
     * Extract Base64 from url string (JS & TS)
     * Lambda Extractor
     * @param urlStr: string
     * @returns {string}
     */
    const extractBase64 = (urlStr: string) => urlStr.split(',')[1];
  })
}
/**
 * Convert Base64 to Blob
 * @param b64Data
 * @param contentType
 * @param sliceSize
 * @returns {Blob}
 */
export function b64ToBlob(b64Data: string, contentType: string, sliceSize = 512) {
  contentType = contentType || '';

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}
