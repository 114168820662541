<style src="./post-files.component.scss" lang="scss"></style>

<template>
  <div class="post-files">
    <form action="" class="form">
      <div class="col-12 col-md-4">
        <div class="form__group">
          <label class="v-label">{{ locale.technologyType }}</label>
          <v-select
              :error="errorRedAppealTechnologyId"
              :value="getPostCreateRedAppeal.redAppealTechnologyId"
              ref="selectTechnologyType"
              placeholder="Texnologiya növüseçin"
              @select="selectTechnology"
              :options="technology"/>
        </div>
      </div>
      <div class="col-12 mt-20" v-for="(item, index) in fileTypesKeyValues" :key="index">
        <div class="form__group">
          <div class="row">
            <div class="col-12">
              <label class="v-label">{{ item.value }}</label>
            </div>
            <div class="col-12">
              <file-input-component
                  :limit="10"
                  :error="errorFiles[index].error"
                  :multiple="true"
                  @file="(i) => this.selectFile(i, item, index)"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./post-files.component.ts"></script>