import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import FileGenerationComponent
    from '@/pages/issuing-certificate-conformity-service/components/file-generation/file-generation.component.vue';
import OneStepRadioCheckboxComponent
    from '@/pages/issuing-certificate-conformity-service/components/one-step-radio-checkbox/one-step-radio-checkbox.component.vue';

@Options({
    name: 'third-step-component',
    components: {
        FileGenerationComponent,
        OneStepRadioCheckboxComponent
    }, props: {
        filesForForCertificate: {
            type: [Array, null],
            required: true
        },
        certificateType: {
            type: Number,
            required: true
        },
        certificateStatus: {
            type: Number,
            required: true
        },
        citizenshipValue: {
            type: Number,
            default: []
        }
    },
    emits: ['onChangeCertificateType', 'onSelectFile']
})

export default class ThirdStepComponent extends Vue {
    public certificateType4 = {
        id: 'certificateType4',
        label: 'Sxem 4',
        description: 'Avadanlıq sınaq laboratoriyasına təqdim olunduqda',
        value: 4
    };
    public certificateType5 = {
        id: 'certificateType5',
        label: 'Sxem 5 ',
        description: 'Xarici sınaq protokolları təqdim olunduqda',
        value: 5
    };
}