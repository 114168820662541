import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {Login} from '@/core/configs/api';

@Options({
    name: 'ip-input-component',
    props: {
        interval: {
            type: Boolean
        },
        firstFocus: {
            type: Boolean
        },
        endFocus: {
            type: Boolean
        },
        item: {
            type: Object
        },
        length: {
            type: Number
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        item: [
            {
                handler: 'onChangeItem',
                immediate: false,
                deep: false,
            },
        ],
        firstFocus: [
            {
                handler: 'onChangeFirstFocus',
                immediate: false,
                deep: false,
            },
        ],
        endFocus: [
            {
                handler: 'onChangeEndFocus',
                immediate: false,
                deep: false,
            },
        ],
    }
})

export default class IpInputComponent extends Vue {
    public widthFirst = 7;
    public widthSecond = 7;
    public widthThird = 7;
    public widthFourth = 7;

    public item: { id: number, ip: string, checkValidation: boolean, endFocus: boolean, firstFocus: boolean, separator: string } | null = null;
    public firstFocus: boolean = false;
    public firstInput: null | string = '   ';
    public secondInput: null | string = '   ';
    public thirdInput: null | string = '   ';
    public fourthInput: null | string = '   ';
    public length: number = 0;
    public interval1 = false;
    public tenthInput = '';
    public ip1 = '';
    public ip2 = '';
    public prevLastInput: null | string = '';
    public checkComma = false;
    public checkDash = false;
    public view = true;
    public error = {
        firstInput: false,
        secondInput: false,
        thirdInput: false,
        fourthInput: false
    };

    public $refs!: {
        firstInputRef: HTMLInputElement,
        secondInputRef: HTMLInputElement
        thirdInputRef: HTMLInputElement
        fourthInputRef: HTMLInputElement

    };

    public onChangeFirstFocus(val: any) {
        if (val) {
            this.$refs.firstInputRef.focus();
            this.$emit('onStartFocus');
        }
    }

    public onChangeEndFocus(val: any) {
        if (val) {
            this.$refs.fourthInputRef.focus();
            this.$emit('onEndFocus');
        }
    }

    public onChangeItem(val: any) {
        if (val.separator === ',') {
            if (this.fourthInput) {
                this.fourthInput = this.fourthInput.replace('-', ',');
            }
        }
        if (val.ip.length) {

            let arr = val.ip.split('.');
            this.firstInput = arr[0];
            if (arr[0].length === 0) {
                this.widthFirst = 14;
            } else {
                this.widthFirst = arr[0].length * 7;
            }
            this.secondInput = arr[1];
            if (arr[1].length === 0) {
                this.widthSecond = 14;
            } else {
                this.widthSecond = arr[1].length * 7;
            }
            this.thirdInput = arr[2];
            if (arr[2].length === 0) {
                this.widthThird = 14;
            } else {
                this.widthThird = arr[2].length * 7;
            }
            this.fourthInput = arr[3];
            if (arr[3].length === 0) {
                this.widthFourth = 14;
            } else {
                this.widthFourth = arr[3].length * 7;
            }
            this.prevLastInput = arr[3];
        } else
        {
            this.firstInput = '   ';
            this.widthFirst = 14;
            this.secondInput = '   ';
            this.widthSecond = 14;

            this.thirdInput = '   ';
            this.widthThird = 14;
            this.fourthInput = '   ';
            this.widthFourth = 14;
            this.prevLastInput = null;
        }
    }

    public createData(data: string) {
        let number = data.replace(/[^0-9]+/g, '').slice(0, 3);
        if (Number(number) > 255) number = '255';
        if (Number(number) < 0) number = '0';
        return number;
    }

    public createForLastInput(data: any): string {
        let number = data.target.value.replace(/[^0-9,-]+/g, '').slice(0, 4);
        if (number.indexOf(',') !== -1) {
            number = data.target.value.replace(/[^0-9,]+/g, '').slice(0, 4);
            if (number?.match(/\,/g)?.length > 1)
                number = number.substr(0, number.lastIndexOf(','));
        }
        if (number.indexOf('-') !== -1) {
            number = data.target.value.replace(/[^0-9-]+/g, '').slice(0, 4);
            let index = number.lastIndexOf('-');
            if (number?.match(/\-/g)?.length > 1)
                number = number.substr(0, index);
            if ((number.length - 1) !== index)
                number = number.substr(0, index + 1);
        }
        if (number[0] === '-' || number[0] === ',') {
            number = `0${number[0]}`;
            this.$refs.fourthInputRef.blur();
        }
        if (number === '0000') number = `000`;
        if (Number(number) > 255) number = `255`;
        if (Number(number) < 0) number = '0';
        if ((data.data === '-' || data.data === ',')) {
            if (!this.error.fourthInput
                && !this.error.firstInput
                && !this.error.secondInput
                && !this.error.thirdInput
            ) {
                this.$emit('action', number[number.length - 1]);
            }
            this.$refs.fourthInputRef.blur();
        }
        return number;
    }

    public checkLastEl() {
        if (!this.checkValidationInput()) {
            if (this.fourthInput?.indexOf(',') !== -1) {
                this.$emit('action', ',');
            }
            if (this.fourthInput?.indexOf('-') !== -1) {
                this.$emit('action', ',');
            }
        }
    }

    public onCheckAddSeparator() {

        if (this.length > 1) {
            if (this.checkDash) {
                this.fourthInput = `${this.fourthInput}-`;
                this.widthFourth = this.fourthInput.length * 7;
            }
            if (this.checkComma) {
                this.fourthInput = `${this.fourthInput},`;
                this.widthFourth = this.fourthInput.length * 7;
            }
        }


    }


    public validation($event: any, value: string) {
        switch (value) {
            case 'firstInput':
                this.firstInput = this.createData($event);
                this.validationInput();
                this.checkLastEl();
                if (this.firstInput.length === 0) {
                    this.widthFirst = 14;
                } else {
                    this.widthFirst = this.firstInput.length * 7;
                }

                if (this.firstInput.length === 3) this.$refs.secondInputRef.focus();
                break;
            case 'secondInput':
                this.secondInput = this.createData($event);
                this.validationInput();
                this.checkLastEl();
                if (this.secondInput.length === 0) {
                    this.widthSecond = 14;
                } else {
                    this.widthSecond = this.secondInput.length * 7;
                }
                if (this.secondInput.length === 3) this.$refs.thirdInputRef.focus();
                break;
            case 'thirdInput':
                this.thirdInput = this.createData($event);
                this.validationInput();
                this.checkLastEl();
                if (this.thirdInput.length === 0) {
                    this.widthThird = 14;
                } else {
                    this.widthThird = this.thirdInput.length * 7;
                }
                if (this.thirdInput.length === 3) this.$refs.fourthInputRef.focus();
                break;
            case 'fourthInput':
                this.fourthInput = this.createForLastInput($event);
                this.validationInput();
                this.onCheckSeparator();
                if (this.fourthInput.length === 0) {
                    this.widthFourth = 14;
                } else {
                    this.widthFourth = this.fourthInput.length * 7;
                }
                this.prevLastInput = this.fourthInput;
                // if (this.fourthInput.length === 3) this.$refs.fifthInputRef.focus();
                break;
        }
        this.$emit('changeIP', this.createIp());
    }


    public onCheckSeparator() {
        if (this.prevLastInput?.indexOf(',') !== -1
            && this.fourthInput?.indexOf(',') == -1) {
            this.checkComma = true;
        } else {
            this.checkComma = false;
        }
        if (this.prevLastInput?.indexOf('-') !== -1
            && this.fourthInput?.indexOf('-') == -1) {
            this.checkDash = true;
        } else {
            this.checkDash = false;
        }

    }

    public validationInput() {
        this.firstInput?.trim().length ? this.error.firstInput = false : this.error.firstInput = true;
        this.secondInput?.trim().length ? this.error.secondInput = false : this.error.secondInput = true;
        this.thirdInput?.trim().length ? this.error.thirdInput = false : this.error.thirdInput = true;
        this.fourthInput?.trim().length ? this.error.fourthInput = false : this.error.fourthInput = true;
    }

    public checkValidationInput() {
        let check = false;
        if (this.error.firstInput || this.error.secondInput || this.error.thirdInput || this.error.fourthInput) {
            check = true;
        } else {
            check = false;
        }
        return check;
    }


    public createIp() {
        this.ip1 = `${this.firstInput}.${this.secondInput}.${this.thirdInput}.${this.fourthInput}`;
        return {
            ip1: this.ip1,
        };
    }

    public deleteNumber(event: any, type: string) {
        if ((event.keyCode === 8) || (event.keyCode === 46)) {
            switch (type) {
                case 'firstInput':
                    if (this.firstInput?.length === 0) this.$emit('deleteItem');
                    break;
                case 'secondInput':
                    if (this.secondInput?.length === 0) this.$refs.firstInputRef.focus();
                    break;
                case 'thirdInput':
                    if (this.thirdInput?.length === 0) this.$refs.secondInputRef.focus();
                    break;
                case 'fourthInput':
                    if (this.fourthInput?.length === 0) this.$refs.thirdInputRef.focus();
                    break;
            }
        }
    }

    public moveCursor(event: any, type: string) {
        if (event.keyCode === 37) {
            switch (type) {
                case 'firstInput':
                    if (this.$refs.firstInputRef.selectionStart === 0) {
                        this.$emit('startFocus');
                    }
                    break;
                case 'secondInput':
                    if (this.$refs.secondInputRef.selectionStart === 0) {
                        this.$refs.firstInputRef.focus();
                    }
                    break;
                case 'thirdInput':
                    if (this.$refs.thirdInputRef.selectionStart === 0) {
                        this.$refs.secondInputRef.focus();
                    }
                    break;
                case 'fourthInput':
                    if (this.$refs.fourthInputRef.selectionStart === 0) {
                        this.$refs.thirdInputRef.focus();
                    }
                    break;

            }
        }
        if (event.keyCode === 39) {
            switch (type) {
                case 'firstInput':
                    if (this.$refs.firstInputRef.selectionStart === this.firstInput?.length) {
                        this.$refs.secondInputRef.focus();
                    }
                    break;
                case 'secondInput':
                    if (this.$refs.secondInputRef.selectionStart === this.secondInput?.length) {
                        this.$refs.thirdInputRef.focus();
                    }
                    break;
                case 'thirdInput':
                    if (this.$refs.thirdInputRef.selectionStart === this.thirdInput?.length) {
                        this.$refs.fourthInputRef.focus();
                    }
                    break;
                case 'fourthInput':
                    if (this.$refs.fourthInputRef.selectionStart === this.fourthInput?.length) {
                        this.$emit('endFocus');
                    }
                    break;

            }
        }
    }

    public mounted() {
        if (this.length !== 1)
            this.$refs.firstInputRef.focus();
        if (this.item?.ip?.length) {
            let arr = this.item.ip.split('.');
            this.firstInput = arr[0];
            if (arr[0].length === 0) {
                this.widthFirst = 14;
            } else {
                this.widthFirst = arr[0].length * 7;
            }
            this.secondInput = arr[1];
            if (arr[1].length === 0) {
                this.widthSecond = 14;
            } else {
                this.widthSecond = arr[1].length * 7;
            }
            this.thirdInput = arr[2];
            if (arr[2].length === 0) {
                this.widthThird = 14;
            } else {
                this.widthThird = arr[2].length * 7;
            }
            this.fourthInput = arr[3];
            if (arr[3].length === 0) {
                this.widthFourth = 14;
            } else {
                this.widthFourth = arr[3].length * 7;
            }
            this.prevLastInput = arr[3];
        }

    }
}
