import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import FileInputComponent from '@/core/shared/file-input/file-input.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {IPayloadFile} from '@/pages/registration-radio-electronic-devices-service/types/payload-file';

@Options({
    name: 'post-files-component',
    props: {
        technology: {
            type: Array,
            require: true
        },
        fileTypesKeyValues: {
            type: Array,
            require: true
        },
        getPostCreateRedAppeal: {
            type: Object,
            require: true
        },
        errorFiles:{
            type: Array,
            require: true
        },
        errorRedAppealTechnologyId:{
            type: Boolean,
            require: true
        }
    },
    components: {
        FileInputComponent
    }
})

export default class PostFilesComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public store = store;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.registrationRadioElectronic) {
                this.locale = res.registrationRadioElectronic;
            }
        });
    }

    public selectTechnology(info: any) {
        this.$emit('selectTechnology')

        let payload = {
            type: 'redAppealTechnologyId',
            value: info,
        };
        this.store.commit('SET_POST_CREATE_RED_APPEAL', payload);
    }

    public selectFile(info: any, data: any, index: any) {

        let payload: IPayloadFile = {
            redAppealFileTypeId: null,
            file: null,
            index: null
        };
        // if (data.id === 17) {
            let arr = [];
            for (let key in info) {
                arr.push(info[key]);
                payload = {
                    redAppealFileTypeId: data.id,
                    file: arr,
                    index: index
                };
            }
        // } else {
        //     payload = {
        //         redAppealFileTypeId: data.id,
        //         file: info,
        //         index: index
        //     };
        //
        // }
        this.store.commit('SET_ITEM_RED_FILES', payload);

    }




}