import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'user-information-component',
    props: {
        organization: {
            type: [null, String],
            required: false
        },
        tin: {
            type: [null, String],
            required: false
        }
    }
})

export default class UserInformationComponent extends Vue {

}