import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'result-component',
    props: {
        title: {
            type: String,
            required: true
        },
        price: {
            type: String,
            required: true
        },
        color: {
            type: String,
            required: false
        },
        additions:{
            type: String,
            required: false
        }
    }
})

export default class ResultComponent extends Vue {

}
