<style src="./cancel-form.component.scss" lang="scss"></style>

<template>
  <div class="cancel-form">
    <form class="form">
      <div class="row">
        <div class="col-12">
          <div class="form__group">
            <label class="v-label">Ləğvetmə səbəbi</label>
            <textarea
                :class="{'v-textarea--error':canselError?.text}"
                class="v-textarea" @input="onChangedInput($event.target.value, 'cancelText')"></textarea>
          </div>
        </div>
        <div class="col-lg-6 col-12 mt-20">
          <div class="form__group">
            <label class="v-label">Ləğv etmə ilə bağlı sənəd</label>
            <file-input-component
                :error="canselError?.file"
                accept="application/pdf"
                @file="(i)=>{onChangedInput(i, 'cancelFile')}"/>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./cancel-form.component.ts"></script>
