import {
    IRedAppealFileRequest
} from '@/pages/registration-radio-electronic-devices-service/types/post-create-red-appeal';

export class PostCreateRedAppeal {
    redAppealTechnologyId: null | number = null;
    applicantId: number | null = null;
    redAppealFileRequest: IRedAppealFileRequest[] | null = null;

    constructor(data: any, applicantId: number) {
        this._setRedAppealTechnologyId(data);
        this._setApplicantId(applicantId);
        this._setRedAppealFileRequest(data);
    }

    public _setRedAppealTechnologyId({redAppealTechnologyId}: any) {
        this.redAppealTechnologyId = redAppealTechnologyId;
    }

    public _setApplicantId(applicantId: any) {
        this.applicantId = applicantId;
    }

    public _setRedAppealFileRequest({redAppealFileRequest}: any) {
        this.redAppealFileRequest = redAppealFileRequest.map((item: any) => {
            return new RedAppealFileRequest(item);
        });
    }
}

export class RedAppealFileRequest {
    public redAppealFileTypeId: number | null = null;
    public file: File[] | null = null;

    constructor(item: any) {
        this._setRedAppealFileTypeId(item);
        this._setFile(item);
    }

    private _setRedAppealFileTypeId({redAppealFileTypeId}: any) {
        this.redAppealFileTypeId = redAppealFileTypeId;
    }

    private _setFile({file}: any) {
        this.file = file;
    }
}

