<style src="./tags.component.scss" lang="scss"> </style>

<template>
  <div class="tags">
    <tag-component
        @addTag=""
        @selectItem=""
        :selected="false" :item="{id:0, value: 'Təsvirin keyfiyyətinin pisləşməsi'}"/>
    <tag-component
        @addTag=""
        color="dark"
        :selected="true" :addButton="true" :item="{id:0, value: 'Teq əlavə edin'}"/>
  </div>
</template>

<script src="./tags.component.ts"></script>