<style src="./foreign-citizen-form.component.scss" lang="scss"></style>

<template>
  <div class="foreign-citizen-form">
    <div class="row">
      <div class="col-12 col-lg-4 mt-20">
        <div class="form__group">
          <label class="v-label">Ad </label>
          <input type="text" class="v-input" v-model="personalInformation.name">
        </div>
      </div>
      <div class="col-12 col-lg-4 mt-20">
        <div class="form__group">
          <label class="v-label">Soyad</label>
          <input type="text" class="v-input" v-model="personalInformation.secondName">
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-12 col-lg-4 mt-20">
        <div class="form__group">
          <label class="v-label">Telefon nömrəsi</label>
          <input
              name="phone"
              :class="{'v-input--error':errorPersonalInformation.foreignPhone}"
              @input="phoneChanged($event.target.value, 'foreignPhone')"
              type="text" class="v-input" v-model="personalInformation.foreignPhone">
        </div>
      </div>
      <div class="col-12 col-lg-4 mt-20">
        <div class="form__group">
          <label class="v-label">Email</label>
          <input
              name="email"
              :class="{'v-input--error':errorPersonalInformation.email}"
              @input="emailChanged($event.target.value)" type="text"
              class="v-input" v-model="personalInformation.email">
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-12 col-lg-4 mt-20">
        <file-generation-component
            @onSelectFile="(i) =>
             $emit('onSelectFileInformation', i)"
                                   :files="filesForForCertificate"/>
      </div>
      <div class="w-100"></div>
    </div>
  </div>
</template>

<script src="./foreign-citizen-form.component.ts"></script>