<style src="./applicant-form.component.scss" lang="scss"> </style>

<template>
  <div class="applicant-form">
    <form class="form">
      <div class="row">
        <div class="col-12 col-lg-4 password-margin">
          <div class="row">
            <div class="col-4 col-md-4">
              <div class="form__group">
                <label class="v-label"> {{ locale.documentType }}</label>
                <v-select
                    :allData="true"
                    @select="selectedDocumentType"
                    :options="documentSeriesType"
                    :value="applicantForm.documentType?.id"
                />
              </div>
            </div>
            <div class="col-8 col-md-8 pl-0">
              <div class="form__group">
                <label
                    v-if="citizenshipValue===1"
                    class="v-label">{{ locale.documentNumber }}</label>
                <label
                    v-else
                    class="v-label">{{ locale.passwordName }}</label>
                <input
                    v-model="applicantForm.documentNumber"
                    @input="onChangeInput($event.target.value, 'documentNumber')"
                    name="documentNumber"
                    type="text"
                    class="v-input">
                <div class="form__group-info--error">Məlumat tam daxil edilməmişdir</div>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="col-12 col-lg-4" v-if="!userLogin">-->
<!--          <div class="form__group">-->
<!--            <label class="v-label">{{ locale.fin }}</label>-->
<!--            <input-->
<!--                name="fin"-->
<!--                :class="{'v-input&#45;&#45;error':errorPersonalInformation.fin || finError}"-->
<!--                placeholder="1234ABC"-->
<!--                @input="finChanged($event.target.value)" type="text"-->
<!--                class="v-input" v-model="personalInformation.fin">-->
<!--            <div class="form__group-info&#45;&#45;error" v-if=" finError">Məlumat tam daxil edilməmişdir</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="w-100"></div>-->
<!--        <div class="col-12 col-lg-4 mt-20">-->
<!--          <div class="form__group">-->
<!--            <label class="v-label">{{ locale.phoneNumber }}</label>-->
<!--            <input-->
<!--                name="phone"-->
<!--                :class="{'v-input&#45;&#45;error':errorPersonalInformation.phone}"-->
<!--                @keyup="deleteNumber($event, 'phone')"-->
<!--                @keydown="deleteNumber($event,'phone')"-->
<!--                v-mask="['+994(##)-###-##-##', '+994(##)-###-##-##']"-->
<!--                @input="phoneChanged($event.target.value, 'phone')" type="text"-->
<!--                class="v-input" v-model="personalInformation.phone">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 col-lg-4 mt-20">-->
<!--          <div class="form__group">-->
<!--            <label class="v-label">{{ locale.mail }}</label>-->
<!--            <input-->
<!--                name="email"-->
<!--                :class="{'v-input&#45;&#45;error':errorPersonalInformation.email}"-->
<!--                :placeholder="locale.emailOrganizationPlaceholder"-->
<!--                @input="emailChanged($event.target.value)" type="text"-->
<!--                class="v-input" v-model="personalInformation.email">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="w-100"></div>-->
<!--        <div class="col-12 col-lg-4 mt-20" v-if="organizationLegalFormValue!==1"-->
<!--        >-->
<!--          <div class="form__group">-->
<!--            <label class="v-label">{{ locale.tin }}</label>-->
<!--            <input-->
<!--                name="tin"-->
<!--                v-model="tinValue"-->
<!--                :class="{'v-input&#45;&#45;error':errorPersonalInformation.voen || tinError }"-->
<!--                :placeholder="locale.tinPlaceholder"-->
<!--                @input="setVoen($event.target.value, 'voen')" type="text"-->
<!--                class="v-input">-->
<!--            <div class="form__group-info&#45;&#45;error" v-if="tinError">Məlumat düzgün daxil edilməmişdir</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 col-lg-4 mt-20" v-if="organizationLegalFormValue!==1"-->
<!--        >-->
<!--          <div class="form__group">-->
<!--            <label class="v-label">{{ locale.nameOrganization }}</label>-->
<!--            <input-->

<!--                :data-title="organizationName"-->
<!--                disabled-->
<!--                :class="{'v-input&#45;&#45;error':errorPersonalInformation.organization}"-->
<!--                :placeholder="locale.nameOrganizationPlaceholder"-->
<!--                v-model="organizationNameValue"-->
<!--                type="text"-->
<!--                class="v-input">-->
<!--            <div class="input-title">-->
<!--              {{ organizationName }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="w-100"></div>-->
<!--        <div class="col-12 col-lg-4 mt-20" v-if="organizationLegalFormValue!==1"-->
<!--        >-->
<!--          <div class="form__group">-->
<!--            <label class="v-label">{{ locale.position }}</label>-->
<!--            <input-->
<!--                :class="{'v-input&#45;&#45;error':errorPersonalInformation.position}"-->
<!--                @input="setPersonalInformation($event.target.value, 'position')" type="text"-->
<!--                class="v-input" v-model="personalInformation.position">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="w-100"></div>-->
      </div>
    </form>
  </div>
</template>

<script src="./applicant-form.component.ts"></script>