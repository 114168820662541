import {
    IAppeal, IAppealFiles,
    IApplicant,
    IApplicantOrganization, IItemNameFactoryNumbers,
    IJuridicalPersonInformation
} from '@/pages/issuance-special-permit-service/types/sp-applicant';
import {IFileType} from '@/pages/issuing-certificate-conformity-service/types/file-type';

export class ISPApplicantModel {
    public applicant: IApplicant | null = null;
    public appeal: IAppeal | null = null;

    constructor(citizenshipValue: any, formData: any, applicantTypeId: any, files: IFileType[], isApplicantDifferentWithJuridical: any) {
        this._setApplicant(citizenshipValue, formData, applicantTypeId, isApplicantDifferentWithJuridical);
        this._setAppeal(formData, files);
    }


    private _setApplicant(citizenshipValue: any, formData: any, applicantTypeId: any, isApplicantDifferentWithJuridical: any) {
        this.applicant = new Applicant(citizenshipValue, formData, applicantTypeId, isApplicantDifferentWithJuridical);
    }

    private _setAppeal(formData: any, files: any) {
        this.appeal = new Appeal(formData, files);
    }
}

class Applicant {
    public documentTypeGroupId: string | null = null;
    public documentTypeId: string | null = null;
    public documentNumber: string | null = null;
    public pin: string | null = null;
    public phone: string | null = null;
    public email: string | null = null;
    public applicantTypeId: string | null = null;
    public applicantOrganization: IApplicantOrganization | null = null;
    public juridicalPersonInformation: IJuridicalPersonInformation | null = null;

    constructor(citizenshipValue: any, formData: any, applicantTypeId: any, isApplicantDifferentWithJuridical: any) {
        this._setDocumentTypeGroupId(citizenshipValue);
        this._setDocumentTypeId(formData);
        this._setDocumentNumber(formData);
        this._sePin(formData);
        this._setPhone(formData);
        this._setEmail(formData);
        this._setApplicantTypeId(applicantTypeId);
        this._setApplicantOrganization(formData, isApplicantDifferentWithJuridical);
        this._setJuridicalPersonInformation(formData);
    }

    private _setDocumentTypeGroupId(citizenshipValue: any) {
        this.documentTypeGroupId = citizenshipValue;
    }

    private _setDocumentTypeId({documentTypeNumber}: any) {
        this.documentTypeId = documentTypeNumber?.id;
    }


    private _setDocumentNumber({documentNumber}: any) {
        this.documentNumber = documentNumber;
    }


    private _sePin({fin}: any) {
        this.pin = fin;
    }

    private _setPhone({phone}: any) {
        this.phone = phone;
    }

    private _setEmail({email}: any) {
        this.email = email;
    }

    private _setApplicantTypeId(applicantTypeId: any) {
        this.applicantTypeId = applicantTypeId;
    }

    private _setApplicantOrganization(formData: any, isApplicantDifferentWithJuridical: any) {
        this.applicantOrganization = new ApplicantOrganization(formData, isApplicantDifferentWithJuridical);
    }

    private _setJuridicalPersonInformation(formData: any) {
        this.juridicalPersonInformation = new JuridicalPersonInformation(formData);
    }
}

class JuridicalPersonInformation {
    public fullname: string | null = null;
    public position: string | null = null;

    constructor(data: any) {
        this._setFullname(data);
        this._setPosition(data);

    }

    private _setFullname({legalEntityFullName}: any) {
        this.fullname = legalEntityFullName;
    }

    private _setPosition({positionLegalEntity, position}: any) {
        if (positionLegalEntity) this.position = positionLegalEntity;
        if (positionLegalEntity) this.position = position;

    }
}

class ApplicantOrganization {
    public name: string | null = null;
    public tin: string | null = null;
    public position: string | null = null;
    public isApplicantDifferentWithJuridical: boolean | null = null;
    public address: string | null = null;

    constructor(data: any, isApplicantDifferentWithJuridical: any) {
        this._setName(data);
        this._setTin(data);
        this._setPosition(data);
        this._setIsApplicantDifferentWithJuridical(isApplicantDifferentWithJuridical);
        this._setAddress(data);
    }

    private _setName({organizationName}: any) {
        this.name = organizationName;
    }

    private _setTin({tin}: any) {
        this.tin = tin;
    }

    private _setPosition({position}: any) {
        this.position = position;
    }

    private _setIsApplicantDifferentWithJuridical(data: any) {
        this.isApplicantDifferentWithJuridical = data;
    }

    private _setAddress({address, legalEntityAddress, addressLegalEntity}: any) {
        if (address) this.address = address;
        if (legalEntityAddress) this.address = legalEntityAddress;
        if (addressLegalEntity) this.address = addressLegalEntity;
    }
}

class Appeal {
    public ownerActivityType: string | null = null;
    public restrictedCirculationItemId: number | null = null;
    public requiredCrucialDataId: number | null = null;
    public itemNameFactoryNumbers: IItemNameFactoryNumbers[] | null = null;
    public appealFiles: IAppealFiles[] | null = null;

    constructor(data: any, files: any) {
        this._setOwnerActivityType(data);
        this._setRestrictedCirculationItemId(data);
        this._setRequiredCrucialDataId(data);
        this._setItemNameFactoryNumbers(data);
        this._setAppealFiles(data, files,);
    }

    private _setOwnerActivityType({typeEntrepreneurialActivity}: any) {
        this.ownerActivityType = typeEntrepreneurialActivity;
    }

    private _setRestrictedCirculationItemId({restrictedCivilCirculation}: any) {
        this.restrictedCirculationItemId = restrictedCivilCirculation?.id;
    }

    private _setRequiredCrucialDataId({requiredCrucialData}: any) {
        if (requiredCrucialData) this.requiredCrucialDataId = requiredCrucialData?.id;
    }

    private _setItemNameFactoryNumbers({commonInformation}: any) {
        this.itemNameFactoryNumbers = commonInformation.map((item: IFileType) => {
            return new ItemNameFactoryNumbers(item);
        });
    }

    private _setAppealFiles(form: any, files: any) {
        this.appealFiles = files.map((item: IFileType) => {
            return new AppealFiles(item);
        });
        // @ts-ignore
        form.govregistercopy ? this.appealFiles?.push(new AppealFiles(form.govregistercopy)) : null;
        // @ts-ignore
        form.poweroftheattorneydoc ? this.appealFiles?.push(new AppealFiles(form.poweroftheattorneydoc)) : null;
        // @ts-ignore
        form.authorityFile ? this.appealFiles?.push(new AppealFiles(form.authorityFile)) : null;
        // @ts-ignore
        form.copyExtractFile ? this.appealFiles?.push(new AppealFiles(form.copyExtractFile)) : null;
    }
}

class ItemNameFactoryNumbers {
    public itemName: string | null = null;
    public factoryNumber: string | null = null;

    constructor(data: any) {
        this._setItemName(data);
        this._setFactoryNumber(data);
    }

    private _setItemName({name}: any) {
        this.itemName = name;
    }

    private _setFactoryNumber({factorySerialNumber}: any) {
        this.factoryNumber = factorySerialNumber;
    }
}

class AppealFiles {
    public fileInputId: number | null = null;
    public file: File | null = null;

    constructor(data: any) {
        this._setFileInputId(data);
        this._setFile(data);
    }

    private _setFileInputId({id}: any) {
        this.fileInputId = id;
    }

    private _setFile({file}: any) {
        this.file = file;
    }
}