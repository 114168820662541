import {SignService} from './sign.service';
import {ISign} from '../types/sign';
import Swal from 'sweetalert2';
import {b64ToBlob, convertToBase64} from '../helpers/helpers';
import {messages} from '../constants/messages';
import {getLang} from '../constants/configs';
// @ts-ignore
import {getBase64} from '@/core/esign/helpers/esign.helpers';

export class ESignSandbox {
    private attempts = 5;
    private intervar: any;
    private service: SignService = new SignService();

    check() {
        this.service.getVersion().then();
    }

    sign(signRequest: ISign) {
        return new Promise((resolve, reject) => {
            this.certificate().then(cert => {
                Swal.fire({
                    title: 'Sənədin imzalanması',
                    icon: 'info',
                    html: `<table class="table" >
                    <tbody>
                      <tr>
                        <td class="px-0 text-left" width="35%">Struktur:</td>
                        <th class="text-left">${cert.structure}</th>
                      </tr>
                      <tr>
                        <td class="px-0 text-left" width="35%">Ad Soyad Ata adı:</td>
                        <th class="text-left">${cert.name} ${cert.surname} ${cert.patronymic}</th>
                      </tr>
                      <tr>
                        <td class="px-0 text-left" width="35%">Vəzifə:</td>
                        <th class="text-left">${cert.position}</th>
                      </tr>
                      <tr>
                        <td class="px-0 text-left" width="35%">Seriya nömrəsi:</td>
                        <th class="text-left">${cert.serial} ${cert.serialNumber}</th>
                      </tr>
                      <tr>
                        <td class="px-0 text-left" width="35%">VÖEN:</td>
                        <th class="text-left">${cert.voen.split(':')[1]}</th>
                      </tr>
                    </tbody>
                 </table>
          `,
                    showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText:
                        'İmzala <i class="icon md-edit"></i>',
                    cancelButtonText: 'Geri'
                }).then(res => {
                    if (res && res.value) {
                        this._signator(signRequest, cert, resolve);
                    }
                });
            });
        });
    }

    /**
     * Get Certificates
     */
    public certificate(): Promise<any> {
        return this.service.cert();
    }

    public async createArrayBase64(request: File[]): Promise<any[]> {
        let files: any[] = [];
        for await (const file of request) {
            await getBase64(file).then((res: any) => {
                let index = res.indexOf(',');
                files.push({
                    name: file.name,
                    rawData: res.substr(index + 1, res.length)
                });
            });
        }
        return files;
    }

    private async _signator(requestData: ISign, cert: any, resolve: any) {
        this.attempts = 5;
        if (requestData.file) {
            let files = await this.createArrayBase64(requestData.file);
            this._signProceed(files, cert, resolve);
            // convertToBase64(requestData.file[0]).then(base64File => {
            //     const reqData = {
            //         ...requestData,
            //         base64: base64File
            //     };
            //
            // });
        } if (requestData.base64) {
            this._signProceed([{
                name:requestData.title,
                rawData: requestData.base64
            }], cert, resolve);
        }
    }

    private _signProceed(reqData: any, cert: any, resolve: any) {
        Swal.fire({
            title: messages[getLang()].signing,
            icon: 'info',
            html: '<div class="progress-area"></div>',
            didOpen: () => {
                Swal.showLoading();
                this.service.sign(reqData, cert).then(
                    (res: any) => {
                        // @ts-ignore
                        const html = Swal && Swal.getHtmlContainer() ? Swal.getHtmlContainer().querySelector('div.progress-area') : null;
                        if (html) {
                            html.innerHTML = '<strong>İmzalandı!</strong>' +
                                '<hr>' +
                                '<div class="progress">' +
                                '<div class="progress-bar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">' +
                                '100%' +
                                '</div>' +
                                '</div>';
                            clearInterval(this.intervar);
                            if (!res || res && !res.edocFile) {
                                if (this.attempts !== 0) {
                                    this.attempter(reqData, cert, resolve);
                                }
                            } else {
                                Swal.fire({
                                    title: messages[getLang()].successfullSign,
                                    icon: 'success',
                                    showCloseButton: false,
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                                const binary = b64ToBlob(res.edocFile.rawData, 'application/e-doc');
                                resolve({
                                    name: res.edocFile.fileName,
                                    file: res.edocFile.rawData,
                                    binary,
                                    mimeType: 'application/e-doc'
                                });
                            }
                        } else {

                        }
                    },
                    error => {
                        if (this.attempts !== 0) {
                            this.attempter(reqData, cert, resolve);
                        }
                    }
                );
                this.intervar = setInterval(() => {
                    this.status().then(
                        (status: any) => {
                            if (status && status.progress && Swal && Swal.getHtmlContainer()) {
                                // @ts-ignore
                                Swal.getHtmlContainer().querySelector('div.progress-area')
                                    .innerHTML = `<strong>${status.progress.state}</strong>
                                <hr>
                                <div class="progress">
                                  <div class="progress-bar" role="progressbar" style="width: ${status.progress.percentage}%;" aria-valuenow="${status.progress.percentage}" aria-valuemin="0" aria-valuemax="100">${status.progress.percentage}%</div>
                                </div>`;
                            }
                        }
                    );
                }, 1000);

            },
        });
    }

    private attempter(requestData: any, cert: any, resolve: any) {
        this.attempts--;
        Swal.fire({
            title: messages[getLang()].signing,
            icon: 'info',
            html: '<div class="progress-area"></div>',
            didOpen: async () => {
                Swal.showLoading();
                await this.service.sign(requestData, cert).then(
                    (res: any) => {
                        // @ts-ignore
                        Swal.getHtmlContainer().querySelector('div.progress-area')
                            .innerHTML = '<strong>İmzalandı!</strong>' +
                            '<hr>' +
                            '<div class="progress">' +
                            '<div class="progress-bar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">' +
                            '100%' +
                            '</div>' +
                            '</div>';
                        clearInterval(this.intervar);
                        if (!res || res && !res.edocFile) {
                            if (this.attempts === 0) {
                                clearInterval(this.intervar);
                                Swal.fire({
                                    title: messages[getLang()].failedSign,
                                    html: ' <br> '
                                        + res.message +
                                        ' <br> Xətanı texniki dəstək qrupuna ' +
                                        '<strong><a target="_blank" href="http://www.e-imza.az/support.php?id=5&lang=az">bildir !</a></strong>',
                                    icon: 'error',
                                });
                                resolve({
                                    name: null,
                                    file: null,
                                    binary: null,
                                    mimeType: null,
                                    message: 'Cəhdlər edilsədə imzalanma baş vermədi ('
                                });
                            } else {
                                this.attempter(requestData, cert, resolve);
                            }
                        } else {
                            Swal.fire({
                                title: messages[getLang()].successfullSign,
                                icon: 'success',
                                showCloseButton: false,
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 2000,
                            });
                            const binary = b64ToBlob(res.edocFile.rawData, 'application/e-doc');
                            resolve({
                                name: res.edocFile.fileName,
                                file: res.edocFile.rawData,
                                binary,
                                mimeType: 'application/e-doc'
                            });
                        }
                    },
                    error => {
                        if (this.attempts === 0) {
                            clearInterval(this.intervar);
                            Swal.fire({
                                title: messages[getLang()].failedSign,
                                html: 'İmzalanma zamanı xəta baş verdi! <br> Xətanı texniki dəstək qrupuna <strong><a target="_blank" href="http://www.e-imza.az/support.php?id=5&lang=az">bildir !</a></strong>',
                                icon: 'error',
                            });

                            resolve({
                                name: null,
                                file: null,
                                binary: null,
                                mimeType: null,
                                message: 'Cəhdlər edilsədə imzalanma baş vermədi ('
                            });
                        } else {
                            this.attempter(requestData, cert, resolve);
                        }
                    }
                );
            },
        });
    }

    /**
     * Check sign status
     */
    public status() {
        return this.service.status();
    }
}
